import { getInvalidOutcomeError } from "errors";
import { MAX_BYTE } from "extensions/web3-extensions";

export function getOutcomeText(outcomeCount: number, outcome: number) {
    if (outcome === MAX_BYTE) {
        return "Unknown";
    }

    if (outcome > outcomeCount) {
        throw `Outcome "${outcome}" is greater than outcome count: ${outcomeCount}`;
    }

    if (outcomeCount === 2) {
        if (outcome === 0) {
            return "Heads";
        }

        if (outcome === 1) {
            return "Tails";
        }
    }

    if (outcomeCount === 3) {
        if (outcome === 0) {
            return "Bim";
        }

        if (outcome === 1) {
            return "Bam";
        }

        if (outcome === 2) {
            return "Bom";
        }
    }

    return `${outcome + 1}`;
}

export function getOutcomeImage(outcomeCount: number, outcome: number) {
    if (outcome === MAX_BYTE) {
        return "Unknown";
    }

    if (outcome > outcomeCount) {
        throw getInvalidOutcomeError(outcomeCount, outcome);
    }

    // https://miro.medium.com/max/1838/1*p-SahjISD2_mdOf_e-uF5A.png
    if (outcomeCount === 2) {
        if (outcome === 0) {
            return (<img src="./images/weeb-games/2/heads-200px.png" className="w-[120px] xs:w-[140px]" />);
        }

        if (outcome === 1) {
            return (<img src="./images/weeb-games/2/tails-200px.png" className="w-[120px] xs:w-[140px]" />);
        }
    } else if (outcomeCount === 3) {
        if (outcome === 0) {
            return (<i className="bi bi-triangle text-[4em] xs:text-[4.5em] sm:text-[5em]"></i>);
            //return (<i className="bi bi-gender-male text-[4em] xs:text-[4.5em] sm:text-[5em]"></i>);
            //return (<img src="./images/weeb-games/3/rock-200px.png" width="125" height="125" />);
        }

        if (outcome === 1) {
            return (<i className="bi bi-square text-[4em] xs:text-[4.5em] sm:text-[5em]"></i>);
            //return (<i className="bi bi-gender-female text-[4em] xs:text-[4.5em] sm:text-[5em]"></i>);
            //return (<img src="./images/weeb-games/3/paper-200px.png" width="125" height="125" />);
        }

        if (outcome === 2) {
            return (<i className="bi bi-circle text-[4em] xs:text-[4.5em] sm:text-[5em]"></i>);
            //return (<i className="bi bi-gender-ambiguous text-[4em] xs:text-[4.5em] sm:text-[5em]"></i>);
            //return (<img src="./images/weeb-games/3/scissors-200px.png" width="125" height="125" />);
        }
    } else if (outcomeCount === 6) {
        return (<i className={`bi bi-dice-${outcome + 1} text-[2.5em] xs:text-[3em] sm:text-[3.5em]`}></i>);
    }

    return `${outcome}`;
}

export function getRandomizeText(outcomeCount: number) {
    if (outcomeCount === 2) {
        return "Flip";
    }

    if (outcomeCount === 3) {
        return "Pick";
    }

    if (outcomeCount === 6) {
        return "Roll";
    }

    return "Randomize";
}

export function getRandomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min)) + min;
}