import BN from "bn.js";
import { PromiEvent } from "web3-core/types";
import { Contract } from "web3-eth-contract/types";

import { Address } from "configuration/types";

import { TokenState, TokenDetails, TokenInfo } from "services/erc20";
import { AggregatePrimitive, AggregateValue, FeeValue } from "../shared.types";

export type AccountStatement = {
    winningBet: string;
    prize: string
}

export enum PrizeClaimState {
    UnknownAccount,
    Claimed,
    Claimable,
    GamePending
}

export type AccountRecentBetInfo = {
    lastBetPoolId: string;
    lastDistributedBlock: string;
    previousBetPoolId: string;
    previousDistributedBlock: string
}

export type TotalPosition = {
    placedBets: AggregateValue;
    claimedBets: AggregateValue;
    claimedPrizes: AggregateValue;
    lastBetPoolId: string;
    previousBetPoolId: string
}

export type FinancialStatement = {
    allAggregatedBets: AggregatePrimitive;
    allWinningBets: AggregatePrimitive;
    allLosingBets: AggregatePrimitive;
    senderAggregatedBets: AggregatePrimitive;
    senderWinningBets: AggregatePrimitive;
    senderLosingBets: AggregatePrimitive;
    senderPrize: string
}

export type WeebGamePoolViewModel = {
    poolId: string;
    outcome: string;
    allBets: AggregateValue[];
    senderBets: AggregateValue[];
    statement: FinancialStatement
}

export type WeebGameViewModel = {
    gameId: string;
    name: string;
    betToken: TokenInfo;
    isLiquidityToken: boolean;
    token0: Address;
    token0Details: TokenDetails;
    token1: Address;
    token1Details: TokenDetails;
    harvestRate: string;
    minimumBet: string;
    blockLength: string;
    outcomeCount: string;
    seigniory: Address;
    seigniorageFee: FeeValue;
    isSenderSeignory: boolean;
    isEnabled: boolean;
    totalPosition: TotalPosition;
    unclaimedBalance: string;
    currentPoolId: string;
    currentBetPool: WeebGamePoolViewModel;
    lastBetPool: WeebGamePoolViewModel;
    senderRecentBet: AccountRecentBetInfo;
    senderUnclaimedPrize: AccountStatement;
    senderUnclaimedPrizeState: PrizeClaimState;
    senderLastBetPool: WeebGamePoolViewModel;
    senderBetPoolCount: string;
    currentBlock: string;
}

export type WeebGamesViewModel = {
    games: WeebGameViewModel[];
    totalPlacedBets: AggregatePrimitive;
    totalClaimedBets: AggregatePrimitive;
    totalClaimedPrizes: AggregatePrimitive;
    currentBlock: string;
}

export type WeebGameState = {
    getSymbol: (game: WeebGameViewModel) => string;
    createBetToken: (game: WeebGameViewModel) => TokenState;
    readerContract: Contract;
    senderGameSnapshots: (isEnabled: boolean) => Promise<WeebGameViewModel[]>;
    senderBetPoolCount: (gameId: number) => Promise<number>;
    senderPoolSnapshots: (gameId: number, offset: number, count: number) => Promise<WeebGamePoolViewModel[]>;
    senderBetPools: (gameId: number) => Promise<number[]>;
    signerContract?: Contract | undefined;
    claim?: ((gameId: number) => PromiEvent<Contract>) | undefined;
    compound?: ((gameId: number, outcome: number) => PromiEvent<Contract>) | undefined;
    betETH?: ((gameId: number, amountETHInWei: BN, outcome: number, betToken: TokenState) => Promise<Contract>) | undefined;
    bet?: ((gameId: number, amount: BN, outcome: number) => PromiEvent<Contract>) | undefined;
    betToken?: ((gameId: number, token: TokenState, amountInWei: BN, outcome: number, betToken: TokenState) => Promise<Contract>) | undefined;
    harvestPendingReward: (gameId: number, outcome: number) => PromiEvent<Contract> | undefined;
}