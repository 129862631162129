export enum NotificationType {
    Info,
    Success,
    Failure,
    Warning
}

export type NotificationItem = {
    trackId: string;
    type: NotificationType;
    title: string;
    description?: any;
    transactionHash?: string,
    resultTime?: number;
    createdAt: number;
}