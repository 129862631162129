import { PromiEvent } from "web3-core/types";
import { AbiItem } from "web3-utils/types";
import { Contract, ContractOptions } from "web3-eth-contract/types";

import appSettings from "configuration";

import { AnonymousWalletInfo, PersonalWalletInfo } from "services/shared.types";
import { IziswapState } from "services/iziswap";
import { createToken } from "services/erc20";
import { WeebTokenState, WeebTokenViewModel } from "./weeb-token.types";

import WeebToken_ABI from "contracts/abi/WeebToken.json";

const createWeebToken = (wallet: AnonymousWalletInfo | PersonalWalletInfo, iziswap: IziswapState): WeebTokenState => {
    const chain = appSettings.chains[wallet.chainId];

    const options = { from: wallet.reader.defaultAccount } as ContractOptions;
    const abi = WeebToken_ABI as AbiItem[];

    const readerContract = new wallet.reader.eth.Contract(abi, chain.contracts.weebToken.address, options);

    const read = {
        readerContract,
        senderTokenSnapshot: (): Promise<WeebTokenViewModel> => readerContract.methods.senderTokenSnapshot().call(),
    }

    let sign: {
        signerContract?: Contract,
        harvestPendingReward?: () => PromiEvent<Contract>,
    };

    if ("signer" in wallet && wallet.signer) {
        const signerContract = new wallet.signer.eth.Contract(abi, chain.contracts.weebToken.address, options);

        sign = {
            signerContract,
            harvestPendingReward: (): PromiEvent<Contract> => wallet.track(signerContract.methods.harvestPendingReward().send(), { name: "harvestPendingReward" }),
        }
    } else {
        sign = {};
    }

    return {
        ...read,
        ...sign,
        ...createToken({
            wallet,
            iziswap,
            readerContract: read.readerContract,
            signerContract: sign.signerContract,
            router: chain.contracts.defaultSwapRouter,
            token: chain.contracts.weebToken.address,
            decimals: chain.contracts.weebToken.decimals,
            isLiquidityToken: false,
            symbol: chain.contracts.weebToken.symbol
        }),
    }
}

export default createWeebToken;