const Footer = () => {
    return (
        <>
            <div className="pt-16 pb-6">
                <div className="flex flex-row items-center justify-center space-x-10 text-[28px]">
                    <a href="https://twitter.com/WeebFi" target="_blank" aria-label="twitter page">
                        <i className="bi bi-twitter"></i>
                    </a>
                    <a href="https://t.me/WeebFi" target="_blank" aria-label="telegram channel">
                        <i className="bi bi-telegram"></i>
                    </a>
                    {/* <i class="bi bi-github"></i>
                <i class="bi bi-discord"></i> */}
                </div>
            </div>
        </>
    );
}

export default Footer;