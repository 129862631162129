import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function useStateDeferred<S>(initialState: S | (() => S), delay: number = 400): [S, Dispatch<SetStateAction<S>>] {
    const [value, setValue] = useState<S>(initialState);
    const [valueDeferred, setValueDeferred] = useState<S>(initialState);

    useEffect(() => {
        const timerId = setTimeout(() => setValue(valueDeferred), delay);
        return () => clearTimeout(timerId);
    }, [valueDeferred]);

    return [value, setValueDeferred];
}

// export function useStateDeferred1<S = undefined>(delay: number = 400): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
//     const [value, setValue] = useState<S>();
//     const [valueDeferred, setValueDeferred] = useState<S>();

//     useEffect(() => {
//         const timerId = setTimeout(() => setValue(valueDeferred), delay);
//         return () => clearTimeout(timerId);
//     }, [valueDeferred]);

//     return [value, setValueDeferred];
// }