import Web3 from "web3";
import { AbiItem } from "web3-utils/types";
import { ContractOptions } from "web3-eth-contract/types";

import { Address } from "configuration/types";
import { convert } from "services/convert";
import { PairState } from "./pair.types";
import IUniswapV2Pair_ABI from "contracts/abi/IUniswapV2Pair.json";

type PairProps = {
    liquidityToken: Address;
    token1: Address;
    decimals1: number;
    token2: Address;
    decimals2: number;
}

const createPair = ({
    reader,
    liquidityToken,
    token1,
    decimals1,
    token2,
    decimals2
}: {
    reader: Web3,
} & PairProps): PairState => {
    const options = { from: reader.defaultAccount } as ContractOptions;
    const abi = IUniswapV2Pair_ABI as AbiItem[];

    const readerContract = new reader.eth.Contract(abi, liquidityToken, options);

    return {
        liquidityToken,
        token1,
        decimals1,
        token2,
        decimals2,
        readerContract,
        getTokenReserves: async () => {
            // Check the order of pair tokens.
            const reserves = await readerContract.methods.getReserves().call();
            const isToken1First = token1.toBN().lt(token2.toBN());

            return isToken1First
                ?
                {
                    token1Reserve: convert.fromWei(reserves.reserve0, decimals1),
                    token2Reserve: convert.fromWei(reserves.reserve1, decimals2)
                }
                :
                {
                    token1Reserve: convert.fromWei(reserves.reserve1, decimals1),
                    token2Reserve: convert.fromWei(reserves.reserve0, decimals2)
                };
        },
    };
}

export default createPair;