import { ChainInfo } from "configuration/types";
import { PersonalWalletInfo } from "services/shared.types";
import { SettingsRepositoryState } from "services/settings-repository";
import { TokensState } from "services/weeb-finance/tokens";

import Settings from "components/settings";
import ModalDialog from "./modal-dialog";

type SettingsDialogProps = {
    wallet: PersonalWalletInfo;
    settingsRepositoryService: SettingsRepositoryState;
    chain: ChainInfo;
    tokens: TokensState;

    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

const SettingsDialog = ({
    wallet,
    settingsRepositoryService,
    chain,
    tokens,

    isOpen,
    setIsOpen
}: SettingsDialogProps) => {
    return (
        <ModalDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disableBackgroundCancel={false}
            body={
                <Settings
                    wallet={wallet as PersonalWalletInfo}
                    settingsRepositoryService={settingsRepositoryService}
                    chain={chain}
                    tokens={tokens}
                    isDarkTheme={true}
                />
            }
            title="Application Settings"
            hideSubmitButton={true}
            cancelButtonText="Close"
        />
    );
}

export default SettingsDialog;