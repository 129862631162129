import { useContext } from "react";

import WalletContext from "contexts/wallet-context";

import ModalDialog from "./modal-dialog";;

type ConnectDialogProps = {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    onSubmit?: (providerId: number) => void,
}

const ConnectDialog = ({
    isOpen = false,
    setIsOpen,
    onSubmit,
}: ConnectDialogProps) => {
    const wallet = useContext(WalletContext);

    if (!wallet) {
        return <p className="text-red-500">Invalid wallet state</p>
    }

    return (
        <ModalDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disableBackgroundCancel={false}
            body={
                <div className="grid grid-cols-1 gap-x-4 gap-y-4 items-center justify-center text-indigo-900">
                    {wallet.isInjectedProviderInstalled
                        ?
                        <button
                            type="button"
                            className="btn btn-red h-16 w-full text-violet-50 text-[0.75em]"
                            onClick={() => {
                                setIsOpen?.(false);
                                wallet.connectInjectedProvider();
                                onSubmit?.(0);
                            }}
                        >
                            Use Injected Provider
                        </button>
                        : <div className="h-16 w-full text-violet-900 text-center">
                            <p>
                                <b>No injected provider found.</b>
                            </p>
                            <a href="https://metamask.io/download/" target="_blank">Install MetaMask <i className="bi bi-box-arrow-up-right" /></a>
                        </div>
                    }
                    <button
                        type="button"
                        className="btn btn-blue h-16 w-full text-violet-50 text-[0.75em]"
                        onClick={() => {
                            setIsOpen?.(false);
                            wallet.connectWalletConnectProvider();
                            onSubmit?.(1);
                        }}
                    >
                        Use Wallet Connect Provider
                    </button>
                </div>
            }
            title="Connect to Wallet"
            hideSubmitButton={true}
            cancelButtonText="Close"
        />
    );
}

export default ConnectDialog;