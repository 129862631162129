import { useEffect, useState } from "react";

import { ChainInfo } from "configuration/types";

import { ModalDialog, StyledPopover } from "components/shared/dialogs";
import { MainText, Spinner, TokenSelector } from "components/shared";

import { PersonalWalletInfo } from "services/shared.types";
import { IziswapState } from "services/iziswap";
import { MoralisState } from "services/moralis";
import { TokenFinderState } from "services/token-finder";
import { NativeCoinState, TokensState } from "services/weeb-finance/tokens";
import { TokenState } from "services/erc20";
import { WeebGameState, WeebGameViewModel } from "services/weeb-finance/weeb-game";

import GameList from "./game-list";
import OutcomeList from "./outcome-list";

type GameBetDialogProps = {
    wallet: PersonalWalletInfo;
    iziswap: IziswapState;
    moralis: MoralisState;
    tokenFinder: TokenFinderState;
    tokens: TokensState;
    chain: ChainInfo,
    gameService: WeebGameState,
    defaultGames?: WeebGameViewModel[],
    setGames: React.Dispatch<React.SetStateAction<WeebGameViewModel[] | undefined>>,
    defaultGameId: number,
    defaultOutcome: number,
    enableGameSelection: boolean,
    enableAmountEntry: boolean,
    transferBurnRate?: number,

    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    onCancel?: Function,
    onSubmit: Function,
    title: string,
    submitButtonText: string
}

const GameBetDialog = ({
    wallet,
    iziswap,
    moralis,
    tokenFinder,
    tokens,
    chain,
    gameService,
    defaultGames,
    setGames,
    defaultGameId,
    defaultOutcome,
    enableGameSelection,
    enableAmountEntry,
    transferBurnRate,

    isOpen,
    setIsOpen,
    onCancel,
    onSubmit,
    title,
    submitButtonText
}: GameBetDialogProps) => {
    const [games, setGamesInternal] = useState(defaultGames);
    const [gameId, setGameId] = useState(defaultGameId);
    const [outcome, setOutcome] = useState(defaultOutcome);
    const [selectedGame, setSelectedGame] = useState<WeebGameViewModel>();
    const [selectedToken, setSelectedToken] = useState<NativeCoinState | TokenState | undefined>();
    const [amount, setAmount] = useState(0);
    const [amountInDepositToken, setAmountInDepositToken] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!games) {
            setSelectedGame(undefined);

            gameService.senderGameSnapshots(true)
                .then(games => {
                    setGamesInternal(games);
                    setGames(games);
                });

            return;
        }

        const game = games.find(g => g.gameId.toInt() === gameId);
        setSelectedGame(game);
    }, [games]);

    useEffect(() => {
        const game = games?.find(g => g.gameId.toInt() === gameId);
        setSelectedGame(game);
    }, [gameId]);

    let betToken: TokenState | undefined = undefined;
    let minimumBet: number | undefined = undefined;
    let isMinimumBetSattisfied: boolean = false;

    if (selectedGame) {
        betToken = gameService.createBetToken(selectedGame);
        minimumBet = betToken.convert.fromWei(selectedGame.minimumBet);

        if (transferBurnRate) {
            minimumBet *= 1 + transferBurnRate;
        }

        isMinimumBetSattisfied = amountInDepositToken >= minimumBet;
    }

    const tooltipHarvestDescriptionText = (
        <div className="tooltip text-[80%]">
            <p>
                Harvesting rewards though UI requires a minimum bet amount from the pending reward to be placed in a game.
            </p>
            <p>
                The rest of the pending reward is added to your balance.
            </p>
            <p>
                If you don't want to place a bet you need to trigger a transfer event either by claiming game prizes, placing bets, farming, liquidity hunting, etc. or by performing a manual wallet to wallet transfer.
            </p>
        </div>
    );

    const tooltipPlaceBetDescriptionText = (
        <div className="tooltip text-[80%]">
            <p>
                Placing a bet will automatically harvest the pending reward.
            </p>
            <p>
                You can bet with any token available in your wallet, including liquidity provider (LP) tokens. The conversion will be held automatically.
            </p>
        </div>
    );

    return (
        <ModalDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disableBackgroundCancel={false}
            body={
                <div className="grid grid-cols-1 gap-y-3">
                    {enableGameSelection &&
                        <div>
                            <span className="input-label">
                                Select a game:
                            </span>
                            {games
                                ? <GameList
                                    gameService={gameService}
                                    games={games}
                                    defaultGameId={gameId}
                                    setGameId={setGameId}
                                />
                                : <Spinner />
                            }
                        </div>
                    }

                    {selectedGame && betToken &&
                        <>
                            <div>
                                <div>
                                    <span className="input-label">
                                        Bet on an outcome:
                                    </span>
                                </div>
                                <div className="mt-1">
                                    <OutcomeList
                                        outcomeCount={selectedGame.outcomeCount.toInt()}
                                        defaultOutcome={outcome}
                                        setOutcome={setOutcome}
                                    />
                                </div>
                            </div>
                            {enableAmountEntry && minimumBet != undefined && minimumBet > 0 &&
                                <div className="text-center">
                                    <span className={`inline-block input-label uppercase rounded-xl px-3 ring-2 ${isMinimumBetSattisfied ? "bg-emerald-300 ring-emerald-500/60" : "bg-red-400 ring-red-600/60"} `}>
                                        Minimum Bet:
                                        <span className="ml-1">
                                            <MainText
                                                main={betToken.convert.formatCurrency(minimumBet)}
                                                mainUnit={betToken.symbol}
                                                mainClassName="text-[0.875em]"
                                                mainUnitClassName="ml-0.5"
                                            />
                                        </span>
                                    </span>
                                </div>
                            }
                        </>
                    }

                    {!enableGameSelection && enableAmountEntry && betToken &&
                        <TokenSelector
                            wallet={wallet}
                            iziswap={iziswap}
                            moralis={moralis}
                            tokenFinder={tokenFinder}
                            tokens={tokens}
                            chain={chain}
                            depositToken={betToken}
                            setSelectedToken={setSelectedToken}
                            setAmount={setAmount}
                            setAmountInDepositToken={setAmountInDepositToken}
                            setIsLoading={setIsLoading}
                        />
                    }
                </div>
            }
            title={title}
            info={
                <StyledPopover
                    isTooltip={true}
                    children={enableAmountEntry ? tooltipPlaceBetDescriptionText : tooltipHarvestDescriptionText}
                    icon="bi-info"
                />
            }
            submitButtonText={submitButtonText}
            isSubmitButtonDisabled={enableAmountEntry && (isLoading || amount <= 0 || !isMinimumBetSattisfied)}
            onCancel={onCancel}
            onSubmit={() => onSubmit(gameId, selectedToken, amount, outcome)}
        />
    );
}

export default GameBetDialog;