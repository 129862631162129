import { useEffect, useState } from "react";

import { Address, ChainInfo } from "configuration/types";

import { WeebFarmLiquidatablesViewModel, WeebFarmState } from "services/weeb-finance/weeb-farm";

import { PriceBox } from "components/shared";

import WeebFarmLiquidatable from "./weeb-farm-liquidatable";

type WeebFarmLiquidatablesProps = {
    farmService: WeebFarmState;
    chain: ChainInfo;
    senderCreditTokenBalance: number;
    farm: WeebFarmLiquidatablesViewModel;
    liquidate: (poolId: number, investor: Address) => void;
    buyCreditToken: () => void;
}

const WeebFarmLiquidatables = ({
    farmService,
    chain,
    senderCreditTokenBalance,
    farm,
    liquidate,
    buyCreditToken
}: WeebFarmLiquidatablesProps) => {
    const [activePools, setActivePools] = useState(farm.poolLiquidatables);

    const creditToken = farmService.createCreditToken(farm);

    const totalLiquidity = creditToken.convert.fromWei(farm.totalLiquidity.total);
    const totalLiquidatorFee = creditToken.convert.fromWei(farm.totalLiquidatorFee.total)

    useEffect(() => {
        const pools = [...farm.poolLiquidatables];

        pools.sort(function (a, b) {
            return b.totalLiquidity.total.toBN().cmp(a.totalLiquidity.total.toBN());
        });

        // Pools where sender has deposits come first.
        pools.sort(function (a, b) {
            return b.pool.senderPosition.deposited.toBN().cmp(a.pool.senderPosition.deposited.toBN());
        });

        setActivePools(pools);
    }, [farm]);

    if (activePools.length > 0) {
        return (
            <>
                <div className="card bg-weeb-farm/50 bg-diagonal-lines text-center">
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-2 gap-y-4 text-[80%]">
                        <PriceBox
                            title="Credit Token"
                            main={farm.creditToken.details.symbol}
                            mainUnit="&nbsp;"
                            isMainUnitOnTop={true}
                            titleClassName="light-box"
                            mainClassName="font-display"
                            mainUnitClassName="mb-3"
                        />
                        <PriceBox
                            title="Liquidatable Pools"
                            main={farm.poolLiquidatables.length.format(0)}
                            mainUnit="&nbsp;"
                            isMainUnitOnTop={true}
                            titleClassName="light-box"
                            mainUnitClassName="mb-3"
                        />
                        <PriceBox
                            title="Total Liquidity"
                            main={creditToken.convert.formatCurrency(totalLiquidity)}
                            mainUnit={farm.creditToken.details.symbol}
                            isMainUnitOnTop={true}
                            titleClassName="light-box"
                            mainUnitClassName="!opacity-25 mb-3"
                        />
                        <PriceBox
                            //title="Total Liquidator Fee"
                            title="Total Trophy"
                            main={creditToken.convert.formatCurrency(totalLiquidatorFee)}
                            mainUnit={farm.creditToken.details.symbol}
                            isMainUnitOnTop={true}
                            titleClassName="light-box"
                            mainUnitClassName="!opacity-25 mb-3"
                        />
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:text-[80%] gap-x-5 gap-y-10">
                    {(activePools.map((poolLiquidatables, i) => (
                        <div key={i} className="max-h-full">
                            <WeebFarmLiquidatable
                                farmService={farmService}
                                chain={chain}
                                creditToken={creditToken}
                                senderCreditTokenBalance={senderCreditTokenBalance}
                                poolLiquidatables={poolLiquidatables}
                                liquidate={liquidate}
                                buyCreditToken={buyCreditToken}
                            />
                        </div>
                    )))}
                </div>
            </>
        );
    }

    return (
        <div className="card bg-weeb-farm/50 bg-diagonal-lines text-center">
            <div className="light-box text-red-500 ">
                No liquidatable pools found.
            </div >
        </div >
    );
}

export default WeebFarmLiquidatables;