import { useEffect, useState } from "react";

import { DeferredInput, PriceBox } from "components/shared";
import { getBaseAssetOutflowRequiredToDecreaseThePriceByFactorOf, getQuoteAssetInflowRequiredToIncreaseThePriceByFactorOf } from "extensions/price-extensions";
import { ReserveState } from "services/erc20";
import { TokensState } from "services/weeb-finance/tokens";

const enum Mode {
    None,
    Inflow,
    Outflow
}

type InflowOutflowCalculatorProps = {
    tokens: TokensState;
    reserves: ReserveState;
    weebTokenPriceInStableToken: number;
    nativeTokenPriceInStableToken: number;
}

const InflowOutflowCalculator = ({
    tokens,
    reserves,
    weebTokenPriceInStableToken,
    nativeTokenPriceInStableToken
}: InflowOutflowCalculatorProps) => {
    const [targetPrice, setTargetPrice] = useState(weebTokenPriceInStableToken * 2);
    const [mode, setMode] = useState(Mode.None);
    const [assetAmountRequired, setAssetAmountRequired] = useState<number>();

    const [defaultValue, setDefaultValue] = useState<number>();

    useEffect(() => {
        if (!defaultValue) {
            setDefaultValue(targetPrice);
        }
    }, [targetPrice]);

    useEffect(() => {
        const factor = targetPrice / weebTokenPriceInStableToken;

        if (targetPrice > weebTokenPriceInStableToken) {
            const quoteAssetAmountRequired = getQuoteAssetInflowRequiredToIncreaseThePriceByFactorOf(factor, reserves.token2Reserve);

            setAssetAmountRequired(quoteAssetAmountRequired);
            setMode(Mode.Inflow);
        } else if (targetPrice < weebTokenPriceInStableToken) {
            const baseAssetAmountRequired = getBaseAssetOutflowRequiredToDecreaseThePriceByFactorOf(factor, reserves.token1Reserve);

            setAssetAmountRequired(baseAssetAmountRequired);
            setMode(Mode.Outflow);
        } else {
            setAssetAmountRequired(undefined);
            setMode(Mode.None);
        }
    }, [reserves, weebTokenPriceInStableToken, nativeTokenPriceInStableToken, targetPrice]);

    return (
        <div className="grid grid-cols-1 gap-x-2 gap-y-4">
            <div>
                <span className="text-[0.5em] text-violet-100/50 light-box block mb-4 xs:min-h-[5.5em] sm:min-h-[4.5em]">
                    Calculates the amount of inflow or outflow required to bring the price to the desired level.
                </span>
                <div className="grid grid-cols-1 gap-y-1">
                    <label htmlFor="price" className="flex leading-6">
                        <span className="input-label">Target price</span>
                    </label>
                    <DeferredInput
                        type="number"
                        id="price"
                        className="numeric !text-[1em] text-right"
                        defaultValue={tokens.stableToken.convert.formatCurrency(defaultValue ?? 0, 4, 0)}
                        placeholder={`${tokens.weebToken.symbol} price in ${tokens.stableToken.symbol}`}
                        min={0}
                        step={1}
                        onInput={value => {
                            const float = value ? parseFloat(value) : 0;
                            setTargetPrice(isNaN(float) ? 0 : float);
                        }}
                    />
                </div>
            </div>

            <div className="text-[80%]">
                {mode === Mode.Inflow &&
                    <PriceBox
                        title="Required Inflow"
                        main={assetAmountRequired ? tokens.nativeToken.convert.formatCurrency(assetAmountRequired) : "-"}
                        mainUnit={tokens.nativeToken.symbol}
                        detail={assetAmountRequired ? tokens.stableToken.convert.formatCurrency(assetAmountRequired * nativeTokenPriceInStableToken) : "-"}
                        detailUnit={tokens.stableToken.symbol}
                        mainUnitClassName="neon"
                    />
                }
                {mode === Mode.Outflow &&
                    <PriceBox
                        title="Required Outflow"
                        main={assetAmountRequired ? tokens.weebToken.convert.formatCurrency(assetAmountRequired) : "-"}
                        mainUnit={tokens.weebToken.symbol}
                        detail={assetAmountRequired ? tokens.stableToken.convert.formatCurrency(assetAmountRequired * weebTokenPriceInStableToken) : "-"}
                        detailUnit={tokens.stableToken.symbol}
                        mainUnitClassName="glow"
                    />
                }
                {mode === Mode.None && <p>No change</p>}
            </div>
        </div>
    );
}

export default InflowOutflowCalculator;