import { ChainInfo, AppSettings } from "./types";

const projectCommon = readProjectSettings(null);
const projectEnvironment = readProjectSettings(process.env.NODE_ENV);

const appSettings = Object.assign(projectCommon, projectEnvironment,
    {
        chains: createChains(process.env.NODE_ENV, [31337])
    }
) as AppSettings;

export default appSettings;

export function getRpcUris(chains: Record<number, ChainInfo>) {
    const rpcUris: Record<number, string> = {};
    const keys = Object.keys(chains).map(value => parseInt(value));

    for (let i = 0; i < keys.length; ++i) {
        rpcUris[keys[i]] = chains[keys[i]].rpcUri;
    }

    return rpcUris;
}

function createChains(environment: string, chainIds: number[]) {
    const chains: Record<number, ChainInfo> = {};

    for (let i = 0; i < chainIds.length; ++i) {
        chains[chainIds[i]] = createChain(environment, chainIds[i]);
    }

    return chains;
}

function createChain(environment: string | null, chainId: number) {
    return Object.assign(readChainSettings(null, chainId), readChainSettings(environment, chainId)) as ChainInfo;
}

function readProjectSettings(environment: string | null) {
    return require(`./settings/project${environment ? `.${environment}` : ""}.json`);
}

function readChainSettings(environment: string | null, chainId: number) {
    return require(`./settings/chains/${chainId}${environment ? `.${environment}` : ""}.json`);
}
