export function getError(e: unknown): Error {
    let error: Error;

    if (typeof e === "string") {
        error = new Error(e);
    } else if (e instanceof Error) {
        error = e;
    } else {
        error = new Error("Unknown error.");
        error.name = "unknownError";
        error.cause = new Error(JSON.stringify(e));
    }

    return error;
}

export function getUnsupportedChainError(chainId: number): Error {
    const e = new Error(`Unsupported chain: ${chainId}`);
    e.name = "unsupportedChain";

    return e;
}

export function getInjectedProviderNotFoundError(): Error {
    const e = new Error("Injected provider not found.");
    e.name = "injectedProviderNotFound";

    return e;
}

export function getAddEthereumChainError(chainId: number, error: unknown): Error {
    const e = new Error(`Add ethereum chain failed: ${chainId}`);
    e.name = "addEthereumChainError";
    e.cause = getError(error);

    return e;
}

export function getSwitchEthereumChainError(chainId: number, error: unknown): Error {
    const e = new Error(`Switch ethereum chain failed: ${chainId}`);
    e.name = "switchEthereumChainError";
    e.cause = getError(error);

    return e;
}

export function getInvalidOutcomeError(outcomeCount: number, outcome: number): Error {
    const e = new Error(`Outcome "${outcome}" is greater than outcome count: ${outcomeCount}`);
    e.name = "invalidOutcomeError";

    return e;
}
