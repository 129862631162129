import { ReactNode, useState } from "react";
import { usePopper } from "react-popper";
import { Portal, Popover } from "@headlessui/react";

type StyledPopoverProps = {
    isTooltip: boolean,
    children: ReactNode,
    icon?: string
}

const StyledPopover = ({
    isTooltip,
    children,
    icon
}: StyledPopoverProps) => {
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement);

    return (
        <Popover as="span">
            <Popover.Button as="i"
                ref={setReferenceElement}
                className={`bi ${isTooltip ? icon ?? "bi-question-circle" : icon ?? "bi-three-dots"} cursor-pointer hover:animate-pulse`}>
            </Popover.Button>

            <Portal>
                <Popover.Panel
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className="z-10 card popover rounded-lg shadow w-full max-w-xl">
                    {children}
                </Popover.Panel>
            </Portal>
        </Popover>
    );
}

export default StyledPopover;