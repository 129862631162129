import { Fragment } from "react";

import { DetailText, MainText } from "components/shared";
import { Address } from "configuration/types";

import { format } from "services/format";
import { TokenState } from "services/erc20";
import { WeebFarmInvestorLiquidatableViewModel, WeebFarmPoolLiquidatablesViewModel } from "services/weeb-finance/weeb-farm";

export const getPoolSelfTotals = (creditToken: TokenState, poolLiquidatables: WeebFarmPoolLiquidatablesViewModel) => {
    const liquidatables = poolLiquidatables.investorLiquidatables.filter(i => i.isSelf);
    const fee = liquidatables.reduce((sum, investorLiquidatable) => sum + creditToken.convert.fromWei(investorLiquidatable.liquidatorFee), 0);

    return { fee, count: liquidatables.length };
}

type FarmLiquidatablesProps = {
    creditToken: TokenState;
    senderCreditTokenBalance: number;
    poolId: number;
    investorLiquidatables: WeebFarmInvestorLiquidatableViewModel[];
    liquidatorBalanceRate: number;
    liquidate: (poolId: number, investor: Address) => void;
    buyCreditToken: () => void;
}

export const FarmLiquidatables = ({
    creditToken,
    senderCreditTokenBalance,
    poolId,
    investorLiquidatables,
    liquidatorBalanceRate,
    liquidate,
    buyCreditToken
}: FarmLiquidatablesProps) => {
    const items = investorLiquidatables.map((investorLiquidatable, i) => {
        const liquidity = creditToken.convert.fromWei(investorLiquidatable.liquidity);
        const minBalance = liquidity * liquidatorBalanceRate;

        return (
            <Fragment key={`${i}_${investorLiquidatable.investor}`}>
                <div>
                    <MainText
                        main={(
                            <>
                                <span className="sm:hidden">
                                    {format.formatAccount(investorLiquidatable.investor, undefined, true)}
                                </span>
                                <span className="hidden sm:inline">
                                    {format.formatAccount(investorLiquidatable.investor)}
                                </span>
                            </>
                        )}
                        mainClassName="text-[0.875em]"
                    />
                    {investorLiquidatable.isSelf &&
                        <div>
                            <DetailText detail="You" detailClassName="font-display rounded-lg bg-red-600 px-1" />
                        </div>
                    }
                </div>
                <MainText main={creditToken.convert.formatCurrency(liquidity)} />
                <MainText main={investorLiquidatable.isSelf
                    ? (<del>{creditToken.convert.formatCurrency(creditToken.convert.fromWei(investorLiquidatable.liquidatorFee))}</del>)
                    : creditToken.convert.formatCurrency(creditToken.convert.fromWei(investorLiquidatable.liquidatorFee))
                }
                />
                {senderCreditTokenBalance >= minBalance
                    ?
                    <button
                        type="button"
                        className="btn btn-red px-2 py-1 truncate"
                        onClick={() => liquidate(poolId, investorLiquidatable.investor)}
                    >
                        Liquidate
                    </button>
                    :
                    <button
                        type="button"
                        className="btn btn-green px-2 py-1 truncate"
                        title={`Buy "${creditToken.convert.formatCurrency(minBalance - senderCreditTokenBalance)}" ${creditToken.symbol}`}
                        onClick={buyCreditToken}
                    >
                        Increase Balance
                    </button>
                }
            </Fragment>
        );
    });

    return (<>{items}</>);
}