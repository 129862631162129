import BN from "bn.js";

import { PromiEvent } from "web3-core/types";
import { AbiItem } from "web3-utils/types";
import { Contract, ContractOptions } from "web3-eth-contract/types";

import appSettings from "configuration";

import { AnonymousWalletInfo, PersonalWalletInfo } from "services/shared.types";
import { IziswapState } from "services/iziswap";
import { createToken, TokenState } from "services/erc20/token";
import { SettingsRepositoryState } from "services/settings-repository";
import { convert } from "services/convert";
import { WeebGameViewModel, WeebGamePoolViewModel, WeebGameState } from "./weeb-game.types";

import WeebGame_ABI from "contracts/abi/WeebGame.json";

const createWeebGame = (wallet: AnonymousWalletInfo | PersonalWalletInfo, iziswap: IziswapState, settings: SettingsRepositoryState): WeebGameState => {
    const chain = appSettings.chains[wallet.chainId];

    const options = { from: wallet.reader.defaultAccount } as ContractOptions;
    const abi = WeebGame_ABI as AbiItem[];

    const readerContract = new wallet.reader.eth.Contract(abi, chain.contracts.weebGame, options);

    const read = {
        readerContract,
        senderGameSnapshots: (isEnabled: boolean): Promise<WeebGameViewModel[]> => readerContract.methods.senderGameSnapshots(isEnabled ? 1 : 0).call(),
        senderBetPoolCount: (gameId: number): Promise<number> => readerContract.methods.senderBetPoolCount(gameId).call(),
        senderPoolSnapshots: (gameId: number, offset: number, count: number): Promise<WeebGamePoolViewModel[]> => readerContract.methods.senderPoolSnapshots(gameId, offset, count).call(),
        senderBetPools: (gameId: number): Promise<number[]> => readerContract.methods.senderBetPools(gameId).call(),
    }

    let sign: {
        signerContract?: Contract,
        claim?: (gameId: number) => PromiEvent<Contract>,
        compound?: (gameId: number, outcome: number) => PromiEvent<Contract>,
        betETH?: (gameId: number, amountETHInWei: BN, outcome: number, betToken: TokenState) => Promise<Contract>,
        bet?: (gameId: number, amount: BN, outcome: number) => PromiEvent<Contract>,
        betToken?: (gameId: number, token: TokenState, amountInWei: BN, outcome: number, betToken: TokenState) => Promise<Contract>,
    };

    if ("signer" in wallet && wallet.signer) {
        const signerContract = new wallet.signer.eth.Contract(abi, chain.contracts.weebGame, options);

        sign = {
            claim: (gameId: number): PromiEvent<Contract> => wallet.track(signerContract.methods.claim(gameId).send(), { name: "claim" }),
            compound: (gameId: number, outcome: number): PromiEvent<Contract> => wallet.track(signerContract.methods.compound(gameId, outcome).send(), { name: "compound" }),
            betETH: async (gameId: number, amountETHInWei: BN, outcome: number, betToken: TokenState): Promise<Contract> => {
                const amountETH = convert.fromWei(amountETHInWei, chain.nativeCoin.decimals);
                const betTokenAmountOut = await betToken.quote.quoteFromNativeCoin(amountETH);
                const betTokenAmountMin = (betTokenAmountOut * settings.getSlippageTolerance()).toRate();
                const betTokenAmountOutMinInWei = betToken.convert.toWei(betTokenAmountOut - betTokenAmountMin);

                return await wallet.track(signerContract.methods.betETH(gameId, outcome, betTokenAmountOutMinInWei).send({ value: amountETHInWei }), { name: "betETH" });
            },
            bet: (gameId: number, amount: BN, outcome: number): PromiEvent<Contract> => {
                console.log({ gameId, amount, outcome }, signerContract.methods);

                return wallet.track(signerContract.methods.bet(gameId, amount, outcome).send(), { name: "bet" });
            },
            betToken: async (gameId: number, token: TokenState, amountInWei: BN, outcome: number, betToken: TokenState): Promise<Contract> => {
                const amount = token.convert.fromWei(amountInWei);
                const betTokenAmountOut = await token.quote.quoteToTargetTokenDirect(amount, betToken);
                const betTokenAmountMin = (betTokenAmountOut * settings.getSlippageTolerance()).toRate();
                const betTokenAmountOutMin = betToken.convert.toWei(betTokenAmountOut - betTokenAmountMin);

                return await wallet.track(signerContract.methods.bet(gameId, token.router, token.token, token.isLiquidityToken, amountInWei, outcome, betTokenAmountOutMin).send(), { name: "betToken" });
            },
        }
    } else {
        sign = {};
    }

    const base = {
        getSymbol: (game: WeebGameViewModel) => game.isLiquidityToken ? `${game.token0Details.symbol}/${game.token1Details.symbol}` : game.betToken.details.symbol,
    };

    var zero = "0".toBN();

    return {
        ...base,
        createBetToken: (game: WeebGameViewModel) => {
            return {
                ...createToken({
                    wallet,
                    iziswap,
                    router: game.betToken.router,
                    token: game.betToken.token,
                    decimals: game.betToken.details.decimals.toNumber(),
                    isLiquidityToken: game.isLiquidityToken,
                    symbol: base.getSymbol(game)
                }),
            }
        },
        ...read,
        ...sign,
        harvestPendingReward: (gameId: number, outcome: number) => sign.bet?.(gameId, zero, outcome),
    }
}

export default createWeebGame;