import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";

import { DeferredInput, PriceBox } from "components/shared";
import { getPriceDecreaseFactorFromBaseAssetOutflow, getPriceIncreaseFactorFromQuoteAssetInflow } from "extensions/price-extensions";

import { ReserveState } from "services/erc20";
import { TokensState } from "services/weeb-finance/tokens";

const enum Mode {
    Inflow,
    Outflow
}

type TargetPriceCalculatorProps = {
    tokens: TokensState;
    reserves: ReserveState;
    weebTokenPriceInStableToken: number;
    nativeTokenPriceInStableToken: number;
}

const TargetPriceCalculator = ({
    tokens,
    reserves,
    weebTokenPriceInStableToken,
    nativeTokenPriceInStableToken
}: TargetPriceCalculatorProps) => {
    const [mode, setMode] = useState(Mode.Inflow);
    const [assetAmount, setAssetAmount] = useState(1);
    const [targetPrice, setTargetPrice] = useState(0);

    useEffect(() => {
        const factor = mode === Mode.Inflow
            ? getPriceIncreaseFactorFromQuoteAssetInflow(assetAmount, reserves.token2Reserve)
            : getPriceDecreaseFactorFromBaseAssetOutflow(assetAmount, reserves.token1Reserve);
        setTargetPrice(weebTokenPriceInStableToken * factor);
    }, [reserves, weebTokenPriceInStableToken, nativeTokenPriceInStableToken, mode, assetAmount]);

    return (
        <div className="grid grid-cols-1 gap-x-2 gap-y-4">
            <div>
                <span className="text-[0.5em] text-violet-100/50 light-box block mb-4 xs:min-h-[5.5em] sm:min-h-[4.5em]">
                    Calculates the level to which the given inflow or outflow amount brings the price.
                </span>
                <div className="grid grid-cols-1 gap-y-1">
                    <Switch.Group>
                        <div className="flex items-center">
                            <Switch
                                checked={mode === Mode.Inflow}
                                onChange={() => setMode(current => current === Mode.Inflow ? Mode.Outflow : Mode.Inflow)}
                                className="bg-violet-300 ring-offset-slate-800 focus:ring-violet-300/50 relative inline-flex items-center w-10 h-[1em] rounded-full transition-colors focus:outline-none ring-2 ring-offset-2 ring-transparent"
                            >
                                <span className="sr-only">Flow mode</span>
                                <span className={`${mode === Mode.Inflow ? "translate-x-6" : "translate-x-1"} bg-slate-800 inline-block w-[0.75em] h-[0.75em] transform rounded-full transition-transform`} />
                            </Switch>
                            <Switch.Label className="input-label leading-6 ml-1.5">
                                {mode === Mode.Inflow ? `Inflow ${tokens.nativeToken.symbol}` : `Outflow ${tokens.weebToken.symbol}`}
                            </Switch.Label>
                        </div>
                    </Switch.Group>
                    <DeferredInput
                        type="number"
                        id="assetAmount"
                        className="numeric !text-[1em] text-right"
                        defaultValue={assetAmount}
                        placeholder="Asset amount"
                        min={0}
                        step={1}
                        onInput={value => {
                            const float = value ? parseFloat(value) : 0;
                            setAssetAmount(isNaN(float) ? 0 : float);
                        }}
                    />
                </div>
            </div>

            <div className="text-[80%]">
                <PriceBox
                    title="Target Price"
                    main={isNaN(targetPrice) ? "-" : tokens.stableToken.convert.formatCurrency(targetPrice)}
                    mainUnit={tokens.stableToken.symbol}
                    detail={isNaN(targetPrice) ? "-" : tokens.nativeToken.convert.formatCurrency(targetPrice / nativeTokenPriceInStableToken)}
                    detailUnit={tokens.nativeToken.symbol}
                    mainUnitClassName="glow"
                />
            </div>
        </div>
    );
}

export default TargetPriceCalculator;