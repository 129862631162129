import { getError } from "errors";

import { TokenState } from "services/erc20";
import { format } from "services/format";
import { TokensState } from "services/weeb-finance/tokens";
import { WeebFarmPoolViewModel } from "services/weeb-finance/weeb-farm";

import { PriceBox } from "components/shared";
import { StyledPopover } from "components/shared/dialogs";

type ActivityProps = {
    count: number,
    timestamp: number,
    now: number
}

export const Activity = ({
    count,
    timestamp,
    now
}: ActivityProps) => {
    return (
        <div className="flex justify-evenly space-x-1">
            <span className="light-box w-full">
                <i className="bi bi-app-indicator mr-1" />
                {count.format(0)}
            </span>
            <span className="light-box w-full">
                <i className="bi bi-clock mr-1" />
                {timestamp > 0 ? format.formatTimespan(now - timestamp) : "-"}
            </span>
        </div>
    );
}

type TokenAvatarsProps = {
    pool: WeebFarmPoolViewModel
}

export const TokenAvatars = ({
    pool
}: TokenAvatarsProps) => {
    return pool.isLiquidityToken
        ?
        (
            <>
                <div className="avatar">
                    <span className="card-title opacity-25 text-uppercase m-0" style={{ fontSize: "x-small" }}>
                        {pool.token0Details.symbol}
                    </span>
                </div>
                <div className="avatar">
                    <span className="card-title opacity-25 text-uppercase m-0" style={{ fontSize: "x-small" }}>
                        {pool.token1Details.symbol}
                    </span>
                </div>
            </>
        )
        :
        (
            <div className="avatar">
                <span className="card-title opacity-25 text-uppercase m-0" style={{ fontSize: "x-small" }}>
                    {pool.depositToken.details.symbol}
                </span>
            </div>
        );
}

type PopoverFarmPoolContentProps = {
    tokens: TokensState,
    nativeTokenPriceInStableToken: number,
    pool: WeebFarmPoolViewModel,
    depositToken: TokenState,
    depositTokenExplorerUri: string,
    seignioryExplorerUri: string,
    now: number
}

export const PopoverFarmPoolContent = ({
    tokens,
    nativeTokenPriceInStableToken,
    pool,
    depositToken,
    depositTokenExplorerUri,
    seignioryExplorerUri,
    now
}: PopoverFarmPoolContentProps) => {
    const tooltipSeigniorageFeeText = (
        <span className="tooltip text-[80%]">Fee paid to the seignory (pool owner) from deposit fee.</span>
    );

    const seigniorageFee = pool.seigniorageFee.rate.toNumber().toRate();
    const seigniorageFeeCollected = tokens.nativeToken.convert.fromWei(pool.seigniorageFee.collected.total);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-6 text-[80%]">
            <PriceBox
                title="Deposit Token Address"
                main={(
                    <>
                        <i className="bi bi-clipboard mr-1 cursor-pointer inline-block active:animate-ping"
                            onClick={_ => {
                                navigator.clipboard.writeText(depositToken.token)
                                    .catch(ex => console.error(getError(ex)));
                            }}
                        />
                        <a href={depositTokenExplorerUri} rel="noreferrer" target="_blank" className="numeric hover:select-all">
                            {format.formatAccount(depositToken.token)}
                        </a>
                    </>
                )}
            />
            <PriceBox title="Investor Count" main={pool.investorCount.toInt().format(0)} />
            <PriceBox
                title={(
                    <>
                        <span className="mr-1">Seigniorage Fee</span>
                        <StyledPopover isTooltip={true} children={tooltipSeigniorageFeeText} />
                    </>
                )}
                main={seigniorageFee.toPercent().formatPercentage()}
                mainUnit="%"
                detail={(
                    <>
                        <i className="bi bi-clipboard mr-1 cursor-pointer inline-block active:animate-ping"
                            onClick={_ => {
                                navigator.clipboard.writeText(pool.seigniory)
                                    .catch(ex => console.error(getError(ex)));
                            }} />
                        <a href={seignioryExplorerUri} rel="noreferrer" target="_blank" className="numeric">
                            {format.formatAccount(pool.seigniory)}
                        </a>
                    </>
                )}
                info={pool.isSenderSeignory
                    ? (<span className="font-display">You're Seignory</span>)
                    : null
                }
                infoClassName="opacity-75 light-box font-semibold uppercase text-red-500"
            />
            <PriceBox
                title="Seigniorage Collected"
                main={tokens.nativeToken.convert.formatCurrency(seigniorageFeeCollected)}
                mainUnit={tokens.nativeToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(seigniorageFeeCollected * nativeTokenPriceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(
                    <Activity
                        count={pool.seigniorageFee.collected.count.toInt()}
                        timestamp={pool.seigniorageFee.collected.timestamp.toInt()}
                        now={now}
                    />
                )}
            />
        </div>
    );
}