import { useState } from "react";
import { Switch } from "@headlessui/react";

import { MainText, RangeInput } from "components/shared";
import { ModalDialog } from "components/shared/dialogs";

import { convert } from "services/convert";
import { TokensState } from "services/weeb-finance/tokens";
import { TokenState } from "services/erc20";

type AmountSelectionDialogProps = {
    tokens: TokensState;
    creditToken: TokenState;
    credited: number,
    priceInStableToken: number,
    depositTokenSymbol: string,

    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    title: string,
    submitButtonText: string
    onCancel?: Function,
    onSubmit: Function,
}

const AmountSelectionDialog = ({
    tokens,
    creditToken,
    credited,
    priceInStableToken,
    depositTokenSymbol,

    isOpen,
    setIsOpen,
    title,
    submitButtonText,
    onCancel,
    onSubmit,
}: AmountSelectionDialogProps) => {
    const [amount, setAmount] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [convertToWeeb, setConvertToWeeb] = useState(false);

    return (
        <ModalDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disableBackgroundCancel={false}
            body={
                <>
                    <div className="flex items-center justify-between leading-6">
                        <span className="input-label">
                            Amount:
                            <span className="ml-1">
                                <MainText
                                    main={percentage.toPercent().formatPercentage()}
                                    mainUnit="%"
                                    mainClassName="text-[0.875em]"
                                    mainUnitClassName="ml-0.5"
                                />
                            </span>
                        </span>
                        <span className="input-label">
                            Balance:
                            <MainText
                                main={convert.formatCurrency(credited, creditToken.decimals)}
                                mainUnit={creditToken.symbol}
                                mainClassName="text-[0.875em] ml-1"
                                mainUnitClassName="ml-0.5"
                            />
                        </span>
                    </div>
                    <RangeInput
                        isDisabled={false}
                        min={0}
                        max={credited}
                        decimals={creditToken.decimals}
                        setValue={setAmount}
                        setPercentage={setPercentage}
                        valueClassName="text-indigo-900 border-indigo-300/50 focus:ring-indigo-300/50"
                        rangeClassName="accent-indigo-900"
                    />
                    <div className="text-center mt-3">
                        <MainText
                            main={tokens.stableToken.convert.formatCurrency(amount * priceInStableToken)}
                            mainUnit={tokens.stableToken.symbol}
                            mainClassName="text-[0.875em]"
                        />
                    </div>
                    <Switch.Group>
                        <div className="flex items-center mt-3">
                            <Switch
                                checked={convertToWeeb}
                                onChange={setConvertToWeeb}
                                className={`${convertToWeeb
                                    ? "bg-violet-700 ring-offset-violet-400 focus:ring-violet-200"
                                    : "bg-indigo-900 ring-offset-indigo-500 focus:ring-indigo-300"}
                                       relative inline-flex items-center w-11 h-5 rounded-full transition-colors focus:outline-none ring-2 ring-offset-2 ring-transparent`
                                }
                            >
                                <span className="sr-only">
                                    Withdraw as
                                </span>
                                <span className={`${convertToWeeb ? "translate-x-6 bg-violet-200" : "translate-x-1 bg-indigo-300"} inline-block w-4 h-4 transform rounded-full transition-transform`} />
                            </Switch>
                            <Switch.Label className="leading-6 ml-1.5">
                                Withdraw as {convertToWeeb ? tokens.weebToken.symbol : depositTokenSymbol}
                            </Switch.Label>
                        </div>
                    </Switch.Group>
                </>
            }
            title={title}
            submitButtonText={submitButtonText}
            onCancel={onCancel}
            onSubmit={() => onSubmit(amount, convertToWeeb)}
        />
    )
}

export default AmountSelectionDialog;