import { AbiItem } from "web3-utils/types";
import { ContractOptions } from "web3-eth-contract/types";

import appSettings from "configuration";
import { Address } from "configuration/types";

import { AnonymousWalletInfo } from "services/shared.types";
import { TokenFinderState, TokenResult } from "./token-finder.types";

import TokenFinder_ABI from "contracts/abi/TokenFinder.json";

const createTokenFinder = (wallet: AnonymousWalletInfo): TokenFinderState => {
    const chain = appSettings.chains[wallet.chainId];

    const options = { from: wallet.reader.defaultAccount } as ContractOptions;
    const abi = TokenFinder_ABI as AbiItem[];

    const readerContract = new wallet.reader.eth.Contract(abi, chain.contracts.tokenFinder, options);

    const read = {
        readerContract,
        find: (token: Address): Promise<TokenResult> => readerContract.methods.find(token).call(),
        findAll: (tokens: Address[]): Promise<TokenResult[]> => readerContract.methods.findAll(tokens).call(),
    };

    return {
        getName: (token: TokenResult) => token.isLiquidityToken ? `${token.token0.details.name}/${token.token1.details.name}` : token.sourceToken.details.name,
        getSymbol: (token: TokenResult) => token.isLiquidityToken ? `${token.token0.details.symbol}/${token.token1.details.symbol}` : token.sourceToken.details.symbol,
        ...read,
    }
}

export default createTokenFinder;