import { useEffect, useState } from "react";

import { PriceBox } from "components/shared";
import { StyledPopover } from "components/shared/dialogs";
import { ChainInfo } from "configuration/types";

import { format } from "services/format";
import { TokenState } from "services/erc20";
import { TokensState } from "services/weeb-finance/tokens";
import { WeebFarmPoolViewModel, WeebFarmState } from "services/weeb-finance/weeb-farm";

import { Activity, PopoverFarmPoolContent, TokenAvatars } from "./weeb-farm-pool.fragments";

type WeebFarmPoolProps = {
    farmService: WeebFarmState;
    tokens: TokensState;
    blockTime: number;
    currentBlock: number;
    chain: ChainInfo;
    creditToken: TokenState;
    pool: WeebFarmPoolViewModel;
    creditTokenPriceInStableToken: number;
    nativeTokenPriceInStableToken: number;
    deposit: () => void;
    withdraw: () => void;
    rebalance: () => void;
}

const WeebFarmPool = ({
    farmService,
    tokens,
    blockTime,
    currentBlock,
    chain,
    creditToken,
    pool,
    creditTokenPriceInStableToken,
    nativeTokenPriceInStableToken,
    deposit,
    withdraw,
    rebalance
}: WeebFarmPoolProps) => {
    const [isInteractionDisabled, setIsInteractionDisabled] = useState<boolean>(false);
    const [isDetailed, setIsDetailed] = useState<boolean>(true);
    const [isPoolTotalsVisibile, setIsPoolTotalsVisibile] = useState<boolean>(false);
    const [depositTokenPriceInStableToken, setDepositTokenPriceInStableToken] = useState<number>();

    const depositTokenSymbol = farmService.getDepositTokenSymbol(pool);
    const depositToken = farmService.createDepositToken(pool);
    const depositTokenRouterName = chain.contracts.swapRouters[depositToken.router].name;

    useEffect(() => {
        depositToken.price.priceInStableToken()
            .then(price => {
                setDepositTokenPriceInStableToken(price);
            });
    }, []);

    const now = Date.now() / 1000;
    const depositTokenExplorerUri = `${chain.blockExplorerLink}/token/${pool.depositToken.token}`;
    const seignioryExplorerUri = `${chain.blockExplorerLink}/address/${pool.seigniory}`;

    const creditMultiplier = pool.creditMultiplier.toNumber().toRate();
    const depositFeeRate = pool.depositFee.rate.toNumber().toRate().toPercent();
    const creditLiquidationRate = pool.creditLiquidationRate.toNumber().toRate().toPercent();
    const liquidatorBalanceRate = pool.liquidatorBalanceRate.toNumber().toRate();
    const liquidatorFeeRate = pool.liquidatorFee.rate.toNumber().toRate().toPercent();
    const senderBalance = depositToken.convert.fromWei(pool.senderBalance);
    const senderCreditMultiplier = pool.senderPosition.creditMultiplier.toNumber().toRate();
    const senderDeposited = depositToken.convert.fromWei(pool.senderPosition.deposited);
    const senderCredited = creditToken.convert.fromWei(pool.senderPosition.credited);
    const senderLiquidated = depositToken.convert.fromWei(pool.senderPosition.liquidated.total);
    const senderCreditLiquidationLevel = creditToken.convert.fromWei(pool.senderPosition.creditLiquidationLevel);
    const senderCurrentCredit = creditToken.convert.fromWei(pool.senderCurrentCredit);
    const requiresRebalancing = senderCredited !== senderCurrentCredit;
    const isLiquidatable = senderCurrentCredit < senderCreditLiquidationLevel;
    const totalDeposited = depositToken.convert.fromWei(pool.totalPosition.deposited);
    const totalCredited = creditToken.convert.fromWei(pool.totalPosition.credited);
    const totalLiquidated = depositToken.convert.fromWei(pool.totalPosition.liquidated.total);

    let updatedBlockId = pool.senderPosition.updatedBlockId.toInt();
    const senderTimeFromUpdate = updatedBlockId > 0 ? (currentBlock - updatedBlockId) * blockTime : 0;

    updatedBlockId = pool.totalPosition.updatedBlockId.toInt();
    const totalTimeFromUpdate = updatedBlockId > 0 ? (currentBlock - updatedBlockId) * blockTime : 0;

    const togglePoolTotalsVisibility = () => {
        setIsPoolTotalsVisibile(current => !current);
    }

    const depositFeeRateText = (
        <span className="tooltip text-[80%]">Amount of the deposit used to provide liquidity and seigniorage.</span>
    );

    return (
        <div className={`card bg-weeb-farm/50 bg-diagonal-lines`}>
            <div className="grid grid-cols-12 gap-x-2 gap-y-0.5">
                <div className="col-span-7">
                    <span className="block opacity-75 text-uppercase fs-6 m-0">
                        <span
                            className="mr-1 cursor-pointer"
                            onClick={_ => setIsDetailed(current => !current)}
                        >
                            <i className={`bi ${(isDetailed ? "bi-caret-down-fill" : "bi-caret-right-fill")} mr-1`} />
                            <span className="font-semibold inline-block hover:animate-wiggle">{depositTokenSymbol}</span>
                        </span>
                        <StyledPopover
                            isTooltip={false}
                            children={(
                                <PopoverFarmPoolContent
                                    tokens={tokens}
                                    nativeTokenPriceInStableToken={nativeTokenPriceInStableToken}
                                    pool={pool}
                                    depositToken={depositToken}
                                    depositTokenExplorerUri={depositTokenExplorerUri}
                                    seignioryExplorerUri={seignioryExplorerUri}
                                    now={now}
                                />
                            )}
                        />
                    </span>
                    <span className="opacity-25 text-[0.75em]">
                        {depositTokenRouterName}
                    </span>
                </div>
                <div className="col-span-5 flex items-center justify-end text-[0.75em]">
                    <TokenAvatars pool={pool} />
                </div>

                <div className="col-span-12 mt-0.5 mb-3 border-bottom-t4 border-2"></div>
            </div>

            <div className="grid grid-cols-1 gap-x-2 gap-y-4">
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-2 gap-y-6 text-[80%]">
                    <PriceBox
                        title="Credit Multiplier"
                        main={`×${(creditMultiplier).formatScaled(2)}`}
                        titleClassName="light-box"
                    />
                    <PriceBox
                        title={(
                            <>
                                <span className="mr-1">Deposit Fee</span>
                                <StyledPopover isTooltip={true} children={depositFeeRateText} />
                            </>
                        )}
                        main={depositFeeRate.formatPercentage()}
                        mainUnit="%"
                        titleClassName="light-box"
                    />
                    <PriceBox
                        title="Credit Liq. Rate"
                        main={creditLiquidationRate.formatPercentage()}
                        mainUnit="%"
                        titleClassName="light-box"
                    />
                    <PriceBox
                        title="Liquidator Fee"
                        main={liquidatorFeeRate.formatPercentage()}
                        mainUnit="%"
                        titleClassName="light-box"
                    />
                </div>

                <div className="text-[80%] -mb-2">
                    <div className="flex items-center justify-between group-title opacity-75 light-box px-2">
                        <span className={`${senderDeposited > 0 && !isDetailed ? "animate-pulse" : ""}`}>
                            Your Position
                        </span>
                        <span className="numeric opacity-25">
                            <i className="bi bi-clock mr-1" />
                            {format.formatTimespan(senderTimeFromUpdate)}
                        </span>
                    </div>
                </div>

                {isDetailed &&
                    <>
                        <div className="grid grid-cols-3 gap-x-2 gap-y-6 text-[80%]">
                            <PriceBox
                                title="Balance"
                                main={depositToken.convert.formatCurrency(senderBalance)}
                                mainUnit={depositTokenSymbol}
                                detail={depositTokenPriceInStableToken
                                    ? tokens.stableToken.convert.formatCurrency(depositTokenPriceInStableToken * senderBalance)
                                    : "-"
                                }
                                detailUnit={tokens.stableToken.symbol}
                                isMainUnitOnTop={true}
                                mainUnitClassName="!opacity-25 mb-3"
                                titleClassName="light-box"
                            />
                            <PriceBox
                                title="Deposited"
                                main={depositToken.convert.formatCurrency(senderDeposited)}
                                mainUnit={depositTokenSymbol}
                                detail={depositTokenPriceInStableToken
                                    ? tokens.stableToken.convert.formatCurrency(depositTokenPriceInStableToken * senderDeposited)
                                    : "-"
                                }
                                detailUnit={tokens.stableToken.symbol}
                                isMainUnitOnTop={true}
                                mainUnitClassName="!opacity-25 mb-3"
                                titleClassName="light-box"
                            />
                            <PriceBox
                                title="Credited"
                                main={creditToken.convert.formatCurrency(senderCredited)}
                                mainUnit={creditToken.symbol}
                                detail={creditTokenPriceInStableToken
                                    ? tokens.stableToken.convert.formatCurrency(creditTokenPriceInStableToken * senderCredited)
                                    : "-"
                                }
                                detailUnit={tokens.stableToken.symbol}
                                info={(senderCreditMultiplier !== 0 && senderCreditMultiplier !== creditMultiplier) &&
                                    <div className="flex justify-evenly space-x-1">
                                        <span className="light-box w-full font-display">
                                            Multiplier
                                        </span>
                                        <span className="light-box w-full">{`×${(senderCreditMultiplier).formatScaled(2)}`}</span>
                                    </div>
                                }
                                isMainUnitOnTop={true}
                                mainUnitClassName="!opacity-25 mb-3"
                                titleClassName="light-box"
                            />

                            <PriceBox
                                title="Current Level"
                                main={creditToken.convert.formatCurrency(senderCurrentCredit)}
                                mainUnit={creditToken.symbol}
                                isMainUnitOnTop={true}
                                mainClassName={`${senderCurrentCredit > senderCredited
                                    ? "animate-pulse neon"
                                    : senderCurrentCredit < senderCreditLiquidationLevel
                                        ? "animate-pulse glow"
                                        : ""}`
                                }
                                mainUnitClassName="!opacity-25 mb-3"
                                titleClassName="light-box"
                            />
                            <PriceBox
                                title={(
                                    <>
                                        <span className="xs:hidden">
                                            Liq. Level
                                        </span>
                                        <span className="hidden xs:inline">
                                            Liquidation Level
                                        </span>
                                    </>
                                )}
                                main={creditToken.convert.formatCurrency(senderCreditLiquidationLevel)}
                                mainUnit={creditToken.symbol}
                                isMainUnitOnTop={true}
                                mainUnitClassName="!opacity-25 mb-3"
                                titleClassName="light-box"
                            />
                            <PriceBox
                                title="Liquidated"
                                main={depositToken.convert.formatCurrency(senderLiquidated)}
                                mainUnit={depositTokenSymbol}
                                detail={depositTokenPriceInStableToken
                                    ? tokens.stableToken.convert.formatCurrency(depositTokenPriceInStableToken * senderLiquidated)
                                    : "-"
                                }
                                detailUnit={tokens.stableToken.symbol}
                                info={(
                                    <Activity
                                        count={pool.senderPosition.liquidated.count.toInt()}
                                        timestamp={pool.senderPosition.liquidated.timestamp.toInt()}
                                        now={now}
                                    />
                                )}
                                isMainUnitOnTop={true}
                                mainUnitClassName="!opacity-25 mb-3"
                                titleClassName="light-box"
                            />
                        </div>

                        <div className="grid grid-cols-3 gap-x-2 gap-y-4 leading-none auto-rows-auto text-[0.75em]">
                            <button
                                type="button"
                                className="h-9 btn btn-blue"
                                disabled={isInteractionDisabled /*|| senderBalance === 0*/}
                                onClick={deposit}
                            >
                                Deposit
                            </button>
                            <button
                                type="button"
                                className="h-9 btn btn-slate"
                                disabled={isInteractionDisabled || senderDeposited === 0}
                                onClick={withdraw}
                            >
                                Withdraw
                            </button>
                            <button
                                type="button"
                                className={`h-9 btn ${isLiquidatable ? "btn-red" : "btn-green"}`}
                                disabled={isInteractionDisabled || senderDeposited === 0 || !(requiresRebalancing || isLiquidatable)}
                                onClick={rebalance}
                            >
                                <span className={`${requiresRebalancing || isLiquidatable ? "animate-pulse" : ""}`}>
                                    Rebalance
                                </span>
                            </button>

                            <div className="col-span-4 border-bottom-t4"></div>
                        </div>

                        <div className="flex items-center justify-between -mt-2">
                            <span
                                className="text-[0.75em] uppercase opacity-75 cursor-pointer"
                                onClick={togglePoolTotalsVisibility}
                            >
                                <i className={`bi ${(isPoolTotalsVisibile ? "bi-caret-down-fill" : "bi-caret-right-fill")} mr-1`} />
                                <span className="inline-block hover:animate-wiggle">Pool Totals</span>
                            </span>
                            <span className="numeric text-[0.75em] opacity-25">
                                <i className="bi bi-clock mr-1" />
                                {format.formatTimespan(totalTimeFromUpdate)}
                            </span>
                        </div>
                    </>
                }

                {isPoolTotalsVisibile &&
                    <div className="grid grid-cols-3 gap-x-2 text-[80%]">
                        <PriceBox
                            title="Deposited"
                            main={depositToken.convert.formatCurrency(totalDeposited)}
                            mainUnit={depositTokenSymbol}
                            detail={depositTokenPriceInStableToken
                                ? tokens.stableToken.convert.formatCurrency(depositTokenPriceInStableToken * totalDeposited)
                                : "-"
                            }
                            detailUnit={tokens.stableToken.symbol}
                            isMainUnitOnTop={true}
                            mainUnitClassName="!opacity-25 mb-3"
                            titleClassName="light-box"
                        />
                        <PriceBox
                            title="Credited"
                            main={creditToken.convert.formatCurrency(totalCredited)}
                            mainUnit={creditToken.symbol}
                            detail={creditTokenPriceInStableToken
                                ? tokens.stableToken.convert.formatCurrency(creditTokenPriceInStableToken * totalCredited)
                                : "-"
                            }
                            detailUnit={tokens.stableToken.symbol}
                            isMainUnitOnTop={true}
                            mainUnitClassName="!opacity-25 mb-3"
                            titleClassName="light-box"
                        />
                        <PriceBox
                            title="Liquidated"
                            main={depositToken.convert.formatCurrency(totalLiquidated)}
                            mainUnit={depositTokenSymbol}
                            detail={depositTokenPriceInStableToken
                                ? tokens.stableToken.convert.formatCurrency(depositTokenPriceInStableToken * totalLiquidated)
                                : "-"
                            }
                            detailUnit={tokens.stableToken.symbol}
                            info={(
                                <Activity
                                    count={pool.totalPosition.liquidated.count.toInt()}
                                    timestamp={pool.totalPosition.liquidated.timestamp.toInt()}
                                    now={now}
                                />
                            )}
                            isMainUnitOnTop={true}
                            mainUnitClassName="!opacity-25 mb-3"
                            titleClassName="light-box"
                        />
                    </div>
                }
            </div>
        </div>
    );
}

export default WeebFarmPool;