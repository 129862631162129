import BN from "bn.js";

import { PromiEvent } from "web3-core/types";
import { AbiItem } from "web3-utils/types";
import { Contract, ContractOptions } from "web3-eth-contract/types";

import appSettings from "configuration";
import { Address } from "configuration/types";

import { AnonymousWalletInfo, PersonalWalletInfo } from "services/shared.types";
import { IziswapState } from "services/iziswap";
import { createToken, TokenState } from "services/erc20/token";
import { SettingsRepositoryState } from "services/settings-repository";
import { convert } from "services/convert";
import { WeebFarmViewModel, WeebFarmPoolViewModel, WeebFarmLiquidatablesViewModel, WeebFarmState } from "./weeb-farm.types";

import WeebFarm_ABI from "contracts/abi/WeebFarm.json";

const createWeebFarm = (wallet: AnonymousWalletInfo | PersonalWalletInfo, iziswap: IziswapState, settings: SettingsRepositoryState): WeebFarmState => {
    const chain = appSettings.chains[wallet.chainId];

    const options = { from: wallet.reader.defaultAccount } as ContractOptions;
    const abi = WeebFarm_ABI as AbiItem[];

    const readerContract = new wallet.reader.eth.Contract(abi, chain.contracts.weebFarm, options);

    const read = {
        readerContract,
        conversionRateOf: (depositRouter: Address, depositToken: Address, isLiquidityToken: boolean, deposit: BN, creditRouter: Address, creditToken: Address): Promise<WeebFarmViewModel[]> => readerContract.methods.conversionRateOf(depositRouter, depositToken, isLiquidityToken, deposit, creditRouter, creditToken).call(),
        senderFarmSnapshot: (isEnabled: boolean): Promise<WeebFarmViewModel> => readerContract.methods.senderFarmSnapshot(isEnabled ? 1 : 0).call(),
        senderFarmLiquidatablesSnapshot: (isEnabled: boolean): Promise<WeebFarmLiquidatablesViewModel> => readerContract.methods.senderFarmLiquidatablesSnapshot(isEnabled ? 1 : 0).call(),
    }

    let sign: {
        signerContract?: Contract,
        withdrawETH?: (poolId: number, amount: BN) => PromiEvent<Contract>,
        withdraw?: (poolId: number, amount: BN) => PromiEvent<Contract>,
        withdrawToken?: (poolId: number, amount: BN, token: TokenState) => PromiEvent<Contract>,
        rebalance?: (poolId: number) => PromiEvent<Contract>,
        liquidateSenderUnchecked?: (poolId: number) => PromiEvent<Contract>,
        liquidateAccount?: (poolId: number, investor: string) => PromiEvent<Contract>,
        depositETH?: (poolId: number, amountETHInWei: BN, depositToken: TokenState) => Promise<Contract>,
        deposit?: (poolId: number, amountInWei: BN) => Promise<Contract>,
        depositToken?: (poolId: number, token: TokenState, amountInWei: BN, depositToken: TokenState) => Promise<Contract>,
    };

    if ("signer" in wallet && wallet.signer) {
        const signerContract = new wallet.signer.eth.Contract(abi, chain.contracts.weebFarm, options);

        sign = {
            withdrawETH: (poolId: number, amount: BN): PromiEvent<Contract> => wallet.track(signerContract.methods.withdrawETH(poolId, amount).send(), { name: "withdrawETH" }),
            withdraw: (poolId: number, amount: BN): PromiEvent<Contract> => wallet.track(signerContract.methods.withdraw(poolId, amount).send(), { name: "withdraw" }),
            withdrawToken: (poolId: number, amount: BN, token: TokenState): PromiEvent<Contract> => wallet.track(signerContract.methods.withdraw(poolId, amount, token.router, token.token, token.isLiquidityToken).send(), { name: "withdrawToken" }),
            rebalance: (poolId: number): PromiEvent<Contract> => wallet.track(signerContract.methods.rebalance(poolId).send(), { name: "rebalance" }),
            liquidateAccount: (poolId: number, investor: string): PromiEvent<Contract> => wallet.track(signerContract.methods.liquidateAccount(poolId, investor).send(), { name: "liquidateAccount" }),
            depositETH: async (poolId: number, amountETHInWei: BN, depositToken: TokenState): Promise<Contract> => {
                const amountETH = convert.fromWei(amountETHInWei, chain.nativeCoin.decimals);
                const depositTokenAmountOut = await depositToken.quote.quoteFromNativeCoin(amountETH);
                const depositTokenAmountMin = (depositTokenAmountOut * settings.getSlippageTolerance()).toRate();
                const depositTokenAmountOutMinInWei = depositToken.convert.toWei(depositTokenAmountOut - depositTokenAmountMin);

                return await wallet.track(signerContract.methods.depositETH(poolId, depositTokenAmountOutMinInWei).send({ value: amountETHInWei }), { name: "depositETH" });
            },
            deposit: (poolId: number, amountInWei: BN): Promise<Contract> => wallet.track(signerContract.methods.deposit(poolId, amountInWei).send(), { name: "deposit" }),
            depositToken: async (poolId: number, token: TokenState, amountInWei: BN, depositToken: TokenState): Promise<Contract> => {
                const amount = token.convert.fromWei(amountInWei);
                const depositTokenAmountOut = await token.quote.quoteToTargetTokenDirect(amount, depositToken);
                const depositTokenAmountMin = (depositTokenAmountOut * settings.getSlippageTolerance()).toRate();
                const depositTokenAmountOutMin = depositToken.convert.toWei(depositTokenAmountOut - depositTokenAmountMin);

                return await wallet.track(signerContract.methods.deposit(poolId, token.router, token.token, token.isLiquidityToken, amountInWei, depositTokenAmountOutMin).send(), { name: "depositToken" });
            },
        }
    } else {
        sign = {};
    }

    const base = {
        getDepositTokenSymbol: (pool: WeebFarmPoolViewModel) => pool.isLiquidityToken ? `${pool.token0Details.symbol}/${pool.token1Details.symbol}` : pool.depositToken.details.symbol,
    };

    return {
        ...base,
        createCreditToken: (farm: WeebFarmViewModel | WeebFarmLiquidatablesViewModel) => {
            return {
                ...createToken({
                    wallet,
                    iziswap,
                    router: farm.creditToken.router,
                    token: farm.creditToken.token,
                    decimals: farm.creditToken.details.decimals.toNumber(),
                    isLiquidityToken: false,
                    symbol: farm.creditToken.details.symbol
                }),
            }
        },
        createDepositToken: (pool: WeebFarmPoolViewModel) => {
            return {
                ...createToken({
                    wallet,
                    iziswap,
                    router: pool.depositToken.router,
                    token: pool.depositToken.token,
                    decimals: pool.depositToken.details.decimals.toNumber(),
                    isLiquidityToken: pool.isLiquidityToken,
                    symbol: base.getDepositTokenSymbol(pool)
                }),
            }
        },
        ...read,
        ...sign,
    }
}

export default createWeebFarm;