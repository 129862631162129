import BN from "bn.js";

import { Fragment, useEffect, useState } from "react";

import { Listbox, Transition } from "@headlessui/react";

import { ChainInfo } from "configuration/types";

import { createToken, TokenState } from "services/erc20";
import { IziswapState } from "services/iziswap";
import { MoralisState } from "services/moralis";
import { PersonalWalletInfo } from "services/shared.types";
import { TokenFinderState, TokenResult } from "services/token-finder";
import { NativeCoinState, TokensState } from "services/weeb-finance/tokens";

import { MainText } from "./info-box";
import RangeInput from "./range-input";
import { convert } from "services/convert";

type TokenSelectorProps = {
    wallet: PersonalWalletInfo;
    iziswap: IziswapState;
    moralis: MoralisState;
    tokenFinder: TokenFinderState;
    tokens: TokensState;
    chain: ChainInfo,
    depositToken: TokenState;
    setSelectedToken: React.Dispatch<React.SetStateAction<NativeCoinState | TokenState | undefined>>;
    setAmount: React.Dispatch<React.SetStateAction<number>>;
    setAmountInDepositToken?: React.Dispatch<React.SetStateAction<number>>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TokenSelector = ({
    wallet,
    iziswap,
    moralis,
    tokenFinder,
    tokens,
    chain,
    depositToken,
    setSelectedToken,
    setAmount,
    setAmountInDepositToken,
    setIsLoading
}: TokenSelectorProps) => {
    const [userTokens, setUserTokens] = useState<TokenResult[]>();
    const [selectedTokenResult, setSelectedTokenResult] = useState<TokenResult>();
    const [selectedToken, setSelectedTokenInternal] = useState<NativeCoinState | TokenState>();

    const [selectedTokenBalance, setSelectedTokenBalance] = useState<number>(0);
    const [selectedTokenPriceInDepositToken, setSelectedTokenPriceInDepositToken] = useState(0);
    const [selectedTokenPriceInStableToken, setSelectedTokenPriceInStableToken] = useState(0);
    const [amount, setAmountInternal] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [isLoading, setIsLoadingInternal] = useState(false);
    const [isValidAddress, setIsValidAddress] = useState(false);

    useEffect(() => {
        moralis.getTokenList()
            .then(async values => {
                console.log(`${values.length} wallet tokens discovered.`);

                let addresses = values.map(v => v.token_address);

                if (!addresses.includes(depositToken.token)) {
                    addresses = [...addresses, depositToken.token];
                }

                if (addresses.length > 0) {
                    let result = await tokenFinder.findAll(addresses);

                    const eth: TokenResult = {
                        sourceToken: { token: "", details: { name: "", symbol: tokens.nativeCoin.symbol, decimals: `${tokens.nativeCoin.decimals}` } },
                        senderBalance: wallet.balance,
                        isLiquidityToken: false,
                        token0: { token: "", details: { name: "", symbol: "", decimals: "" } },
                        token1: { token: "", details: { name: "", symbol: "", decimals: "" } },
                        routers: []
                    };

                    result = [...result].sort((a, b) => tokenFinder.getSymbol(a).localeCompare(tokenFinder.getSymbol(b)));

                    let dtr: TokenResult | undefined;
                    const vtr: TokenResult[] = [];
                    const itr: TokenResult[] = [];

                    for (let i = 0; i < result.length; ++i) {
                        const token = result[i];

                        if (token.sourceToken.token === depositToken.token) {
                            dtr = token;
                        } else if (token.routers.length > 0) {
                            vtr.push(token);
                        } else if (token.routers.length === 0) {
                            itr.push(token);
                        }
                    }

                    if (isDisabled(eth)) {
                        result = [...vtr, eth];
                    } else {
                        result = [eth, ...vtr];
                        selectToken(eth);
                    }

                    if (dtr) {
                        if (isDisabled(dtr)) {
                            result = [...result, dtr];
                        } else {
                            result = [dtr, ...result];
                            selectToken(dtr);
                        }
                    }

                    result = [...result, ...itr];

                    setUserTokens(result);

                    console.log(`${result.length} wallet tokens detailed.`);
                }
            })
            .catch((error) => {
                setUserTokens([]);
                console.log(error)
            });
    }, [moralis]);

    const getSwapRouterName = (router: string) => {
        return chain.contracts.swapRouters[router].name ?? chain.name;
    }

    const getTokenSwapRouterName = (token: TokenResult) => {
        const index = getMaxReservesRouterIndex(token);
        return index !== undefined ? getSwapRouterName(token.routers[index].router) : "-";
    }

    const isDisabled = (token: TokenResult) => {
        if (token.sourceToken.details.symbol === tokens.nativeCoin.symbol) {
            return tokens.nativeCoin.convert.fromWei(token.senderBalance) === 0;
        }

        return token.routers.length === 0;
    }

    const selectToken = async (token: TokenResult) => {
        setSelectedTokenResult(token);

        if (token.sourceToken.details.symbol === tokens.nativeCoin.symbol) {
            setSelectedTokenInternal(tokens.nativeCoin);
            setSelectedToken(tokens.nativeCoin);
            setSelectedTokenBalance(tokens.nativeCoin.convert.fromWei(token.senderBalance));

            const selectedTokenPriceInDepositToken = await depositToken.price.priceOfNativeCoin();
            setSelectedTokenPriceInDepositToken(selectedTokenPriceInDepositToken);

            const selectedTokenPriceInStableToken = await tokens.stableToken.price.priceOfNativeCoin();
            setSelectedTokenPriceInStableToken(selectedTokenPriceInStableToken);
        } else {
            const index = getMaxReservesRouterIndex(token);

            if (index !== undefined) {
                const sourceToken = createToken({
                    wallet,
                    iziswap,
                    router: token.routers[index].router,
                    token: token.sourceToken.token,
                    decimals: token.sourceToken.details.decimals.toInt(),
                    isLiquidityToken: token.isLiquidityToken,
                    symbol: tokenFinder.getSymbol(token)
                });

                setSelectedTokenInternal(sourceToken);
                setSelectedToken(sourceToken);
                setSelectedTokenBalance(sourceToken.convert.fromWei(token.senderBalance));

                const selectedTokenPriceInDepositToken = await sourceToken.price.priceInTargetToken(depositToken.router, depositToken.token, depositToken.decimals, depositToken.isLiquidityToken);
                setSelectedTokenPriceInDepositToken(selectedTokenPriceInDepositToken);

                const selectedTokenPriceInStableToken = await sourceToken.price.priceInStableToken();
                setSelectedTokenPriceInStableToken(selectedTokenPriceInStableToken);
            }
        }
    }

    return (
        <div className="grid grid-cols-1 gap-y-3">
            <div className="flex flex-col justify-between">
                {userTokens
                    ?
                    <Listbox
                        value={selectedTokenResult}
                        onChange={async token => {
                            if (token) {
                                setIsLoadingInternal(true);
                                setIsLoading(true);

                                try {
                                    selectToken(token);
                                } finally {
                                    setIsLoadingInternal(false);
                                    setIsLoading(false);
                                }
                            } else {
                                setSelectedTokenResult(undefined);
                                setSelectedTokenInternal(undefined);
                                setSelectedToken(undefined);
                                setSelectedTokenBalance(0);
                            }
                        }}>
                        <div className="relative mt-1">
                            <Listbox.Label className="input-label">
                                Select token to use:
                            </Listbox.Label>
                            <Listbox.Button className="input relative w-full cursor-default text-left text-indigo-900 border-indigo-300/50 focus:ring-indigo-300/50">
                                <>
                                    {selectedTokenResult &&
                                        <span className="truncate">
                                            {tokenFinder.getSymbol(selectedTokenResult)}
                                        </span>
                                    }
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <i className="bi bi-chevron-expand" />
                                    </span>
                                </>
                            </Listbox.Button>
                            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-lg bg-violet-50">
                                {userTokens.map(token => (
                                    <Listbox.Option
                                        key={token.sourceToken.token}
                                        value={token}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-violet-300/30 text-violet-900" : `${token.sourceToken.token === depositToken.token || token.sourceToken.details.symbol === tokens.nativeCoin.symbol ? "text-red-500 font-medium" : "text-indigo-900"}`} ${isDisabled(token) ? "text-indigo-900/50" : ""}`
                                        }
                                        disabled={isDisabled(token)}
                                    >
                                        {({ active, selected }) => (
                                            <>
                                                <div className={`grid grid-cols-3 items-center justify-between ${selected ? "font-medium" : "font-normal"}`}>
                                                    <span className="truncate">
                                                        {tokenFinder.getSymbol(token)}
                                                    </span>
                                                    <span className="justify-self-end numeric">
                                                        {convert.formatCurrency(token.senderBalance.fromWei(token.sourceToken.details.decimals.toInt()), token.sourceToken.details.decimals.toInt())}
                                                    </span>
                                                    <span className="justify-self-end text-[0.875em]">
                                                        {getTokenSwapRouterName(token)}
                                                    </span>
                                                </div>
                                                {selected
                                                    ?
                                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                        <i className="bi bi-check-lg h-5 w-5" />
                                                    </span>
                                                    : null
                                                }
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </div>
                    </Listbox>
                    :
                    <p className="animate-pulse font-medium text-center">
                        Loading wallet tokens...
                    </p>
                }
            </div>

            <div className="grid grid-cols-1 gap-y-0.5">
                <div className="flex items-center justify-between leading-6">
                    <span className="input-label">
                        Amount:
                        <span className="ml-1">
                            <MainText
                                main={percentage.toPercent().formatPercentage()}
                                mainUnit="%"
                                mainClassName="text-[0.875em]"
                                mainUnitClassName="ml-0.5"
                            />
                        </span>
                    </span>
                    <span className="input-label">
                        Balance:
                        <MainText
                            main={selectedToken
                                ? selectedToken.symbol === tokens.nativeCoin.symbol
                                    ? tokens.nativeCoin.convert.formatCurrency(tokens.nativeCoin.convert.fromWei(wallet.balance))
                                    : selectedToken.convert.formatCurrency(selectedTokenBalance)
                                : "-"}
                            mainUnit={selectedToken
                                ? selectedToken.symbol === tokens.nativeCoin.symbol
                                    ? tokens.nativeCoin.symbol
                                    : selectedToken.symbol
                                : ""}
                            mainClassName="text-[0.875em] ml-1"
                            mainUnitClassName="ml-0.5"
                        />
                    </span>
                </div>
                <div className={`${isLoading ? "animate-pulse" : ""}`}>
                    <RangeInput
                        isDisabled={isLoading}
                        min={0}
                        max={
                            selectedToken
                                ? selectedToken.symbol === tokens.nativeCoin.symbol
                                    ? wallet.balance.fromWei(18)
                                    : selectedTokenBalance
                                : 0
                        }
                        decimals={
                            selectedToken
                                ? selectedToken.symbol === tokens.nativeCoin.symbol
                                    ? Math.min(4, tokens.nativeCoin.decimals)
                                    : Math.min(4, selectedToken.decimals)
                                : 0
                        }
                        setValue={a => {
                            setAmountInternal(a);
                            setAmount(a);
                            const n = a.valueOf() as number;
                            setAmountInDepositToken?.(n * selectedTokenPriceInDepositToken);
                        }}
                        setPercentage={setPercentage}
                        valueClassName="text-indigo-900 border-indigo-300/50 focus:ring-indigo-300/50"
                        rangeClassName="accent-indigo-900"
                    />
                    <div className="grid grid-cols-2 gap-x-2 mt-3">
                        <div className="justify-self-end">
                            <MainText
                                main={
                                    selectedToken
                                        ? selectedToken.convert.formatCurrency(amount * selectedTokenPriceInDepositToken)
                                        : "-"
                                }
                                mainUnit={depositToken.symbol}
                                mainClassName="text-[0.875em]"
                            />
                        </div>
                        <div className="justify-self-start">
                            <MainText
                                main={
                                    selectedToken
                                        ? tokens.stableToken.convert.formatCurrency(amount * selectedTokenPriceInStableToken)
                                        : "-"
                                }
                                mainUnit={tokens.stableToken.symbol}
                                mainClassName="text-[0.875em]"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function getMaxReservesRouterIndex(result: TokenResult) {
        let maxValue: BN | undefined;
        let maxIndex: number | undefined;

        for (let i = 0; i < result.routers.length; ++i) {
            const k = result.routers[i].liquidity.reserve0.toBN().mul(result.routers[i].liquidity.reserve1.toBN());

            if (maxValue === undefined || k.gt(maxValue)) {
                maxIndex = i;
                maxValue = k;
            }
        }

        return maxIndex;
    }

    // function getTokenList() {
    //     var zero = "0".toBN();

    //     const items: JSX.Element[] = [];

    //     //items.push((<option key={""} value="">- Not in list -</option>));

    //     if (wallet.balance.toBN().gt(zero)) {
    //         items.push((<option key={tokens.nativeCoin.symbol} value={tokens.nativeCoin.symbol}>{tokens.nativeCoin.symbol}</option>));
    //     }

    //     if (!userTokens.map((t: any) => t.token_address).includes(depositToken.token)) {
    //         items.push((<option key={depositToken.token} value={depositToken.token}>{depositToken.symbol}</option>));
    //     }

    //     userTokens.forEach((t: any) => {
    //         items.push((<option key={t.token_address} value={t.token_address}>{t.symbol}</option>));
    //     });

    //     return items;
    // }
}

export default TokenSelector;