export class UriExtensions {
    static getRoot(uri: string): string | undefined {
        const index = uri.indexOf("//");

        if (index === -1) {
            return undefined;
        }

        let result = uri.substring(0, index + 2);
        const right = uri.substring(index + 2);
        const parts = right.split(".");

        if (parts.length < 2) {
            return uri;
        }

        let delimiter = "";

        for (let i = parts.length - 2; i < parts.length; i++) {
            result += delimiter;
            result += parts[i];
            delimiter = ".";
        }

        return result;
    }
}