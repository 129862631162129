import { Navigate } from "react-router-dom";

import appSettings, { getRpcUris } from "configuration";

import WalletContext from "contexts/wallet-context";
import { useWallet } from "hooks/useWallet";

import { Footer, Navbar, Routes } from "components/navigation";

const DefaultLayout = () => {
    const wallet = useWallet({
        autoConnect: true,
        rpcUris: getRpcUris(appSettings.chains)
    });

    // Find the requested path and navigate.
    let search = window.location.search.toLocaleLowerCase();

    if (search.startsWith("?")) {
        search = search.substring(1);
    }
    
    switch (search) {
        case "/games":
        case "/farms":
        case "/hunt":
        case "/test":
            return (<Navigate to={search} replace={true} />);
    }

    return (
        <div className="2xl:container mx-auto p-2 h-screen">
            <div className="bubble-set">
                <div className="bubble-lg"></div>
                <div className="bubble-md"></div>
                <div className="bubble-sm"></div>
            </div>
            <WalletContext.Provider value={wallet}>
                <div className="flex flex-col justify-between h-full">
                    <div className="">
                        <Navbar />
                        <div className="mt-6"></div>
                        <Routes />
                    </div>
                    <Footer />
                </div>
            </WalletContext.Provider>
        </div>
    );
}

export default DefaultLayout;