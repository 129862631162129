import { MultiBox, PriceBox } from "components/shared";
import { StyledPopover } from "components/shared/dialogs";
import { ReserveInfo } from "configuration/types";

import { ReserveState, TokenState } from "services/erc20";
import { format } from "services/format";
import { WeebTokenCombinedState } from "services/weeb-finance/tokens";
import { WeebTokenViewModel } from "services/weeb-finance/weeb-token";

import {
    DAYS_IN_WEEK,
    DAYS_IN_MONTH,
    DAYS_IN_YEAR,
    getDurationPercentageYield,
    getRewardRatePerDay,
    getRewardPerDay,
    getRewardPerDuration
} from "extensions/interest-extensions";
import { getError } from "errors";

type ActivityProps = {
    count: number,
    timestamp: number,
    now: number
}

export const Activity = ({
    count,
    timestamp,
    now
}: ActivityProps) => {
    return (
        <div className="flex justify-evenly space-x-1">
            <span className="light-box w-full">
                <i className="bi bi-app-indicator mr-1" />
                {count.format(0)}
            </span>
            <span className="light-box w-full">
                <i className="bi bi-clock mr-1" />
                {timestamp > 0 ? format.formatTimespan(now - timestamp) : "-"}
            </span>
        </div>
    );
}

type PopoverSenderBalanceContentProps = {
    weebToken: WeebTokenCombinedState,
    stableToken: TokenState,
    token: WeebTokenViewModel,
    priceInStableToken: number
}
export const PopoverSenderBalanceContent = ({
    weebToken,
    stableToken,
    token,
    priceInStableToken
}: PopoverSenderBalanceContentProps) => {
    const senderBalance = weebToken.convert.fromWei(token.senderBalance);
    const senderLockedBalance = weebToken.convert.fromWei(token.senderLockedBalance);
    const senderFreeBalance = weebToken.convert.fromWei(token.senderFreeBalance);

    const totalSupply = weebToken.convert.fromWei(token.totalSupply);
    const totalStaked = weebToken.convert.fromWei(token.totalStaked);

    const senderBalanceShare = totalSupply === 0 ? 0 : senderBalance / totalSupply;
    const senderStakeShare = totalStaked === 0 ? 0 : senderBalance / totalStaked;

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-6 text-[80%]">
            <PriceBox
                title="Locked Balance"
                main={weebToken.convert.formatCurrency(senderLockedBalance)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderLockedBalance * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Free Balance"
                main={weebToken.convert.formatCurrency(senderFreeBalance)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderFreeBalance * priceInStableToken)}
                detailUnit={stableToken.symbol}
                mainClassName="glow"
            />
            <PriceBox
                title="Balance Share"
                main={senderBalanceShare.toPercent().formatPercentage()}
                mainUnit="%"
                mainClassName="neon"
            />
            <PriceBox
                title="Stake Share"
                main={senderStakeShare.toPercent().formatPercentage()}
                mainUnit="%"
                mainClassName="neon"
            />
        </div>
    )
}

type PopoverSenderTransferBurnRateContentProps = {
    token: WeebTokenViewModel
}

export const PopoverSenderTransferBurnRateContent = ({
    token
}: PopoverSenderTransferBurnRateContentProps) => {
    const rebatableBurnRate = token.rebatableBurnRate.toNumber().toRate();
    const transferBurnRate = token.transferBurnRate.toNumber().toRate();

    const rebateMaximizingShare = token.rebateMaximizingShare.toNumber().toRate();
    const rebateShareStepsize = token.rebateShareStepsize.toNumber().toRate();

    const senderRebateRate = token.senderRebateRate.toNumber().toRate();

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-6 text-[80%]">
            <PriceBox
                title="Rebatable Burn Rate (RBR)"
                main={rebatableBurnRate.toPercent().formatPercentage()}
                mainUnit="%"
                detail={(transferBurnRate * rebatableBurnRate).toPercent().formatPercentage()}
                detailUnit="% from Current Rate"
            />
            <PriceBox
                title="Rebate Maximizing Share"
                main={rebateMaximizingShare.toPercent().formatPercentage()}
                mainUnit="%"
            />
            <PriceBox
                title="Rebate Share Stepsize"
                main={rebateShareStepsize.toPercent().formatPercentage()}
                mainUnit="%"
            />
            <PriceBox
                title="Your Rebate Rate of RBR"
                main={senderRebateRate.toPercent().formatPercentage()}
                mainUnit="%"
                detail={(transferBurnRate * rebatableBurnRate * senderRebateRate).toPercent().formatPercentage()}
                detailUnit="% of Current Rate"
            />
        </div>
    )
}

type PopoverSenderSalesBurnRateContentProps = {
    token: WeebTokenViewModel
}

export const PopoverSenderSalesBurnRateContent = ({
    token
}: PopoverSenderSalesBurnRateContentProps) => {
    const rebatableBurnRate = token.rebatableBurnRate.toNumber().toRate();
    const currentSalesBurnRate = token.currentSalesBurnRate.toNumber().toRate();

    const rebateMaximizingShare = token.rebateMaximizingShare.toNumber().toRate();
    const rebateShareStepsize = token.rebateShareStepsize.toNumber().toRate();

    const senderRebateRate = token.senderRebateRate.toNumber().toRate();

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-6 text-[80%]">
            <PriceBox
                title="Rebatable Burn Rate (RBR)"
                main={rebatableBurnRate.toPercent().formatPercentage()}
                mainUnit="%"
                detail={(currentSalesBurnRate * rebatableBurnRate).toPercent().formatPercentage()}
                detailUnit="% from Current Rate"
            />
            <PriceBox
                title="Rebate Maximizing Share"
                main={rebateMaximizingShare.toPercent().formatPercentage()}
                mainUnit="%"
            />
            <PriceBox
                title="Rebate Share Stepsize"
                main={rebateShareStepsize.toPercent().formatPercentage()}
                mainUnit="%"
            />
            <PriceBox
                title="Your Rebate Rate of RBR"
                main={senderRebateRate.toPercent().formatPercentage()}
                mainUnit="%"
                detail={(currentSalesBurnRate * rebatableBurnRate * senderRebateRate).toPercent().formatPercentage()}
                detailUnit="% of Current Rate"
            />
        </div>
    );
}

type PopoverMaxRewardPerBlockContentProps = {
    weebToken: WeebTokenCombinedState,
    stableToken: TokenState,
    token: WeebTokenViewModel,
    priceInStableToken: number,
    blockTime: number
}

export const PopoverMaxRewardPerBlockContent = ({
    weebToken,
    stableToken,
    token,
    priceInStableToken,
    blockTime
}: PopoverMaxRewardPerBlockContentProps) => {
    const maximumRewardPerBlock = weebToken.convert.fromWei(token.maximumRewardPerBlock);

    const senderBalance = weebToken.convert.fromWei(token.senderBalance);
    const totalStaked = weebToken.convert.fromWei(token.totalStaked);

    const maxRewardPerD = getRewardPerDuration(maximumRewardPerBlock, blockTime, 1);
    const maxRewardPerW = maxRewardPerD * DAYS_IN_WEEK;
    const maxRewardPerM = maxRewardPerD * DAYS_IN_MONTH;
    const maxRewardPerY = maxRewardPerD * DAYS_IN_YEAR;

    const senderMaxRewardPerD = getRewardPerDay(maximumRewardPerBlock, blockTime, totalStaked, senderBalance);
    const senderMaxRewardPerW = senderMaxRewardPerD * DAYS_IN_WEEK;
    const senderMaxRewardPerM = senderMaxRewardPerD * DAYS_IN_MONTH;
    const senderMaxRewardPerY = senderMaxRewardPerD * DAYS_IN_YEAR;

    return (
        <div className="grid grid-cols-12 gap-x-2 gap-y-6 text-[80%]">
            <PriceBox
                containerClassName="col-span-6"
                title="Maximum Reward Per Day"
                main={weebToken.convert.formatCurrency(maxRewardPerD)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(maxRewardPerD * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                containerClassName="col-span-6"
                title="Maximum Reward Per Week"
                main={weebToken.convert.formatCurrency(maxRewardPerW)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(maxRewardPerW * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                containerClassName="col-span-6"
                title="Maximum Reward Per Month"
                main={weebToken.convert.formatCurrency(maxRewardPerM)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(maxRewardPerM * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                containerClassName="col-span-6"
                title="Maximum Reward Per Year"
                main={weebToken.convert.formatCurrency(maxRewardPerY)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(maxRewardPerY * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />

            <div className={`col-span-12 border-bottom-t1`}></div>

            <PriceBox
                containerClassName="col-span-6"
                title="Your Maximum Reward Per Day"
                main={weebToken.convert.formatCurrency(senderMaxRewardPerD)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderMaxRewardPerD * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                containerClassName="col-span-6"
                title="Your Maximum Reward Per Week"
                main={weebToken.convert.formatCurrency(senderMaxRewardPerW)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderMaxRewardPerW * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                containerClassName="col-span-6"
                title="Your Maximum Reward Per Month"
                main={weebToken.convert.formatCurrency(senderMaxRewardPerM)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderMaxRewardPerM * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                containerClassName="col-span-6"
                title="Your Maximum Reward Per Year"
                main={weebToken.convert.formatCurrency(senderMaxRewardPerY)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderMaxRewardPerY * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
        </div>
    );
}

type PopoverCurrentRewardPerBlockContentProps = {
    weebToken: WeebTokenCombinedState,
    stableToken: TokenState,
    token: WeebTokenViewModel,
    priceInStableToken: number,
    blockTime: number
}

export const PopoverCurrentRewardPerBlockContent = ({
    weebToken,
    stableToken,
    token,
    priceInStableToken,
    blockTime
}: PopoverCurrentRewardPerBlockContentProps) => {
    const tooltipRewardMaximizingHoldersText = (
        <span className="tooltip text-[80%]">
            The number of holders required for maximum reward per block emission to avoid liquidity concentration.
        </span>
    );

    const reducedEmissionHolderLimit = token.reducedEmissionHolderLimit.toInt();
    const isRegularEmission = (reducedEmissionHolderLimit === 0 || token.holders.toInt() > reducedEmissionHolderLimit);

    const senderBalance = weebToken.convert.fromWei(token.senderBalance);
    const totalStaked = weebToken.convert.fromWei(token.totalStaked);

    const currentRewardPerBlock = weebToken.convert.fromWei(token.currentRewardPerBlock);

    const currentRewardPerD = getRewardPerDuration(currentRewardPerBlock, blockTime, 1);
    const currentRewardPerW = currentRewardPerD * DAYS_IN_WEEK;
    const currentRewardPerM = currentRewardPerD * DAYS_IN_MONTH;
    const currentRewardPerY = currentRewardPerD * DAYS_IN_YEAR;

    const senderCurrentRewardPerD = getRewardPerDay(currentRewardPerBlock, blockTime, totalStaked, senderBalance);
    const senderCurrentRewardPerW = senderCurrentRewardPerD * DAYS_IN_WEEK;
    const senderCurrentRewardPerM = senderCurrentRewardPerD * DAYS_IN_MONTH;
    const senderCurrentRewardPerY = senderCurrentRewardPerD * DAYS_IN_YEAR;

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-6 text-[80%]">
            <PriceBox
                title="Current Holders"
                main={token.holders.toInt().format(0)}
            />
            <PriceBox
                title={(
                    <>
                        <span className="mr-1">Reward Maximizing Holders</span>
                        <StyledPopover isTooltip={true} children={tooltipRewardMaximizingHoldersText} />
                    </>
                )}
                main={token.reducedEmissionHolderLimit.toInt().format(0)}
                detail={reducedEmissionHolderLimit === 0
                    ? ""
                    : (<span className="font-display">{isRegularEmission ? "Regular Reward Emission" : "Reduced Reward Emission"}</span>)
                }
                detailClassName="uppercase text-red-500"
            />

            <span className="group-title light-box opacity-75 col-span-2">Pool's Current Reward</span>
            <PriceBox
                title="Per Day"
                main={weebToken.convert.formatCurrency(currentRewardPerD)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(currentRewardPerD * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Per Week"
                main={weebToken.convert.formatCurrency(currentRewardPerW)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(currentRewardPerW * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Per Month"
                main={weebToken.convert.formatCurrency(currentRewardPerM)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(currentRewardPerM * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Per Year"
                main={weebToken.convert.formatCurrency(currentRewardPerY)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(currentRewardPerY * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />

            <span className="group-title light-box opacity-75 col-span-2">Your Current Reward</span>
            <PriceBox
                title="Per Day"
                main={weebToken.convert.formatCurrency(senderCurrentRewardPerD)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderCurrentRewardPerD * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Per Week"
                main={weebToken.convert.formatCurrency(senderCurrentRewardPerW)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderCurrentRewardPerW * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Per Month"
                main={weebToken.convert.formatCurrency(senderCurrentRewardPerM)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderCurrentRewardPerM * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Per Year"
                main={weebToken.convert.formatCurrency(senderCurrentRewardPerY)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderCurrentRewardPerY * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
        </div>
    );
}

type CurrentRewardConditionalProps = {
    weebToken: WeebTokenCombinedState,
    stableToken: TokenState,
    token: WeebTokenViewModel,
    priceInStableToken: number,
    blockTime: number
}

const CurrentRewardConditional = ({
    weebToken,
    stableToken,
    token,
    priceInStableToken,
    blockTime
}: CurrentRewardConditionalProps) => {
    const senderBalance = weebToken.convert.fromWei(token.senderBalance);
    const totalStaked = weebToken.convert.fromWei(token.totalStaked);

    const currentRewardPerBlock = weebToken.convert.fromWei(token.currentRewardPerBlock);

    const senderCurrentRewardPerD = getRewardPerDay(currentRewardPerBlock, blockTime, totalStaked, senderBalance);
    const senderCurrentRewardPerW = senderCurrentRewardPerD * DAYS_IN_WEEK;
    const senderCurrentRewardPerM = senderCurrentRewardPerD * DAYS_IN_MONTH;
    const senderCurrentRewardPerY = senderCurrentRewardPerD * DAYS_IN_YEAR;

    if (senderCurrentRewardPerD * priceInStableToken >= 0.0001) {
        return (
            <PriceBox
                title="Reward Per Day"
                main={weebToken.convert.formatCurrency(senderCurrentRewardPerD)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderCurrentRewardPerD * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
        );
    }

    if (senderCurrentRewardPerW * priceInStableToken >= 0.0001) {
        return (
            <PriceBox
                title="Reward Per Week"
                main={weebToken.convert.formatCurrency(senderCurrentRewardPerW)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderCurrentRewardPerW * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
        );
    }

    if (senderCurrentRewardPerM * priceInStableToken >= 0.0001) {
        return (
            <PriceBox
                title="Reward Per Month"
                main={weebToken.convert.formatCurrency(senderCurrentRewardPerM)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderCurrentRewardPerM * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
        );
    }

    return (
        <PriceBox
            title="Reward Per Year"
            main={weebToken.convert.formatCurrency(senderCurrentRewardPerY)}
            mainUnit={token.details.symbol}
            detail={stableToken.convert.formatCurrency(senderCurrentRewardPerY * priceInStableToken)}
            detailUnit={stableToken.symbol}
        />
    );
}

export const getBurnRates = (token: WeebTokenViewModel) => {
    const transferBurnRate = token.transferBurnRate.toNumber().toRate();
    const rebatableBurnRate = token.rebatableBurnRate.toNumber().toRate();
    const senderRebateRate = token.senderRebateRate.toNumber().toRate();
    const currentSalesBurnRate = token.currentSalesBurnRate.toNumber().toRate();
    const senderTransferBurnRate = transferBurnRate - (transferBurnRate * rebatableBurnRate * senderRebateRate);
    const senderSalesBurnRate = currentSalesBurnRate - (currentSalesBurnRate * rebatableBurnRate * senderRebateRate);

    return [senderTransferBurnRate, senderSalesBurnRate];
}

type SummaryViewProps = {
    weebToken: WeebTokenCombinedState,
    stableToken: TokenState,
    token: WeebTokenViewModel,
    priceInStableToken: number,
    blockTime: number
}

export const SummaryView = ({
    weebToken,
    stableToken,
    token,
    priceInStableToken,
    blockTime
}: SummaryViewProps) => {
    const [senderTransferBurnRate, senderSalesBurnRate] = getBurnRates(token);
    const currentRewardPerBlock = weebToken.convert.fromWei(token.currentRewardPerBlock);

    return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-6 text-[80%]">
            <PriceBox
                title="Transfer Burn Rate"
                main={token.isSenderNonburnable
                    ? (<s>{senderTransferBurnRate.toPercent().formatPercentage()}</s>)
                    : senderTransferBurnRate.toPercent().formatPercentage()
                }
                mainUnit="%"
                info={token.isSenderNonburnable && (<span className="font-display">You're Burn Free</span>)}
                infoClassName="opacity-75 font-semibold uppercase text-red-500"
                mainClassName="glow"
            />
            <PriceBox
                title="Sales Burn Rate"
                main={token.isSenderNonburnable
                    ? (<s>{senderSalesBurnRate.toPercent().formatPercentage()}</s>)
                    : senderSalesBurnRate.toPercent().formatPercentage()
                }
                mainUnit="%"
                info={token.isSenderNonburnable && (<span className="font-display">You're Burn Free</span>)}
                infoClassName="opacity-75 font-semibold uppercase text-red-500"
                mainClassName="glow"
            />
            <PriceBox
                title="Reward Per Block"
                main={weebToken.convert.formatCurrency(currentRewardPerBlock)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(currentRewardPerBlock * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <CurrentRewardConditional
                weebToken={weebToken}
                stableToken={stableToken}
                token={token}
                priceInStableToken={priceInStableToken}
                blockTime={blockTime}
            />
        </div>
    );
}

type SupplyViewProps = {
    weebToken: WeebTokenCombinedState,
    stableToken: TokenState,
    token: WeebTokenViewModel,
    priceInStableToken: number
}

export const SupplyView = ({
    weebToken,
    stableToken,
    token,
    priceInStableToken,
}: SupplyViewProps) => {
    const tooltipTotalLockedSupplyText = (
        <span className="tooltip text-[80%]">
            Temporary addition to circulating supply. Passive supply which can generate yield but can not be sold or transferred.
        </span>
    );

    const totalStaked = weebToken.convert.fromWei(token.totalStaked);
    const totalSupply = weebToken.convert.fromWei(token.totalSupply);
    const initialSupply = weebToken.convert.fromWei(token.initialSupply);
    const maximumSupply = weebToken.convert.fromWei(token.maximumSupply);
    const totalLockedSupply = weebToken.convert.fromWei(token.totalLockedSupply);
    const supplyInContracts = weebToken.convert.fromWei(token.supplyInContracts);
    const supplyInNonrewardables = weebToken.convert.fromWei(token.supplyInNonrewardables);

    return (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-6 text-[80%]">
            <span className="group-title light-box opacity-75 col-span-2 md:col-span-4">
                Supply Details
            </span>
            <PriceBox
                title="Circulating Supply"
                main={weebToken.convert.formatCurrency(totalSupply)}
                mainUnit={token.details.symbol}
            />
            <PriceBox
                title="Market Cap."
                main={stableToken.convert.formatCurrency(totalSupply * priceInStableToken)}
                mainUnit={stableToken.symbol}
            />
            <PriceBox
                title="Initial Supply"
                main={weebToken.convert.formatCurrency(initialSupply)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(initialSupply * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            {(maximumSupply > 0)
                ? <PriceBox
                    title="Maximum Supply"
                    main={weebToken.convert.formatCurrency(maximumSupply)}
                    mainUnit={token.details.symbol}
                    detail={stableToken.convert.formatCurrency(maximumSupply * priceInStableToken)}
                    detailUnit={stableToken.symbol}
                />
                : <PriceBox
                    title="Maximum Supply"
                    main="DTPS"
                    mainClassName="font-display"
                />
            }

            <div className={`col-span-2 md:col-span-4 border-bottom-t1`}></div>

            <PriceBox
                title={(
                    <>
                        <span className="mr-1">
                            Total Locked Supply
                        </span>
                        <StyledPopover isTooltip={true} children={(<span>{tooltipTotalLockedSupplyText}</span>)} />
                    </>
                )}
                main={weebToken.convert.formatCurrency(totalLockedSupply)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(totalLockedSupply * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Total Staked"
                main={weebToken.convert.formatCurrency(totalStaked)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(totalStaked * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Supply in Contracts"
                main={weebToken.convert.formatCurrency(supplyInContracts)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(supplyInContracts * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Supply in Nonrewardables"
                main={weebToken.convert.formatCurrency(supplyInNonrewardables)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(supplyInNonrewardables * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
        </div>
    );
}

type EmissionViewProps = {
    weebToken: WeebTokenCombinedState,
    stableToken: TokenState,
    nativeToken: TokenState,
    token: WeebTokenViewModel,
    priceInStableToken: number,
    nativeTokenPriceInStableToken?: number,
    blockTime: number,
    now: number
}

export const EmissionView = ({
    weebToken,
    stableToken,
    nativeToken,
    token,
    priceInStableToken,
    nativeTokenPriceInStableToken,
    blockTime,
    now
}: EmissionViewProps) => {
    const tooltipSenderTotalRebatedText = (
        <span className="tooltip text-[80%]">
            Rebates earned from burns by holding higher balance share.
        </span>
    );

    const baseRewardPerBlock = weebToken.convert.fromWei(token.baseRewardPerBlock);
    const rewardMultiplier = weebToken.convert.fromWei(token.rewardMultiplier);

    const maximumRewardPerBlock = weebToken.convert.fromWei(token.maximumRewardPerBlock);
    const currentRewardPerBlock = weebToken.convert.fromWei(token.currentRewardPerBlock);

    const senderTotalHarvested = weebToken.convert.fromWei(token.senderTotalHarvested.total);

    const transferBurnRate = token.transferBurnRate.toNumber().toRate();
    const rebatableBurnRate = token.rebatableBurnRate.toNumber().toRate();
    const senderRebateRate = token.senderRebateRate.toNumber().toRate();
    const currentSalesBurnRate = token.currentSalesBurnRate.toNumber().toRate();
    const senderTransferBurnRate = transferBurnRate - (transferBurnRate * rebatableBurnRate * senderRebateRate);
    const senderSalesBurnRate = currentSalesBurnRate - (currentSalesBurnRate * rebatableBurnRate * senderRebateRate);

    const salesBaseBurnRate = token.salesBaseBurnRate.toNumber().toRate();
    const salesMaximumBurnRate = token.salesMaximumBurnRate.toNumber().toRate();
    const burnMultiplier = token.burnMultiplier.toNumber().toRate();
    const senderTotalRebated = weebToken.convert.fromWei(token.senderTotalRebated.total);
    const senderTotalBurnt = weebToken.convert.fromWei(token.senderTotalBurnt.total);

    const regulationTimestamp = token.regulationTimestamp.toNumber();
    const regulationInterval = token.regulationInterval.toNumber();

    const priceAverage = weebToken.convert.fromWei(token.priceAverage);

    return (
        <div className="grid grid-cols-6 gap-x-2 gap-y-6 text-[80%]">
            <span className="group-title light-box opacity-75 col-span-6">Token Emission</span>
            <MultiBox
                containerClassName="col-span-6 justify-items-center"
                title="Reward Per Block"
                titleClassName="group-title light-box w-full"
                subTitles={[
                    "Base",
                    "Max",
                    "Multiplier",
                    (
                        <>
                            <span className="mr-1">Current Value</span>
                            <StyledPopover
                                isTooltip={false}
                                children={
                                    <PopoverCurrentRewardPerBlockContent
                                        weebToken={weebToken}
                                        stableToken={stableToken}
                                        token={token}
                                        priceInStableToken={priceInStableToken}
                                        blockTime={blockTime}
                                    />
                                }
                            />
                        </>
                    ),
                ]}
                subValues={[
                    (
                        <PriceBox
                            containerClassName="-mt-2"
                            main={weebToken.convert.formatCurrency(baseRewardPerBlock)}
                            mainUnit={token.details.symbol}
                            detail={stableToken.convert.formatCurrency(baseRewardPerBlock * priceInStableToken)}
                            detailUnit={stableToken.symbol}
                        />
                    ),
                    (
                        <PriceBox
                            containerClassName="-mt-2"
                            main={maximumRewardPerBlock === 0 ? "-" : weebToken.convert.formatCurrency(maximumRewardPerBlock)}
                            mainUnit={token.details.symbol}
                            detail={stableToken.convert.formatCurrency(maximumRewardPerBlock * priceInStableToken)}
                            detailUnit={stableToken.symbol}
                        />
                    ),
                    `×${(1 + rewardMultiplier).formatScaled(2, 0)}`,
                    (
                        <PriceBox
                            containerClassName="-mt-2"
                            main={weebToken.convert.formatCurrency(currentRewardPerBlock)}
                            mainUnit={token.details.symbol}
                            detail={stableToken.convert.formatCurrency(currentRewardPerBlock * priceInStableToken)}
                            detailUnit={stableToken.symbol}
                            mainClassName="neon"
                        />
                    )
                ]}
            />

            <PriceBox
                containerClassName="col-span-3"
                title="Average Block Time"
                titleClassName="group-title light-box w-full"
                main={blockTime}
                mainUnit="s"
            />
            <PriceBox
                containerClassName="col-span-3"
                title="Your Total Harvested"
                titleClassName="group-title light-box w-full"
                main={weebToken.convert.formatCurrency(senderTotalHarvested)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderTotalHarvested * priceInStableToken)}
                detailUnit={stableToken.symbol}
                info={(
                    <Activity
                        count={token.senderTotalHarvested.count.toInt()}
                        timestamp={token.senderTotalHarvested.timestamp.toInt()}
                        now={now}
                    />
                )}
                mainClassName="neon"
            />

            <div className={`col-span-6 border-bottom-t1`}></div>

            <MultiBox
                containerClassName="col-span-6 sm:col-span-2 justify-items-center1"
                title="Transfer Burn Rate"
                titleClassName="group-title light-box w-full"
                subTitles={[
                    "Current Rate",
                    (
                        <>
                            <span className="mr-1">
                                Your Rate
                            </span>
                            <StyledPopover isTooltip={false} children={(<PopoverSenderTransferBurnRateContent token={token} />)} />
                        </>
                    ),
                ]}
                subValues={[
                    (
                        <PriceBox
                            main={transferBurnRate.toRate().toPercent().formatPercentage()}
                            mainUnit="%"
                            mainClassName="neon"
                            isHorizontal={true}
                        />
                    ),
                    (
                        <PriceBox
                            main={token.isSenderNonburnable
                                ? (<s>{(senderTransferBurnRate).toPercent().formatPercentage()}</s>)
                                : (senderTransferBurnRate).toPercent().formatPercentage()
                            }
                            mainUnit="%"
                            info={token.isSenderNonburnable && (<span className="font-display">You're Burn Free</span>)}
                            infoClassName="opacity-75 font-semibold uppercase text-red-500"
                            mainClassName="glow"
                            isHorizontal={true}
                        />
                    )
                ]}
            />

            <MultiBox
                containerClassName="col-span-6 sm:col-span-4 justify-items-center"
                title="Sales Burn Rate"
                titleClassName="group-title light-box w-full"
                subTitles={[
                    "Base",
                    "Max",
                    "Multiplier",
                    "Current Rate",
                    (
                        <>
                            <span className="mr-1">
                                Your Rate
                            </span>
                            <StyledPopover isTooltip={false} children={(<PopoverSenderSalesBurnRateContent token={token} />)} />
                        </>
                    )
                ]}
                subValues={[
                    (
                        <PriceBox
                            containerClassName="-mt-2"
                            main={salesBaseBurnRate.toPercent().formatPercentage()}
                            mainUnit="%"
                        />
                    ),
                    (
                        <PriceBox
                            containerClassName="-mt-2"
                            main={salesMaximumBurnRate === 0 ? "-" : salesMaximumBurnRate.toPercent().formatPercentage()}
                            mainUnit="%"
                        />
                    ),
                    `×${(1 + burnMultiplier).formatScaled(2, 0)}`,
                    (
                        <PriceBox
                            containerClassName="-mt-2"
                            main={currentSalesBurnRate.toPercent().formatPercentage()}
                            mainUnit="%"
                            mainClassName="neon"
                        />
                    ),
                    (
                        <PriceBox
                            containerClassName="-mt-2"
                            main={token.isSenderNonburnable
                                ? (<s>{senderSalesBurnRate.toPercent().formatPercentage()}</s>)
                                : (senderSalesBurnRate).toPercent().formatPercentage()
                            }
                            mainUnit="%"
                            info={token.isSenderNonburnable && (<span className="font-display">You're Burn Free</span>)}
                            infoClassName="opacity-75 font-semibold uppercase text-red-500"
                            mainClassName="glow"
                        />
                    )
                ]}
            />

            <PriceBox
                containerClassName="col-span-3"
                title={(
                    <>
                        <span className="mr-1">
                            Your Total Rebated
                        </span>
                        <StyledPopover isTooltip={true} children={(<span>{tooltipSenderTotalRebatedText}</span>)} />
                    </>
                )}
                titleClassName="group-title light-box w-full"
                main={weebToken.convert.formatCurrency(senderTotalRebated)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderTotalRebated * priceInStableToken)}
                detailUnit={stableToken.symbol}
                info={(
                    <Activity
                        count={token.senderTotalRebated.count.toInt()}
                        timestamp={token.senderTotalRebated.timestamp.toInt()}
                        now={now}
                    />
                )}
                mainClassName="neon"
            />
            <PriceBox
                containerClassName="col-span-3"
                title="Your Total Burnt"
                titleClassName="group-title light-box w-full"
                main={weebToken.convert.formatCurrency(senderTotalBurnt)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(senderTotalBurnt * priceInStableToken)}
                detailUnit={stableToken.symbol}
                info={(
                    <Activity
                        count={token.senderTotalBurnt.count.toInt()}
                        timestamp={token.senderTotalBurnt.timestamp.toInt()}
                        now={now}
                    />
                )}
            />

            <span className="group-title light-box opacity-75 col-span-6">Inflation Regulator</span>
            <PriceBox
                containerClassName="col-span-3 sm:col-span-2"
                title="Time From Last Regulation"
                main={format.formatTimespan(now - regulationTimestamp)}
                mainClassName={now - regulationTimestamp > regulationInterval ? "text-red-500" : ""}
            />
            <PriceBox
                containerClassName="col-span-3 sm:col-span-2"
                title="Regulation Interval"
                main={format.formatTimespan(regulationInterval)}
            />
            <PriceBox
                containerClassName="col-span-6 sm:col-span-2"
                title="Interval Average Price"
                main={nativeTokenPriceInStableToken
                    ? stableToken.convert.formatCurrency(priceAverage * nativeTokenPriceInStableToken)
                    : nativeToken.convert.formatCurrency(priceAverage)
                }
                mainUnit={nativeTokenPriceInStableToken
                    ? stableToken.symbol
                    : nativeToken.symbol
                }
                detail={priceAverage === 0 ? "-" : weebToken.convert.formatCurrency(1 / priceAverage)}
                detailUnit={`${token.details.symbol}/${nativeToken.symbol}`}
            />
        </div>
    );
}

type PercentageYieldViewProps = {
    weebToken: WeebTokenCombinedState,
    stableToken: TokenState,
    token: WeebTokenViewModel,
    priceInStableToken: number,
    blockTime: number,
    investmentInStablecoin: number
}

export const PercentageYieldView = ({
    weebToken,
    stableToken,
    token,
    priceInStableToken,
    blockTime,
    investmentInStablecoin
}: PercentageYieldViewProps) => {
    const currentRewardPerBlock = weebToken.convert.fromWei(token.currentRewardPerBlock);
    const totalStaked = weebToken.convert.fromWei(token.totalStaked);
    const valueInStableToken = investmentInStablecoin / priceInStableToken;

    const cdpy = getDurationPercentageYield(currentRewardPerBlock, blockTime, totalStaked, valueInStableToken, 1);
    const cwpy = getDurationPercentageYield(currentRewardPerBlock, blockTime, totalStaked, valueInStableToken, DAYS_IN_WEEK);
    const cmpy = getDurationPercentageYield(currentRewardPerBlock, blockTime, totalStaked, valueInStableToken, DAYS_IN_MONTH);
    const capy = getDurationPercentageYield(currentRewardPerBlock, blockTime, totalStaked, valueInStableToken, DAYS_IN_YEAR);

    return (
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-2 gap-y-6 text-[80%]">
            <span className="group-title light-box opacity-75 col-span-2 sm:col-span-4">
                Current Percentage Yield
            </span>
            <span className="text-[0.8em] -mt-4 text-center lighter-box block col-span-2 sm:col-span-4">
                <span className="opacity-50">
                    For an investment amount of
                </span>
                <span className="ml-1 numeric">
                    {stableToken.convert.formatCurrency(investmentInStablecoin)}
                </span>
                <span className="uppercase ml-1 opacity-75 text-[0.75em]">{stableToken.symbol}</span>
            </span>
            <PriceBox
                title="Daily"
                main={cdpy.toPercent().formatPercentage(4)}
                mainUnit="%"
                detail={stableToken.convert.formatCurrency(investmentInStablecoin * cdpy)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Weekly"
                main={cwpy.toPercent().formatPercentage(3)}
                mainUnit="%"
                detail={stableToken.convert.formatCurrency(investmentInStablecoin * cwpy)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Monthly"
                main={cmpy.toPercent().formatPercentage()}
                mainUnit="%"
                detail={stableToken.convert.formatCurrency(investmentInStablecoin * cmpy)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                title="Annual"
                main={capy.toPercent().formatPercentage()}
                mainUnit="%"
                detail={stableToken.convert.formatCurrency(investmentInStablecoin * capy)}
                detailUnit={stableToken.symbol}
            />
        </div>
    );
}

type LiquidityAndOthersViewProps = {
    weebToken: WeebTokenCombinedState,
    nativeToken: TokenState,
    token: WeebTokenViewModel,
    reserves?: ReserveState,
    initialLiquidity: ReserveInfo,
    seignioryExplorerUri: string
}

export const LiquidityAndOthersView = ({
    weebToken,
    nativeToken,
    token,
    reserves,
    initialLiquidity,
    seignioryExplorerUri
}: LiquidityAndOthersViewProps) => {
    const tooltipSeigniorageFeeText = (
        <span className="tooltip text-[80%]">
            Fee paid to the seignory (token owner) from burns.
        </span>
    );

    const seigniorageFee = token.seigniorageFee.rate.toNumber().toRate();

    return (
        <div className="grid grid-cols-6 gap-x-2 gap-y-6 text-[80%]">
            <span className="group-title light-box opacity-75 col-span-6">
                Liquidity & Others
            </span>
            <PriceBox
                containerClassName="col-span-3"
                title="Total Liquidity"
                main={reserves ? weebToken.convert.formatCurrency(reserves.token1Reserve) : "-"}
                mainUnit={token.details.symbol}
                detail={reserves ? nativeToken.convert.formatCurrency(reserves.token2Reserve) : "-"}
                detailClassName="text-[1.4em] before:content-['+'] before:block before:text-[0.75em]"
                detailUnit={nativeToken.symbol}
                detailUnitClassName="text-[0.75em]"
            />
            <PriceBox
                containerClassName="col-span-3"
                title="Initial Liquidity"
                main={weebToken.convert.formatCurrency(weebToken.convert.fromWei(initialLiquidity.reserveWeebToken))}
                mainUnit={token.details.symbol}
                detail={nativeToken.convert.formatCurrency(nativeToken.convert.fromWei(initialLiquidity.reserveNativeToken))}
                detailClassName="text-[1.4em] before:content-['+'] before:block before:text-[0.75em]"
                detailUnit={nativeToken.symbol}
                detailUnitClassName="text-[0.75em]"
            />

            <div className={`col-span-6 border-bottom-t1`}></div>

            <PriceBox
                containerClassName="col-span-2"
                title={(
                    <>
                        <span className="mr-1">
                            Seigniorage Fee
                        </span>
                        <StyledPopover isTooltip={true} children={(<span>{tooltipSeigniorageFeeText}</span>)} />
                    </>
                )}
                main={seigniorageFee.toPercent().formatPercentage()}
                mainUnit="%"
                detail={(
                    <>
                        <i className="bi bi-clipboard mr-1 cursor-pointer inline-block active:animate-ping"
                            onClick={_ => {
                                navigator.clipboard.writeText(token.seigniory)
                                    .catch(ex => console.error(getError(ex)));
                            }}
                        />
                        <a href={seignioryExplorerUri} rel="noreferrer" target="_blank" className="numeric">
                            {format.formatAccount(token.seigniory)}
                        </a>
                    </>
                )}
                info={token.isSenderSeignory ? (<span className="font-display">You're Seignory</span>) : null}
                infoClassName="opacity-75 light-box font-semibold uppercase text-red-500"
            />
            <PriceBox
                containerClassName="col-span-2"
                title="Transfers"
                main={token.transfers.toInt().format(0)}
            />
            <PriceBox
                containerClassName="col-span-2"
                title="Holders"
                main={token.holders.toInt().format(0)}
            />
        </div>
    );
}

type TotalsViewProps = {
    weebToken: WeebTokenCombinedState,
    stableToken: TokenState,
    token: WeebTokenViewModel,
    priceInStableToken: number,
    now: number
}

export const TotalsView = ({
    weebToken,
    stableToken,
    token,
    priceInStableToken,
    now
}: TotalsViewProps) => {
    const totalRebated = weebToken.convert.fromWei(token.totalRebated.total);
    const totalBurnt = weebToken.convert.fromWei(token.totalBurnt.total);
    const totalHarvested = weebToken.convert.fromWei(token.totalHarvested.total);
    const totalTransacted = weebToken.convert.fromWei(token.totalTransacted.total);
    const seigniorageFeeCollected = weebToken.convert.fromWei(token.seigniorageFee.collected.total);
    const totalRewarded = weebToken.convert.fromWei(token.totalRewarded);

    return (
        <div className="grid grid-cols-6 gap-x-2 gap-y-6 text-[80%]">
            <span className="group-title light-box opacity-75 col-span-6">Statistics</span>
            <PriceBox
                containerClassName="col-span-3"
                title="Total Rebated"
                main={weebToken.convert.formatCurrency(totalRebated)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(totalRebated * priceInStableToken)}
                detailUnit={stableToken.symbol}
                info={(
                    <Activity
                        count={token.totalRebated.count.toInt()}
                        timestamp={token.totalRebated.timestamp.toInt()}
                        now={now}
                    />
                )}
            />
            <PriceBox
                containerClassName="col-span-3"
                title="Total Burnt"
                main={weebToken.convert.formatCurrency(totalBurnt)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(totalBurnt * priceInStableToken)}
                detailUnit={stableToken.symbol}
                info={(
                    <Activity
                        count={token.totalBurnt.count.toInt()}
                        timestamp={token.totalBurnt.timestamp.toInt()}
                        now={now}
                    />
                )}
            />

            <PriceBox
                containerClassName="col-span-3"
                title="Total Rewarded"
                main={weebToken.convert.formatCurrency(totalRewarded)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(totalRewarded * priceInStableToken)}
                detailUnit={stableToken.symbol}
            />
            <PriceBox
                containerClassName="col-span-3"
                title="Total Harvested"
                main={weebToken.convert.formatCurrency(totalHarvested)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(totalHarvested * priceInStableToken)}
                detailUnit={stableToken.symbol}
                info={(
                    <Activity
                        count={token.totalHarvested.count.toInt()}
                        timestamp={token.totalHarvested.timestamp.toInt()}
                        now={now}
                    />
                )}
            />

            <div className={`col-span-6 border-bottom-t1`}></div>

            <PriceBox
                containerClassName="col-span-3"
                title={(<>Total Transacted<span className="hidden sm:inline"> Volume</span></>)}
                main={weebToken.convert.formatCurrency(totalTransacted)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(totalTransacted * priceInStableToken)}
                detailUnit={stableToken.symbol}
                info={(
                    <Activity
                        count={token.totalTransacted.count.toInt()}
                        timestamp={token.totalTransacted.timestamp.toInt()}
                        now={now}
                    />
                )}
            />
            <PriceBox
                containerClassName="col-span-3"
                title="Seigniorage Collected"
                main={weebToken.convert.formatCurrency(seigniorageFeeCollected)}
                mainUnit={token.details.symbol}
                detail={stableToken.convert.formatCurrency(seigniorageFeeCollected * priceInStableToken)}
                detailUnit={stableToken.symbol}
                info={(
                    <Activity
                        count={token.seigniorageFee.collected.count.toInt()}
                        timestamp={token.seigniorageFee.collected.timestamp.toInt()}
                        now={now}
                    />
                )}
            />
        </div>
    );
}