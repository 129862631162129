import { useContext, useEffect, useState } from "react";
import QRCode from "qrcode";

import { getError } from "errors";

import appSettings from "configuration";
import WalletContext from "contexts/wallet-context";

import { PriceBox } from "components/shared";

import ModalDialog from "./modal-dialog";

type WalletDialogProps = {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
}

const WalletDialog = ({
    isOpen,
    setIsOpen
}: WalletDialogProps) => {
    const wallet = useContext(WalletContext);

    const [qrCode, setQrCode] = useState<string>();

    useEffect(() => {
        if (!wallet?.account) {
            setQrCode(undefined);
            return;
        }

        QRCode.toDataURL(wallet.account, {
            color: {
                dark: "#312e81",
                light: "#0000" // Transparent background
            }
        }).then(url => {
            setQrCode(url);
            console.log("QR", url);
        }).catch(ex => {
            console.error(getError(ex));
        })
    }, [wallet?.account]);

    // This dialog must be closed after disconnect or on invalid wallet.
    if (!wallet || !wallet.chainId || !wallet.account) {
        return <p className="text-red-500">Invalid wallet state</p>
    }

    const chain = appSettings.chains[wallet.chainId];

    return (
        <ModalDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disableBackgroundCancel={false}
            body={
                <div className="grid grid-cols-2 gap-x-2 gap-y-4 items-center justify-center text-indigo-900">
                    {wallet.account &&
                        <>
                            <PriceBox
                                containerClassName="col-span-2"
                                title="Connected Account"
                                main={wallet.account}
                                mainClassName="numeric break-all"
                            />
                            <img className="col-span-2 mx-auto" src={qrCode} />
                            <button
                                type="button"
                                className="btn btn-indigo-right h-10 w-48 text-violet-50 text-[0.75em] justify-self-end"
                                onClick={_ => {
                                    navigator.clipboard.writeText(wallet.account ?? "")
                                        .catch(ex => console.error(getError(ex)));
                                }}
                            >
                                <i className="bi bi-clipboard mr-1" />Copy
                            </button>
                            <button
                                type="button"
                                className="btn btn-indigo h-10 w-48 text-violet-50 text-[0.75em] justify-self-start"
                                onClick={() => {
                                    wallet.disconnect();
                                    setIsOpen(false);
                                }}
                            >
                                Disconnect
                            </button>
                            <PriceBox
                                title="Chain Id"
                                main={wallet.chainId}
                                mainClassName="numeric"
                                detail={chain.name}
                                detailClassName="font-display"
                            />
                            <PriceBox
                                title="Balance"
                                main={wallet.balance !== undefined ? wallet.balance.fromWei(chain.nativeCoin.decimals).formatScaled() : "-"}
                                mainUnit={chain.nativeCoin.symbol}
                                detail="&nbsp;"
                            />

                            {/*
                                <PriceBox
                                    title="Injected Provider"
                                    main={wallet.isInjectedProviderInstalled ? "Yes" : "No"}
                                    detail={wallet.provider.isMetaMask ? "MetaMask" : wallet.provider?.walletMeta?.name ?? "Unknown"}
                                    detailClassName="font-display"
                                />
                                <PriceBox
                                    title="Web3 Version"
                                    main={wallet.signer.version}
                                    mainClassName="numeric"
                                    detail="&nbsp;"
                                />
                                */}
                        </>
                    }
                </div>
            }
            title="Wallet Info"
            hideSubmitButton={true}
            cancelButtonText="Close"
        />
    );
}

export default WalletDialog;