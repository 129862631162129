import { useEffect, useState } from "react";

import { ChainInfo } from "configuration/types";

import { format } from "services/format";
import { StringExtensions } from "extensions/string-extensions";

import { TokensState } from "services/weeb-finance/tokens";
import { WeebFarmPoolViewModel, WeebFarmState, WeebFarmViewModel } from "services/weeb-finance/weeb-farm";
import { DeferredInput, PriceBox, TitleText } from "components/shared";
import { StyledPopover } from "components/shared/dialogs";

import { FarmPools } from "./weeb-farm.fragments";

type WeebFarmProps = {
    farmService: WeebFarmState;
    tokens: TokensState;
    blockTime: number;
    chain: ChainInfo;
    farm: WeebFarmViewModel;
    creditTokenPriceInStableToken: number;
    nativeTokenPriceInStableToken: number;
    deposit: (poolId: number) => void;
    withdraw: (poolId: number) => void;
    rebalance: (poolId: number) => void;
}

const WeebFarm = ({
    farmService,
    tokens,
    blockTime,
    chain,
    farm,
    creditTokenPriceInStableToken,
    nativeTokenPriceInStableToken,
    deposit,
    withdraw,
    rebalance
}: WeebFarmProps) => {
    const SortBy = Object.freeze({
        CreditMultiplier: 0,
        DepositFee: 1,
        CreditLiquidationRate: 2,
        LiquidatorFee: 3,
        Credited: 4
    });

    const SortDirection = Object.freeze({
        Ascending: 0,
        Descending: 1
    });

    const TokenType = Object.freeze({
        All: 0,
        Single: 1,
        Liquidity: 2
    });

    const [activePools, setActivePools] = useState(farm.pools);
    const [filter, setFilter] = useState<string>();
    const [sortBy, setSortBy] = useState(SortBy.CreditMultiplier);
    const [sortDirection, setSortDirection] = useState(SortDirection.Descending);
    const [tokenType, setTokenType] = useState(TokenType.All);

    const creditToken = farmService.createCreditToken(farm);
    const creditTokenRouterName = chain.contracts.swapRouters[farm.creditToken.router].name;

    console.assert(creditToken.router === tokens.weebToken.router && creditToken.token === tokens.weebToken.token, "Farm's creditToken should be weebToken.");

    const currentBlock = farm.currentBlock.toInt();

    const totalLiquidityProvided = creditToken.convert.fromWei(farm.totalLiquidityProvided.total);
    const totalCreditSupplied = farm.pools.reduce((sum, pool) => sum + creditToken.convert.fromWei(pool.totalPosition.credited), 0);

    useEffect(() => {
        const pools = farm.pools.filter(p => {
            let found = true;

            if (filter && filter.length > 0) {
                const supportedSwapRouterName = chain.contracts.swapRouters[p.depositToken.router].name;
                const depositTokenSymbol = farmService.getDepositTokenSymbol(p);

                found = StringExtensions.contains(supportedSwapRouterName, filter) || StringExtensions.contains(depositTokenSymbol, filter);
            }

            if (tokenType !== TokenType.All) {
                if (tokenType === TokenType.Single) {
                    found &&= !p.isLiquidityToken;
                } else if (tokenType === TokenType.Liquidity) {
                    found &&= p.isLiquidityToken;
                }
            }

            return found;
        });

        const compareFn = (a: WeebFarmPoolViewModel, b: WeebFarmPoolViewModel): number => {
            let x;
            let y;

            if (sortDirection === SortDirection.Ascending) {
                x = a;
                y = b;
            } else {
                x = b;
                y = a;
            }

            if (sortBy === SortBy.CreditMultiplier) {
                return x.creditMultiplier.toBN().cmp(y.creditMultiplier.toBN());
            }

            if (sortBy === SortBy.DepositFee) {
                return x.depositFee.rate.toBN().cmp(y.depositFee.rate.toBN());
            }

            if (sortBy === SortBy.CreditLiquidationRate) {
                return x.creditLiquidationRate.toBN().cmp(y.creditLiquidationRate.toBN());
            }

            if (sortBy === SortBy.LiquidatorFee) {
                return x.liquidatorFee.rate.toBN().cmp(y.liquidatorFee.rate.toBN());
            }

            if (sortBy === SortBy.Credited) {
                return x.senderPosition.credited.toBN().cmp(y.senderPosition.credited.toBN());
            }

            return 0;
        }

        pools.sort(compareFn);

        setActivePools(pools);
    }, [filter, tokenType, sortBy, sortDirection, farm]);

    function onTokenTypeChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setTokenType(e.target.value.toInt());
    }

    function onSortByChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setSortBy(e.target.value.toInt());
    }

    function onSortDirectionChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setSortDirection(e.target.value.toInt());
    }

    const totalLiquidityProvidedText = (
        <span className="tooltip text-[80%]">Total liquidity provided from the deposit fees collected.</span>
    );

    return (
        <div className="grid grid-cols-1 gap-x-2 gap-y-10">
            <div className="card bg-weeb-farm/60 bg-diagonal-lines text-center">
                <div className="grid grid-cols-2 gap-x-2 gap-y-3">
                    <span className="group-title light-box opacity-75 col-span-2">
                        Farm Totals
                    </span>
                    <PriceBox
                        title={(
                            <>
                                <span className="mr-1">Liquidity Added</span>
                                <StyledPopover isTooltip={true} children={totalLiquidityProvidedText} />
                            </>
                        )}
                        main={format.formatCurrency(totalLiquidityProvided, 18)}
                        mainUnit={`${farm.creditToken.details.symbol}/${tokens.nativeToken.symbol}`}
                        isMainUnitOnTop={true}
                        titleClassName="light-box"
                        mainUnitClassName="!opacity-25 mb-3"
                    />
                    <PriceBox
                        title="Credit Supplied"
                        main={creditToken.convert.formatCurrency(totalCreditSupplied)}
                        mainUnit={farm.creditToken.details.symbol}
                        isMainUnitOnTop={true}
                        titleClassName="light-box"
                        mainUnitClassName="!opacity-25 mb-3"
                    />
                </div>
            </div>

            <div className="card bg-weeb-farm/60 bg-diagonal-lines">
                <div className="grid grid-cols-12 gap-x-2 gap-y-4 justify-between">
                    <div className="col-span-12">
                        <div className="flex items-end justify-between">
                            <label htmlFor="filter">
                                <TitleText title="Filter pools" titleClassName="opacity-100 leading-6" />
                            </label>
                            <div>
                                {/* <TitleText title="Showing" titleClassName="opacity-100 leading-6 mr-1" /> */}
                                <span className="text-[1.2em]">
                                    <TitleText
                                        title={(
                                            <>
                                                <span className="numeric">{activePools.length.format(0)}</span>
                                                <span className="lowercase mx-1">of</span>
                                                <span className="numeric">{farm.pools.length.format(0)}</span>
                                            </>
                                        )}
                                        titleClassName="opacity-100 leading-6 light-box"
                                    />
                                </span>
                            </div>
                        </div>
                        <DeferredInput
                            type="search"
                            id="filter"
                            className="!text-[1em] px-2 mt-2"
                            placeholder="WEEB, Uniswap"
                            onInput={setFilter}
                        />
                    </div>

                    <div className="col-span-12 xl:col-span-8 accent-violet-600">
                        <label>
                            <TitleText title="Sort by" titleClassName="opacity-100 leading-6" />
                        </label>
                        <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-2 gap-y-0.5 justify-between">
                            <div className="flex items-center space-x-1">
                                <input className="w-4 h-4" type="radio" name="sortBy" id="sortBy1" value={SortBy.CreditMultiplier} checked={sortBy === SortBy.CreditMultiplier} onChange={onSortByChanged} />
                                <label htmlFor="sortBy1">
                                    <TitleText title="Credit Multiplier" titleClassName="opacity-100 normal-case text-[0.75em]" />
                                </label>
                            </div>
                            <div className="flex items-center space-x-1">
                                <input className="w-4 h-4" type="radio" name="sortBy" id="sortBy2" value={SortBy.DepositFee} checked={sortBy === SortBy.DepositFee} onChange={onSortByChanged} />
                                <label htmlFor="sortBy2">
                                    <TitleText title="Deposit Fee" titleClassName="opacity-100 normal-case text-[0.75em]" />
                                </label>
                            </div>
                            <div className="flex items-center space-x-1">
                                <input className="w-4 h-4" type="radio" name="sortBy" id="sortBy3" value={SortBy.CreditLiquidationRate} checked={sortBy === SortBy.CreditLiquidationRate} onChange={onSortByChanged} />
                                <label htmlFor="sortBy3">
                                    <TitleText title="Credit Liq. Rate" titleClassName="opacity-100 normal-case text-[0.75em]" />
                                </label>
                            </div>
                            <div className="flex items-center space-x-1">
                                <input className="w-4 h-4" type="radio" name="sortBy" id="sortBy4" value={SortBy.LiquidatorFee} checked={sortBy === SortBy.LiquidatorFee} onChange={onSortByChanged} />
                                <label htmlFor="sortBy4">
                                    <TitleText title="Liquidator Fee" titleClassName="opacity-100 normal-case text-[0.75em]" />
                                </label>
                            </div>
                            <div className="flex items-center space-x-1">
                                <input className="w-4 h-4" type="radio" name="sortBy" id="sortBy5" value={SortBy.Credited} checked={sortBy === SortBy.Credited} onChange={onSortByChanged} />
                                <label htmlFor="sortBy5">
                                    <TitleText title="Credited Amount" titleClassName="opacity-100 normal-case text-[0.75em]" />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-12 xl:col-span-4 accent-violet-600">
                        <div className="grid grid-cols-2 gap-x-2 gap-y-0.5 justify-between">
                            <div>
                                <label>
                                    <TitleText title="Sort direction" titleClassName="opacity-100" />
                                </label>
                                <div className="flex items-center space-x-2">
                                    <div className="flex items-center space-x-1">
                                        <input className="w-4 h-4" type="radio" name="sortDirection" id="sortDirection1" value={SortDirection.Ascending} checked={sortDirection === SortDirection.Ascending} onChange={onSortDirectionChanged} />
                                        <label htmlFor="sortDirection1">
                                            <TitleText title={(<i className="bi bi-sort-numeric-down text-[20px]" />)} titleClassName="opacity-100 normal-case" />
                                        </label>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <input className="w-4 h-4" type="radio" name="sortDirection" id="sortDirection2" value={SortDirection.Descending} checked={sortDirection === SortDirection.Descending} onChange={onSortDirectionChanged} />
                                        <label htmlFor="sortDirection2">
                                            <TitleText title={(<i className="bi bi-sort-numeric-down-alt text-[20px]"></i>)} titleClassName="opacity-100 normal-case" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label>
                                    <TitleText title="Token type" titleClassName="opacity-100" />
                                </label>
                                <div className="flex flex-wrap items-center gap-2">
                                    <div className="flex items-center space-x-1">
                                        <input className="w-4 h-4" type="radio" name="tokenType" id="tokenType1" value={TokenType.All} checked={tokenType === TokenType.All} onChange={onTokenTypeChanged} />
                                        <label htmlFor="tokenType1">
                                            <TitleText title="All" titleClassName="opacity-100 normal-case text-[0.75em]" />
                                        </label>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <input className="w-4 h-4" type="radio" name="tokenType" id="tokenType2" value={TokenType.Single} checked={tokenType === TokenType.Single} onChange={onTokenTypeChanged} />
                                        <label htmlFor="tokenType2">
                                            <TitleText title="Single" titleClassName="opacity-100 normal-case text-[0.75em]" />
                                        </label>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <input className="w-4 h-4" type="radio" name="tokenType" id="tokenType3" value={TokenType.Liquidity} checked={tokenType === TokenType.Liquidity} onChange={onTokenTypeChanged} />
                                        <label htmlFor="tokenType3">
                                            <TitleText title="Pair" titleClassName="opacity-100 normal-case text-[0.75em]" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-5 gap-y-10">
                <FarmPools
                    farmService={farmService}
                    tokens={tokens}
                    blockTime={blockTime}
                    currentBlock={currentBlock}
                    chain={chain}
                    creditToken={creditToken}
                    pools={activePools}
                    creditTokenPriceInStableToken={creditTokenPriceInStableToken}
                    nativeTokenPriceInStableToken={nativeTokenPriceInStableToken}
                    deposit={deposit}
                    withdraw={withdraw}
                    rebalance={rebalance}
                />
            </div>
        </div>
    );
}

export default WeebFarm;