import { ChainInfo } from "configuration/types";

import { TokenState } from "services/erc20";
import { TokensState } from "services/weeb-finance/tokens";
import { WeebFarmPoolViewModel, WeebFarmState } from "services/weeb-finance/weeb-farm";

import WeebFarmPool from "./weeb-farm-pool";

type FarmPoolsProps = {
    farmService: WeebFarmState;
    tokens: TokensState;
    blockTime: number;
    currentBlock: number;
    chain: ChainInfo;
    creditToken: TokenState;
    pools: WeebFarmPoolViewModel[];
    creditTokenPriceInStableToken: number;
    nativeTokenPriceInStableToken: number;
    deposit: (poolId: number) => void;
    withdraw: (poolId: number) => void;
    rebalance: (poolId: number) => void;
}

export const FarmPools = ({
    farmService,
    tokens,
    blockTime,
    currentBlock,
    chain,
    creditToken,
    pools,
    creditTokenPriceInStableToken,
    nativeTokenPriceInStableToken,
    deposit,
    withdraw,
    rebalance
}: FarmPoolsProps) => {
    const items = pools.map(pool => {
        const poolId = pool.poolId.toInt();

        return (
            <div key={poolId} className="max-h-full">
                <WeebFarmPool
                    farmService={farmService}
                    tokens={tokens}
                    blockTime={blockTime}
                    currentBlock={currentBlock}
                    chain={chain}
                    creditToken={creditToken}
                    pool={pool}
                    creditTokenPriceInStableToken={creditTokenPriceInStableToken}
                    nativeTokenPriceInStableToken={nativeTokenPriceInStableToken}
                    deposit={() => deposit(poolId)}
                    withdraw={() => withdraw(poolId)}
                    rebalance={() => rebalance(poolId)}
                />
                <hr className="border-0 h-[2px]" style={{ backgroundImage: "linear-gradient(to right, transparent, rgb(196 181 253), transparent)" }} />
            </div>
        );
    });

    return (<>{items}</>);
}
