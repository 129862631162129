export function getQuoteAssetInflowRequiredToIncreaseThePriceByFactorOf(factor: number, reserveQuoteAsset: number) {
    return (Math.sqrt(factor) - 1) * reserveQuoteAsset;
}

export function getBaseAssetOutflowRequiredToDecreaseThePriceByFactorOf(factor: number, reserveBaseAsset: number) {
    return (1 / Math.sqrt(factor) - 1) * reserveBaseAsset;
}

export function getPriceIncreaseFactorFromQuoteAssetInflow(inflowQuoteAssetAmount: number, reserveQuoteAsset: number) {
    return Math.pow((inflowQuoteAssetAmount / reserveQuoteAsset + 1), 2);
}

export function getPriceDecreaseFactorFromBaseAssetOutflow(outflowBaseAssetAmount: number, reserveBaseAsset: number) {
    return Math.pow(1 / (outflowBaseAssetAmount / reserveBaseAsset + 1), 2);
}

export function getAmountOut(amount0In: number, reserve0: number, reserve1: number) {
    return amount0In * reserve1 / (amount0In + reserve0);
}