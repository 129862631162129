import { useState } from "react";

import { ChainInfo } from "configuration/types";

import { ModalDialog, StyledPopover } from "components/shared/dialogs";
import { TokenSelector } from "components/shared";

import { PersonalWalletInfo } from "services/shared.types";
import { IziswapState } from "services/iziswap";
import { MoralisState } from "services/moralis";
import { TokenFinderState } from "services/token-finder";
import { TokenState } from "services/erc20";
import { NativeCoinState, TokensState } from "services/weeb-finance/tokens";
import { WeebFarmPoolViewModel, WeebFarmState } from "services/weeb-finance/weeb-farm";

type FarmDepositDialogProps = {
    wallet: PersonalWalletInfo;
    iziswap: IziswapState;
    moralis: MoralisState;
    tokenFinder: TokenFinderState;
    tokens: TokensState;
    chain: ChainInfo,
    farmService: WeebFarmState,
    pool: WeebFarmPoolViewModel,

    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    onCancel?: Function,
    onSubmit: Function,
    title: string,
    submitButtonText: string
}

const FarmDepositDialog = ({
    wallet,
    iziswap,
    moralis,
    tokenFinder,
    tokens,
    chain,
    farmService,
    pool,

    isOpen,
    setIsOpen,
    onCancel,
    onSubmit,
    title,
    submitButtonText
}: FarmDepositDialogProps) => {
    const [selectedToken, setSelectedToken] = useState<NativeCoinState | TokenState | undefined>();
    const [amount, setAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const tooltipDescriptionText = (
        <div className="tooltip text-[80%]">
            <p>
                You can deposit any token you have. The conversion to the pool's deposit token will be held automatically at the current rate.
            </p>
            <p>
                You can make partial withdraws.
            </p>
            <p>
                Liquidation is where your deposit tokens are converted to the credit tokens and added to your balance when your credit level falls below your liquidation level.
                <br />
                Rebalance when your credit level goes above your liquidation level for better yield generation.
                <br />
                Rebalance to keep your credit level above your liquidation level as they get close to not be liquidated.
            </p>
        </div>
    );

    return (
        <ModalDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disableBackgroundCancel={false}
            body={
                <TokenSelector
                    wallet={wallet}
                    iziswap={iziswap}
                    moralis={moralis}
                    tokenFinder={tokenFinder}
                    tokens={tokens}
                    chain={chain}
                    depositToken={farmService.createDepositToken(pool)}
                    setSelectedToken={setSelectedToken}
                    setAmount={setAmount}
                    setIsLoading={setIsLoading}
                />
            }
            title={title}
            info={
                <StyledPopover
                    isTooltip={true}
                    children={tooltipDescriptionText}
                    icon="bi-info"
                />
            }
            submitButtonText={submitButtonText}
            isSubmitButtonDisabled={isLoading || amount <= 0}
            onCancel={onCancel}
            onSubmit={() => onSubmit(pool.poolId.toInt(), selectedToken, amount)}
        />
    );
}

export default FarmDepositDialog;