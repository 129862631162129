export class FunctionExtensions {
    static isAsyncFunction(f: any): boolean {
        return f && f.constructor.name === "AsyncFunction";
    }

    static isPromiseFunction<T>(fn: any, ...params: any[]): Promise<T> | boolean {
        const isFunction = fn && typeof fn === "function";
        const notAsyncFunction = fn.constructor.name !== "AsyncFunction";

        if (isFunction && notAsyncFunction) {
            const value = fn(...params) || false;
            
            if (value && value.constructor.name === "Promise") {
                return value as Promise<T>;
            }
        }

        return false;
    }

    static isAsync<T>(fn: any, ...params: any[]): Promise<T> | boolean {
        const isFunction = fn && typeof fn === "function";

        if (isFunction) {
            const value = fn(...params) || false;

            if (value && value.constructor.name === "Promise") {
                return value as Promise<T>;
            }
        }

        return false;
    }
}