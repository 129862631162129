import { ChainInfo } from "configuration/types";
import { PersonalWalletInfo } from "services/shared.types";
import { SettingsRepositoryState } from "services/settings-repository";
import { TokensState } from "services/weeb-finance/tokens";
import { MainText, RangeInput } from "./shared";
import { StyledPopover } from "./shared/dialogs";
import { Web3Extensions } from "extensions/web3-extensions";

import weebLogo from "assets/images/weeb-icon.svg";

type SettingsProps = {
    wallet: PersonalWalletInfo;
    settingsRepositoryService: SettingsRepositoryState;
    chain: ChainInfo;
    tokens: TokensState;
    isDarkTheme: boolean;
}

const Settings = ({
    wallet,
    settingsRepositoryService,
    chain,
    tokens,
    isDarkTheme
}: SettingsProps) => {
    const tooltipSlippageToleranceText = (
        <ol className="tooltip text-[80%] list-decimal list-inside space-y-3">
            <li>
                For {tokens.weebToken.symbol} buy transactions slippage tolerance can be set as low as the swap router's commission, {chain.contracts.swapRouters[chain.contracts.defaultSwapRouter].fee.toRate().toPercent().formatPercentage()}% for {chain.contracts.swapRouters[chain.contracts.defaultSwapRouter].name}.
            </li>
            <li>
                For {tokens.weebToken.symbol} sell transactions slippage tolerance should not be less than account's "Sales Burn Rate" plus the swap router's commission, {chain.contracts.swapRouters[chain.contracts.defaultSwapRouter].fee.toRate().toPercent().formatPercentage()}% for {chain.contracts.swapRouters[chain.contracts.defaultSwapRouter].name}.
            </li>
            <li>
                For other conversion transactions; since other tokens may have burning mechanisms as well those values must be added on top of the {tokens.weebToken.symbol} transaction type that is going to be performed.
            </li>
            <li>
                The actual number of paths taken during conversion depends on the type of the starting token, if that token is LP token it will recuire at least 2 more swap router's commission values to be included in the calculation.
            </li>
        </ol>
    );

    const tooltipTransactionDeadlineText = (
        <ul className="tooltip text-[80%] list-inside space-y-3">
            <li>
                In case a swap transaction delays to be included in a block (mainly due to low gas fee or node issues) and later is included, the time span on which the sender agrees the result of the execution is acceptable.
            </li>
            <li>
                It is a trade off between gas fee and time. If the sender expects conditions to not change for better or worse then time can be increased.
            </li>
        </ul>
    );

    const toDataURL = (url: string) => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve: (value: string | ArrayBuffer | null) => void, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        }));

    return (
        <>
            <div className="grid grid-cols-1 gap-y-10">
                <div className={`card bg-weeb-token/10`}>
                    <div className="grid grid-cols-1 gap-y-0.5">
                        <div className="flex items-center justify-between leading-6">
                            <div className="flex">
                                <label htmlFor="value_slippageTolerance" className="input-label">
                                    <span className="mr-1">
                                        Slippage tolerance:
                                    </span>
                                </label>
                                <StyledPopover isTooltip={true} children={(<span>{tooltipSlippageToleranceText}</span>)} />
                            </div>
                            <span className="input-label">
                                <i className="bi bi-percent" />
                            </span>
                        </div>
                        <RangeInput
                            isDisabled={false}
                            defaultValue={settingsRepositoryService.getSlippageTolerance() / 100}
                            min={0}
                            max={100}
                            decimals={2}
                            percentages={[0.1, 0.2, 0.3, 0.5, 1, 2, 3, 5, 10]}
                            setValue={value => {
                                const tolerance = Number(value.valueOf());
                                settingsRepositoryService.setSlippageTolerance(tolerance * 100);
                            }}
                            setPercentage={_ => {
                            }}
                            valueIdSuffix="slippageTolerance"
                            valueClassName={`${isDarkTheme ? "bg-slate-900/80" : ""}`}
                            rangeClassName={`${isDarkTheme ? "accent-slate-800" : ""}`}
                            buttonClassName={`btn text-indigo-50 numeric w-11 h-7 ${isDarkTheme ? "btn-slate bg-slate-900/80 focus:hover:text-slate-200" : "btn-violet bg-violet-900/20 hover:text-violet-900 focus:hover:text-violet-200"}`}
                        />
                    </div>
                </div>

                <div className={`card bg-weeb-token/10`}>
                    <div className="grid grid-cols-1 gap-y-0.5">
                        <div className="flex items-center justify-between leading-6">
                            <div className="flex">
                                <label htmlFor="value_transactionDeadline" className="input-label">
                                    <span className="mr-1">
                                        Minutes a swap transaction cancels after:
                                    </span>
                                </label>
                                <StyledPopover isTooltip={true} children={(<span>{tooltipTransactionDeadlineText}</span>)} />
                            </div>
                        </div>
                        <RangeInput
                            isDisabled={false}
                            defaultValue={settingsRepositoryService.getTransactionDeadline() / 60}
                            min={1}
                            max={60}
                            decimals={2}
                            percentages={[]}
                            setValue={value => {
                                const deadline = Number(value.valueOf());
                                settingsRepositoryService.setTransactionDeadline(deadline * 60);
                            }}
                            setPercentage={_ => {
                            }}
                            valueIdSuffix="transactionDeadline"
                            valueClassName={`${isDarkTheme ? "bg-slate-900/80" : ""}`}
                            rangeClassName={`${isDarkTheme ? "accent-slate-800" : ""}`}
                            buttonClassName={`btn text-indigo-50 numeric w-11 h-7 ${isDarkTheme ? "btn-slate bg-slate-900/80 focus:hover:text-slate-200" : "btn-violet bg-violet-900/20 hover:text-violet-900 focus:hover:text-violet-200"}`}
                        />
                    </div>
                </div>

                <button
                    type="button"
                    className={`-mt-5 p-2 text-[0.75em] btn btn-indigo-right ${isDarkTheme ? "text-violet-50" : ""}`}
                    disabled={false}
                    onClick={async () => {
                        const image = await toDataURL(weebLogo) as string;

                        return Web3Extensions.watchAsset(wallet.signer.currentProvider, {
                            type: "ERC20",
                            options: {
                                address: tokens.weebToken.token,
                                decimals: tokens.weebToken.decimals,
                                symbol: tokens.weebToken.symbol,
                                image
                            }
                        });
                    }}
                >
                    <div className="flex items-center justify-center">
                        {/* <img src={weebLogo} className="w-6 mr-2 rounded-full" /> */}
                        Add {tokens.weebToken.symbol} to wallet
                    </div>
                </button>
            </div>
        </>
    )
}

export default Settings;