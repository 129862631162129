import { useContext, useState } from "react";

import Web3 from "web3";

import { getError } from "errors";

import WalletContext from "contexts/wallet-context";

import appSettings from "configuration";
import { Web3Extensions } from "extensions/web3-extensions";

const Test = () => {
    const wallet = useContext(WalletContext);

    const [isExecuting, setIsExecuting] = useState(false);
    const [secondaryToken, setSecondaryToken] = useState<string>();
    const [walletContext, setWalletContext] = useState<string>();

    const chainId = appSettings.defaultChainId;
    const chain = appSettings.chains[chainId];
    const chainName = chain.name;
    const nativeCurrencyName = undefined;
    const nativeCurrencySymbol = chain.nativeCoin.symbol;
    const nativeCurrencyDecimals = chain.nativeCoin.decimals;
    const rpcUrl = chain.rpcUri;
    const blockExplorerUrl = undefined;
    const iconUrls = undefined;

    const tokenRequestUri = appSettings.testTokenRequestUri;

    if (!wallet) {
        return <p className="text-red-500">Invalid wallet state</p>
    }

    const replacer = (key: string, value: string) => {
        if (key === "$" || key === "self" || key === "eth" || key === "utils") {
            return undefined;
        }

        if (key.startsWith("_")) {
            return undefined;
        }

        // if (typeof value === "function") {
        //     return undefined;
        // }

        return value;
    }

    const switchOrAddEthereumChain = () => {
        const chainId = appSettings.defaultChainId;
        const chain = appSettings.chains[chainId];
        const chainName = chain.name;
        const nativeCurrencyName = undefined;
        const nativeCurrencySymbol = chain.nativeCoin.symbol;
        const nativeCurrencyDecimals = chain.nativeCoin.decimals;
        const rpcUrls = [chain.rpcUri];
        const blockExplorerUrls: string[] | undefined = undefined;
        const iconUrls: string[] | undefined = undefined;

        Web3Extensions.switchOrAddEthereumChain(wallet.provider,
            {
                chainId,
                chainName,
                nativeCurrencyName,
                nativeCurrencySymbol,
                nativeCurrencyDecimals,
                rpcUrls,
                blockExplorerUrls: blockExplorerUrls,
                iconUrls
            }
        );
    }

    return (
        <div className="flex flex-col justify-center items-center gap-10">
            <h3 className="font-bold mt-5 -mb-5">Test Node Settings</h3>

            <div className="flex flex-col gap-1 w-full p-3 rounded-xl bg-indigo-900">
                <span className="font-bold text-indigo-300">Network Name:</span>
                <span>{chainName}</span>

                <span className="font-bold text-indigo-300 mt-3">New RPC URL:</span>
                <span>{rpcUrl}</span>

                <span className="font-bold text-indigo-300 mt-3">Chain ID:</span>
                <span>{chainId}</span>

                <span className="font-bold text-indigo-300 mt-3">Currency Symbol:</span>
                <span>{nativeCurrencySymbol}</span>

                <span className="font-bold text-indigo-300 mt-3">Block Explorer URL:</span>
                <span>{blockExplorerUrl ?? "Leave empty"}</span>
            </div>

            {wallet.isInjectedProviderInstalled &&
                <div className="text-center">
                    <button
                        type="button"
                        className="btn btn-indigo p-2 w-72 font-semibold"
                        onClick={switchOrAddEthereumChain}
                    >
                        Add Test Chain to Wallet
                    </button>
                </div>
            }

            <div className="mt-10 text-center">
                <label>
                    Secondary token address:
                    <input
                        type="text"
                        onChange={e => {
                            const token = e.target.value;

                            if (token && Web3.utils.isAddress(token.toLowerCase())) {
                                setSecondaryToken(token);
                            }
                        }}
                    />
                </label>
                <button
                    disabled={wallet.account && !isExecuting ? false : true}
                    type="button"
                    className="btn btn-rose p-2 w-72 font-semibold mt-5"
                    onClick={async () => {
                        setIsExecuting(true);

                        const response1 = await fetch(tokenRequestUri, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                                // 'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            body: JSON.stringify({
                                ta: chain.contracts.weebToken.address,
                                ts: chain.contracts.weebToken.symbol,
                                td: chain.contracts.weebToken.decimals,
                                ra: wallet.account,
                                eq: 2,
                                tq: 20000
                            }) // body data type must match "Content-Type" header
                        });

                        const rx1 = await response1.json();
                        console.log(rx1);

                        let rx = JSON.stringify(rx1, null, ' ');

                        if (secondaryToken) {
                            const response2 = await fetch(tokenRequestUri, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json"
                                    // 'Content-Type': 'application/x-www-form-urlencoded',
                                },
                                body: JSON.stringify({
                                    ta: secondaryToken,
                                    ts: "Test",
                                    td: 18,
                                    ra: wallet.account
                                }) // body data type must match "Content-Type" header
                            });

                            const rx2 = await response2.json();
                            console.log(rx2);

                            rx = `${rx}\r\n${JSON.stringify(rx2, null, ' ')}`;
                        }

                        setIsExecuting(false);

                        alert(rx);
                    }}
                >
                    Send Test Tokens
                </button>
                <p className="my-3 text-[80%]">{tokenRequestUri}</p>
            </div>

            <button
                type="button"
                className="btn btn-green p-2 w-72 font-semibold"
                onClick={() => {
                    const json = JSON.stringify(wallet, replacer, '\t');
                    setWalletContext(json);
                    navigator.clipboard.writeText(json)
                        .catch(ex => console.error(getError(ex)));
                }}
            >
                Show Wallet Context
            </button>

            <textarea className="w-full h-screen font-mono text-[80%] bg-slate-900 rounded-xl p-3" value={walletContext ?? "No wallet context"} readOnly />
        </div >
    )
}

export default Test;