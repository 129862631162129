import { Address, ChainInfo } from "configuration/types";

import { TokenState } from "services/erc20";
import { WeebFarmPoolLiquidatablesViewModel, WeebFarmState } from "services/weeb-finance/weeb-farm";

import { MainText, PriceBox, TitleText } from "components/shared";
import { FarmLiquidatables, getPoolSelfTotals } from "./weeb-farm-liquidatable.fragments";
import { StyledPopover } from "components/shared/dialogs";

type WeebFarmLiquidatableProps = {
    farmService: WeebFarmState;
    chain: ChainInfo;
    creditToken: TokenState;
    senderCreditTokenBalance: number;
    poolLiquidatables: WeebFarmPoolLiquidatablesViewModel;
    liquidate: (poolId: number, investor: Address) => void;
    buyCreditToken: () => void;
}

const WeebFarmLiquidatable = ({
    farmService,
    chain,
    creditToken,
    senderCreditTokenBalance,
    poolLiquidatables,
    liquidate,
    buyCreditToken
}: WeebFarmLiquidatableProps) => {
    const depositTokenSymbol = farmService.getDepositTokenSymbol(poolLiquidatables.pool);
    const depositTokenRouterName = chain.contracts.swapRouters[poolLiquidatables.pool.depositToken.router].name;

    const totalLiquidity = creditToken.convert.fromWei(poolLiquidatables.totalLiquidity.total);
    const totalLiquidatorFee = creditToken.convert.fromWei(poolLiquidatables.totalLiquidatorFee.total);
    const liquidatorBalanceRate = poolLiquidatables.pool.liquidatorBalanceRate.toNumber().toRate();

    const { fee, count } = getPoolSelfTotals(creditToken, poolLiquidatables);
    const netLiquidatorFee = totalLiquidatorFee - fee;

    const tooltipLiquidatorBalanceRateText = (
        <span className="tooltip text-[80%]">Percentage of the liquidity the liquidator has to have as their balance to be able liquidate the specified item.</span>
    );

    return (
        <div className="card bg-[#1e0f3c80] bg-diagonal-lines">
            <div className="grid grid-cols-1 gap-y-4">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-4 text-[80%]">
                    <PriceBox
                        title="Deposit Token"
                        main={depositTokenSymbol}
                        mainUnit={depositTokenRouterName}
                        isMainUnitOnTop={true}
                        mainClassName="font-display"
                        mainUnitClassName="!opacity-25 mb-3 font-display"
                        titleClassName="light-box"
                    />
                    <PriceBox
                        title={(
                            <>
                                <span className="mr-1">Required Balance</span>
                                <StyledPopover isTooltip={true} children={tooltipLiquidatorBalanceRateText} />
                            </>
                        )}
                        main={(
                            <>
                                <span className="numeric text-[1.4em]">
                                    {liquidatorBalanceRate.toPercent().formatPercentage()}
                                </span>
                                <span className="uppercase ml-1 opacity-75 text-[0.75em]">%</span>
                            </>
                        )}
                        mainUnit={creditToken.symbol}
                        isMainUnitOnTop={true}
                        mainClassName="!text-[1em]"
                        mainUnitClassName="!opacity-25 mb-3 font-display"
                        titleClassName="light-box"
                    />
                    <PriceBox
                        title="Total Liquidity"
                        main={creditToken.convert.formatCurrency(totalLiquidity)}
                        mainUnit={creditToken.symbol}
                        info={(
                            <>
                                <div className="flex justify-evenly space-x-1">
                                    <span className="light-box w-full">
                                        <i className="bi bi-app-indicator mr-1" />
                                        {poolLiquidatables.totalLiquidity.count.toInt().format(0)}
                                    </span>
                                </div>
                            </>
                        )}
                        isMainUnitOnTop={true}
                        mainUnitClassName="!opacity-25 mb-3"
                        titleClassName="light-box"
                    />
                    <PriceBox
                        //title="Total Liquidator Fee"
                        title="Total Trophy"
                        main={creditToken.convert.formatCurrency(netLiquidatorFee)}
                        mainUnit={creditToken.symbol}
                        info={(
                            <>
                                <div className="flex justify-evenly space-x-1">
                                    <span className="light-box w-full">
                                        <i className="bi bi-app-indicator mr-1" />
                                        {(poolLiquidatables.totalLiquidatorFee.count.toInt() - count).format(0)}
                                    </span>
                                </div>
                            </>
                        )}
                        isMainUnitOnTop={true}
                        mainUnitClassName="!opacity-25 mb-3"
                        titleClassName="light-box"
                    />
                </div>

                <div className="grid grid-cols-4 gap-x-2 gap-y-2 items-center text-center text-[80%]">
                    <div className="flex flex-col">
                        <TitleText title="Investor" titleClassName="light-box" />
                        <MainText
                            mainUnit={depositTokenRouterName}
                            isUnitOnTop={true}
                            mainClassName="font-display"
                            mainUnitClassName="!opacity-25 mt-3 font-display"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TitleText title="Liquidity" titleClassName="light-box" />
                        <MainText
                            mainUnit={creditToken.symbol}
                            isUnitOnTop={true}
                            mainUnitClassName="!opacity-25 mt-3"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TitleText
                            //title="Liquidator Fee"
                            title="Trophy"
                            titleClassName="light-box" />
                        <MainText
                            mainUnit={creditToken.symbol}
                            isUnitOnTop={true}
                            mainUnitClassName="!opacity-25 mt-3"
                        />
                    </div>
                    <div className="flex flex-col">
                        <TitleText title="Action" titleClassName="light-box" />
                        <MainText
                            mainUnit="&nbsp;"
                            isUnitOnTop={true}
                            mainUnitClassName="!opacity-25 mt-3"
                        />
                    </div>
                    <FarmLiquidatables
                        creditToken={creditToken}
                        senderCreditTokenBalance={senderCreditTokenBalance}
                        poolId={poolLiquidatables.pool.poolId.toInt()}
                        investorLiquidatables={poolLiquidatables.investorLiquidatables}
                        liquidatorBalanceRate={liquidatorBalanceRate}
                        liquidate={liquidate}
                        buyCreditToken={buyCreditToken}
                    />
                </div>
            </div>
        </div>
    );
}

export default WeebFarmLiquidatable;