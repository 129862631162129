import React from "react";
import { Dialog } from "@headlessui/react";

import * as errors from "errors";
import { FunctionExtensions } from "extensions/function-extensions";

export enum DialogResult {
    OK,
    Cancel,
}

type ModalDialogProps = {
    disableBackgroundCancel: boolean,
    contentStyle: string,
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    header: React.ReactNode,
    body: React.ReactNode,
    footer: React.ReactNode,
    title: string,
    hideTitle: boolean,
    info: React.ReactNode,
    hideCloseButton: boolean,
    description: string,
    submitButtonText: string,
    hideSubmitButton: boolean,
    isSubmitButtonDisabled: boolean,
    cancelButtonText: string,
    hideCancelButton: boolean,
    onCancel: Function,
    onSubmit: Function,
    // Will be called after onCancel/onSubmit actions.
    setResult: (value: DialogResult | Error) => void;
}

const ModalDialog = ({
    disableBackgroundCancel = false,
    contentStyle = "card relative w-full max-w-md max-h-screen overflow-y-auto mx-auto bg-violet-50 text-gray-900",
    isOpen = false,
    setIsOpen,
    header,
    body,
    footer,
    title,
    hideTitle = false,
    info,
    hideCloseButton = false,
    description,
    submitButtonText = "OK",
    hideSubmitButton = false,
    isSubmitButtonDisabled = false,
    cancelButtonText = "Cancel",
    hideCancelButton = false,
    onCancel,
    onSubmit,
    setResult
}: Partial<ModalDialogProps>) => {
    const handleCancel = () => {
        if (setIsOpen) {
            setIsOpen(false);
        }

        if (FunctionExtensions.isAsyncFunction(onCancel)) {
            if (onCancel) {
                onCancel()
                    .then(() => {
                        if (setResult) {
                            setResult(DialogResult.Cancel);
                        }
                    }).catch((ex: unknown) => {
                        const e = errors.getError(ex);
                        console.error(e);

                        if (setResult) {
                            setResult(e);
                        }
                    });
            } else {
                if (setResult) {
                    setResult(DialogResult.Cancel);
                }
            }
        } else {
            try {
                if (onCancel) {
                    onCancel();
                }

                if (setResult) {
                    setResult(DialogResult.Cancel);
                }
            } catch (ex: unknown) {
                const e = errors.getError(ex);
                console.error(e);

                if (setResult) {
                    setResult(e);
                }
            }
        }
    };

    const handleSubmit = () => {
        if (setIsOpen) {
            setIsOpen(false);
        }

        if (FunctionExtensions.isAsyncFunction(onSubmit)) {
            if (onSubmit) {
                onSubmit()
                    .then(() => {
                        if (setResult) {
                            setResult(DialogResult.OK);
                        }
                    }).catch((ex: unknown) => {
                        const e = errors.getError(ex);
                        console.error(e);

                        if (setResult) {
                            setResult(e);
                        }
                    });
            } else {
                if (setResult) {
                    setResult(DialogResult.OK);
                }
            }
        } else {
            try {
                if (onSubmit) {
                    onSubmit();
                }

                if (setResult) {
                    setResult(DialogResult.OK);
                }
            } catch (ex: unknown) {
                const e = errors.getError(ex);
                console.error(e);

                if (setResult) {
                    setResult(e);
                }
            }
        }
    };

    return (
        <Dialog
            className="fixed z-50 inset-0 overflow-y-auto"
            open={isOpen}
            onClose={() => {
                if (!disableBackgroundCancel) {
                    if (setIsOpen) {
                        setIsOpen(false);
                    }
                }
            }}
        >
            <div className="flex items-center justify-center min-h-screen text-[16px]">
                <Dialog.Overlay className="fixed inset-0 bg-black/50" />

                <div className={`modal-content ${contentStyle} bg-gradient-to-r to-indigo-200 from-purple-200`}>
                    {(header || !hideTitle || !hideCloseButton) &&
                        <div className="modal-header grid grid-cols-1 gap-x-2 gap-y-3">
                            {header ||
                                <div className="flex items-center justify-between">
                                    {!hideTitle &&
                                        <Dialog.Title key="0" className="">
                                            {title}
                                        </Dialog.Title>
                                    }
                                    <div className="space-x-2">
                                        {info &&
                                            <button
                                                key="1"
                                                type="button"
                                                className="btn text-[2em] leading-none shadow-none btn-close"
                                                aria-label="info"
                                            >
                                                {info}
                                            </button>
                                        }
                                        {!hideCloseButton &&
                                            <button
                                                key="2"
                                                type="button"
                                                className="btn text-[2em] leading-none shadow-none btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="close"
                                                onClick={handleCancel}
                                            >
                                                <i className="bi bi-x" />
                                            </button>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {description &&
                        <Dialog.Description className="mt-3">
                            {description}
                        </Dialog.Description>
                    }

                    <div className="modal-body mt-3">
                        {body}
                    </div>

                    {(footer || !hideSubmitButton || !hideCancelButton) &&
                        <div className="modal-footer mt-8">
                            {footer ||
                                <div className="flex items-center justify-end space-x-2 text-violet-50 text-[80%]">
                                    {!hideSubmitButton &&
                                        <button
                                            key="0"
                                            type="button"
                                            className="btn btn-green w-28 h-10"
                                            onClick={handleSubmit}
                                            disabled={isSubmitButtonDisabled}
                                        >
                                            {submitButtonText}
                                        </button>
                                    }
                                    {!hideCancelButton &&
                                        <button
                                            key="1"
                                            type="button"
                                            className="btn btn-dark w-28 h-10"
                                            data-bs-dismiss="modal"
                                            aria-label="cancel"
                                            onClick={handleCancel}
                                        >
                                            {cancelButtonText}
                                        </button>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </Dialog>
    );
}

export default ModalDialog;