import { NavLink } from "react-router-dom";

const Links = () => {
  return (
    <>
      <NavLink className="nav-link" to="/">Home</NavLink>
      <NavLink className="nav-link" to="/games">Games</NavLink>
      <NavLink className="nav-link" to="/farms">Farms</NavLink>
      <NavLink className="nav-link" to="/hunt">Hunt</NavLink>
    </>
  )
}

export default Links;
