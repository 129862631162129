import BN from "bn.js";

import { PromiEvent } from "web3-core/types";
import { AbiItem } from "web3-utils/types";
import { Contract, ContractOptions } from "web3-eth-contract/types";

import appSettings from "configuration";
import { Address } from "configuration/types";

import { AnonymousWalletInfo, PersonalWalletInfo } from "services/shared.types";
import { IziswapState } from "./iziswap.types";

import Iziswap_ABI from "contracts/abi/Iziswap.json";

const createIziswap = (wallet: AnonymousWalletInfo | PersonalWalletInfo): IziswapState => {
    const chain = appSettings.chains[wallet.chainId];

    const options = { from: wallet.reader.defaultAccount } as ContractOptions;
    const abi = Iziswap_ABI as AbiItem[];

    const readerContract = new wallet.reader.eth.Contract(abi, chain.contracts.iziswap, options);

    const read = {
        readerContract,
        quoteETH: (amountETH: BN, targetRouter: Address, targetToken: Address, isTargetLiquidityToken: boolean): Promise<BN> => readerContract.methods.quoteETH(amountETH, targetRouter, targetToken, isTargetLiquidityToken).call(),
        quote: (sourceRouter: Address, sourceToken: Address, isSourceLiquidityToken: boolean, sourceTokenAmount: BN, targetRouter: Address, targetToken: Address, isTargetLiquidityToken: boolean): Promise<BN> => readerContract.methods.quote(sourceRouter, sourceToken, isSourceLiquidityToken, sourceTokenAmount, targetRouter, targetToken, isTargetLiquidityToken).call(),
    }

    let sign;

    if ("signer" in wallet && wallet.signer) {
        const signerContract = new wallet.signer.eth.Contract(abi, chain.contracts.iziswap, options);

        sign = {
            signerContract,
            swapExactETH: (amountETH: BN, targetRouter: Address, targetToken: Address, isTargetLiquidityToken: boolean, to: Address, origin: Address, deadline: number, targetTokenAmountOutMin: BN): PromiEvent<Contract> => wallet.track(signerContract.methods.swapExactETH(targetRouter, targetToken, isTargetLiquidityToken, to, origin, deadline, targetTokenAmountOutMin).send({ value: amountETH }), { name: "swapExactETH" }),
            swapExactTokens: (sourceRouter: Address, sourceToken: Address, isSourceLiquidityToken: boolean, sourceTokenAmount: BN, targetRouter: Address, targetToken: Address, isTargetLiquidityToken: boolean, to: Address, origin: Address, deadline: number, targetTokenAmountOutMin: BN): PromiEvent<Contract> => wallet.track(signerContract.methods.swapExactTokens(sourceRouter, sourceToken, isSourceLiquidityToken, sourceTokenAmount, targetRouter, targetToken, isTargetLiquidityToken, to, origin, deadline, targetTokenAmountOutMin).send(), { name: "swapExactTokens" })
        }
    }

    return { ...read, ...sign }
}

export default createIziswap;