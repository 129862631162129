import { useContext, useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";

import appSettings from "configuration";
import WalletContext from "contexts/wallet-context";

import { convert } from "services/convert";
import { createBlockReader, BlockReaderState } from "services/block-reader";
import { createTokens, NativeCoinState } from "services/weeb-finance/tokens";
import { createIziswap, IziswapState } from "services/iziswap";
import { createMoralis, MoralisState } from "services/moralis";
import { createTokenFinder, TokenFinderState } from "services/token-finder";
import { TokenState } from "services/erc20";
import { createPair, ReserveState } from "services/erc20/pair";
import { AnonymousWalletInfo, PersonalWalletInfo } from "services/shared.types";
import { createSettingsRepository, SettingsRepositoryState } from "services/settings-repository";
import { TokensState } from "services/weeb-finance/tokens";
import { createWeebToken, WeebTokenViewModel, WeebTokenState } from "services/weeb-finance/weeb-token";
import { createWeebGame, WeebGameState, WeebGameViewModel } from "services/weeb-finance/weeb-game";
import { createWeebFarm, WeebFarmLiquidatablesViewModel, WeebFarmPoolViewModel, WeebFarmState, WeebFarmViewModel } from "services/weeb-finance/weeb-farm";

import { MAX_UINT256 } from "extensions/web3-extensions";
import { getAmountOut } from "extensions/price-extensions";

import { WalletState } from "hooks/useWallet";

import { WeebToken, WeebGame, WeebFarm, WeebFarmLiquidatables, TokenCalculators } from "components/weeb-finance";
import { Spinner } from "components/shared";
import { AmountSelectionDialog, ApproveHarvesterDialog, FarmDepositDialog, GameBetDialog } from "components/shared/dialogs/weeb-finance";
import { Swap } from "components/swap";
import { ApproveRouterDialog, ModalDialog, SettingsDialog } from "components/shared/dialogs";
import { Transfer } from "components/transfer";
import { Toast } from "components/shared/toast";

export enum PageType {
    Token,
    Games,
    Farms,
    Liquidatables
}

type HomeProps = {
    blockTimeReadInterval: number;
    blockTimeLength: number;
    showPage: PageType;
}

type ShowDialogProps = {
    show: boolean;
    callback?: Function;
}

type ShowGameBetDialogProps = {
    show: boolean;
    gameId: number;
}

type ShowFarmDepositDialogProps = {
    show: boolean;
    pool?: WeebFarmPoolViewModel;
    symbol: string;
}

type ShowFarmWithdrawDialogProps = {
    show: boolean;
    callback?: Function;
    creditToken?: TokenState;
    credited: number;
    creditTokenPriceInStableToken: number;
    depositTokenSymbol: string;
}

const Main = ({
    blockTimeReadInterval = 10000,
    blockTimeLength = 10,
    showPage
}: Partial<HomeProps>) => {
    const wallet = useContext(WalletContext);

    const [settingsRepositoryService, setSettingsRepositoryService] = useState<SettingsRepositoryState>();
    const [blockReader, setBlockReader] = useState<BlockReaderState>();
    const [iziswap, setIziswap] = useState<IziswapState>();
    const [tokenFinder, setTokenFinder] = useState<TokenFinderState>();
    const [tokens, setTokens] = useState<TokensState>();
    const [weebTokenService, setWeebTokenService] = useState<WeebTokenState>();
    const [weebGameService, setWeebGameService] = useState<WeebGameState>();
    const [weebFarmService, setWeebFarmService] = useState<WeebFarmState>();
    const [moralis, setMoralis] = useState<MoralisState>();

    const [blockTime, setBlockTime] = useState<number>();

    const [weebToken, setWeebToken] = useState<WeebTokenViewModel>();
    const [weebTokenBalance, setWeebTokenBalance] = useState<number>();
    const [weebTokenReserves, setWeebTokenReserves] = useState<ReserveState>();
    const [weebTokenPriceInStableToken, setWeebTokenPriceInStableToken] = useState<number>();
    const [weebTokenPriceInNativeToken, setWeebTokenPriceInNativeToken] = useState<number>();
    const [nativeTokenPriceInStableToken, setNativeTokenPriceInStableToken] = useState<number>();
    const [nativeTokenPriceInWeebToken, setNativeTokenPriceInWeebToken] = useState<number>();

    const [weebGames, setWeebGames] = useState<WeebGameViewModel[]>();
    const [weebFarm, setWeebFarm] = useState<WeebFarmViewModel>();
    const [weebFarmLiquidatables, setWeebFarmLiquidatables] = useState<WeebFarmLiquidatablesViewModel>();

    const [navigateToGames, setNavigateToGames] = useState<boolean>(false);
    const [showGameApproveDialog, setShowGameApproveDialog] = useState<ShowDialogProps>({ show: false });
    const [showGameHarvestDialog, setShowGameHarvestDialog] = useState<boolean>(false);
    const [showGameBetDialog, setShowGameBetDialog] = useState<ShowGameBetDialogProps>({ show: false, gameId: 0 });
    const [showGameCompoundDialog, setShowGameCompoundDialog] = useState<ShowGameBetDialogProps>({ show: false, gameId: 0 });

    const [showFarmDepositDialog, setShowFarmDepositDialog] = useState<ShowFarmDepositDialogProps>({ show: false, symbol: "" });
    const [showFarmWithdrawDialog, setShowFarmWithdrawDialog] = useState<ShowFarmWithdrawDialogProps>({
        show: false,
        credited: 0,
        creditTokenPriceInStableToken: 0,
        depositTokenSymbol: "",
    });

    const [showRouterApproveDialog, setShowRouterApproveDialog] = useState<ShowDialogProps>({ show: false });

    const [showSettingsDialog, setShowSettingsDialog] = useState(false);
    const [showSwapDialog, setShowSwapDialog] = useState(false);

    const stateId = useRef<number>();
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const showWeebToken = showPage === PageType.Token;
    const loadWeebToken = showPage !== PageType.Liquidatables;
    const showWeebGames = showPage === PageType.Games;
    const loadWeebGames = showWeebGames;
    const showWeebFarms = showPage === PageType.Farms;
    const showWeebFarmLiquidatables = showPage === PageType.Liquidatables;
    const loadWeebFarms = showWeebFarms || showWeebFarmLiquidatables;

    const reduceWallet = (w: WalletState): AnonymousWalletInfo | PersonalWalletInfo => {
        return w.signer ? w as PersonalWalletInfo : w as AnonymousWalletInfo;
    }

    useEffect(() => {
        const _settingsRepositoryService = createSettingsRepository();
        setSettingsRepositoryService(_settingsRepositoryService);

        if (wallet && wallet.reader) {
            const _blockReader = createBlockReader(wallet.reader);
            const _iziswap = createIziswap(reduceWallet(wallet));
            const _tokenFinder = createTokenFinder(wallet as AnonymousWalletInfo);
            const _tokens = createTokens(reduceWallet(wallet), _iziswap);

            const _weebTokenService = loadWeebToken || showWeebFarmLiquidatables
                ? createWeebToken(reduceWallet(wallet), _iziswap)
                : undefined;

            const _weebGameService = loadWeebGames || showWeebToken
                ? createWeebGame(reduceWallet(wallet), _iziswap, _settingsRepositoryService)
                : undefined;

            const _weebFarmService = loadWeebFarms
                ? createWeebFarm(reduceWallet(wallet), _iziswap, _settingsRepositoryService)
                : undefined;

            setBlockReader(_blockReader);
            setIziswap(_iziswap);
            setTokenFinder(_tokenFinder);
            setTokens(_tokens);
            setWeebTokenService(_weebTokenService);
            setWeebGameService(_weebGameService);
            setWeebFarmService(_weebFarmService);
        } else {
            setBlockReader(undefined);
            setIziswap(undefined);
            setTokenFinder(undefined);
            setTokens(undefined);
            setWeebTokenService(undefined);
            setWeebGameService(undefined);
            setWeebFarmService(undefined);
        }

        if (wallet && wallet.account && wallet.chainId) {
            const _moralis = createMoralis(wallet.account, wallet.chainId);
            setMoralis(_moralis);
        } else {
            setMoralis(undefined);
        }
    }, [wallet?.reader, showPage]);

    useEffect(() => {
        if (!blockReader) {
            return;
        }

        const readBlockTime = () => {
            blockReader.getAverageBlockTime(blockTimeLength)
                .then(time => {
                    setBlockTime(Math.round(time * 1000));
                });
        }

        const timerId = setInterval(readBlockTime, blockTimeReadInterval);
        readBlockTime();

        return () => {
            clearInterval(timerId);
        };
    }, [blockReader]);

    useEffect(() => {
        if (!blockTime) {
            return;
        }

        const readState = () => {
            if (!wallet?.chainId || !tokens) {
                return;
            }

            if (stateId.current !== undefined) {
                //console.log(`Pending read state: ${stateId.current}`);
                return;
            }

            stateId.current = Date.now();
            //console.log(`New reading state: ${stateId.current}`);

            const chain = appSettings.chains[wallet.chainId];

            (async () => {
                wallet.setIsReadingState(true);

                let workers = 0;

                if (wallet.reader) {
                    //console.log("New batch request...");

                    var batch = new wallet.reader.BatchRequest();
                    {
                        const swapFee = chain.contracts.swapRouters[tokens.weebToken.router].fee.toRate();
                        const amountIn = 1 - swapFee;

                        const stableTokenNativeTokenPair = createPair({
                            reader: wallet.reader,
                            liquidityToken: chain.contracts.stableTokenNativeTokenPair,
                            token1: chain.contracts.stableToken.address,
                            decimals1: chain.contracts.stableToken.decimals,
                            token2: chain.contracts.nativeToken.address,
                            decimals2: chain.contracts.nativeToken.decimals
                        });

                        if (loadWeebToken) {
                            ++workers;
                            batch.add(tokens.weebToken.readerContract.methods.senderTokenSnapshot().call.request({},
                                (error: Error, result: WeebTokenViewModel) => {
                                    if (!error) {
                                        setWeebToken(result);
                                    } else {
                                        console.error(error);
                                    }

                                    --workers;
                                    //console.log(`WeebToken worker completed: ${workers}`);
                                }));

                            ++workers;
                            batch.add(tokens.weebToken.pair.readerContract.methods.getReserves().call.request({},
                                (error: Error, result: any) => {
                                    if (!error) {
                                        const isToken1First = tokens.weebToken.pair.token1.toBN().lt(tokens.weebToken.pair.token2.toBN());

                                        const reserves: ReserveState = isToken1First
                                            ?
                                            {
                                                token1Reserve: convert.fromWei(result.reserve0, tokens.weebToken.pair.decimals1),
                                                token2Reserve: convert.fromWei(result.reserve1, tokens.weebToken.pair.decimals2)
                                            }
                                            :
                                            {
                                                token1Reserve: convert.fromWei(result.reserve1, tokens.weebToken.pair.decimals1),
                                                token2Reserve: convert.fromWei(result.reserve0, tokens.weebToken.pair.decimals2)
                                            };

                                        setWeebTokenReserves(reserves);

                                        // 1 WETH = 100000 WEEB.
                                        const price = getAmountOut(amountIn, reserves.token2Reserve, reserves.token1Reserve);
                                        setNativeTokenPriceInWeebToken(price);
                                    } else {
                                        console.error(error);
                                    }

                                    --workers;
                                    //console.log(`WeebTokenReserves worker completed: ${workers}`);
                                }));

                            if (showWeebToken || showWeebFarms) {
                                ++workers;
                                batch.add(stableTokenNativeTokenPair.readerContract.methods.getReserves().call.request({},
                                    (error: Error, result: any) => {
                                        if (!error) {
                                            const isToken1First = stableTokenNativeTokenPair.token1.toBN().lt(stableTokenNativeTokenPair.token2.toBN());

                                            const reserves: ReserveState = isToken1First
                                                ?
                                                {
                                                    token1Reserve: convert.fromWei(result.reserve0, stableTokenNativeTokenPair.decimals1),
                                                    token2Reserve: convert.fromWei(result.reserve1, stableTokenNativeTokenPair.decimals2)
                                                }
                                                :
                                                {
                                                    token1Reserve: convert.fromWei(result.reserve1, stableTokenNativeTokenPair.decimals1),
                                                    token2Reserve: convert.fromWei(result.reserve0, stableTokenNativeTokenPair.decimals2)
                                                };

                                            // 1 WETH = 1500 USDT.
                                            const price = getAmountOut(amountIn, reserves.token2Reserve, reserves.token1Reserve);
                                            setNativeTokenPriceInStableToken(price);
                                        } else {
                                            console.error(error);
                                        }

                                        --workers;
                                        //console.log(`StableTokenNativeTokenPairReserves worker completed: ${workers}`);
                                    }));
                            }

                            if (iziswap) {
                                ++workers;
                                batch.add(iziswap.readerContract.methods.quote(tokens.weebToken.router, tokens.weebToken.token, tokens.weebToken.isLiquidityToken, tokens.weebToken.convert.toWei(1), chain.contracts.defaultSwapRouter, chain.contracts.stableToken.address, false).call.request({},
                                    (error: Error, result: any) => {
                                        if (!error) {
                                            const price = tokens.stableToken.convert.fromWei(result);
                                            setWeebTokenPriceInStableToken(price);
                                        } else {
                                            console.error(error);
                                        }

                                        --workers;
                                        //console.log(`WeebTokenPriceInStableToken worker completed: ${workers}`);
                                    }));

                                /*
                                if (loadWeebToken) {
                                    ++workers;
                                    batch.add(iziswap.readerContract.methods.quote(tokens.weebToken.router, tokens.weebToken.token, tokens.weebToken.isLiquidityToken, tokens.weebToken.convert.toWei(1), chain.contracts.defaultSwapRouter, chain.contracts.nativeToken.address, false).call.request({},
                                        (error: Error, result: any) => {
                                            if (!error) {
                                                const price = tokens.nativeToken.convert.fromWei(result);
                                                setWeebTokenPriceInNativeToken(price);
                                            } else {
                                                console.error(error);
                                            }
    
                                            --workers;
                                            //console.log(`WeebTokenPriceInNativeToken worker completed: ${workers}`);
                                        }));
                                }
                                */
                            }
                        }

                        if (loadWeebGames && weebGameService && showWeebGames) {
                            ++workers;
                            batch.add(weebGameService.readerContract.methods.senderGameSnapshots(1).call.request({},
                                (error: Error, result: WeebGameViewModel[]) => {
                                    if (!error) {
                                        setWeebGames(result);
                                    } else {
                                        console.error(error);
                                    }

                                    --workers;
                                    //console.log(`WeebGames worker completed: ${workers}`);
                                }));
                        }

                        if (loadWeebFarms && weebFarmService) {
                            // if (iziswap) {
                            //     ++workers;
                            //     batch.add(iziswap.readerContract.methods.quote(tokens.nativeToken.router, tokens.nativeToken.token, tokens.nativeToken.isLiquidityToken, tokens.nativeToken.convert.toWei(1), chain.contracts.defaultSwapRouter, chain.contracts.stableToken.address, false).call.request({},
                            //         (error: Error, result: any) => {
                            //             if (!error) {
                            //                 const price = tokens.stableToken.convert.fromWei(result);
                            //                 setNativeTokenPriceInStableToken(price);
                            //             } else {
                            //                 console.error(error);
                            //             }

                            //             --workers;
                            //             //console.log(`NativeTokenPriceInStableToken worker completed: ${workers}`);
                            //         }));
                            // }

                            if (showPage === PageType.Farms) {
                                ++workers;
                                batch.add(weebFarmService.readerContract.methods.senderFarmSnapshot(1).call.request({},
                                    (error: Error, result: WeebFarmViewModel) => {
                                        if (!error) {
                                            setWeebFarm(result);
                                        } else {
                                            console.error(error);
                                        }

                                        --workers;
                                        //console.log(`WeebFarm worker completed: ${workers}`);
                                    }));
                            } else if (showPage === PageType.Liquidatables) {
                                if (weebTokenService && wallet.account) {
                                    ++workers;
                                    batch.add(weebTokenService.readerContract.methods.balanceOf(wallet.account).call.request({},
                                        (error: Error, result: string) => {
                                            if (!error) {
                                                setWeebTokenBalance(tokens.weebToken.convert.fromWei(result));
                                            } else {
                                                console.error(error);
                                            }

                                            --workers;
                                            //console.log(`WeebToken balanceOf: ${workers}`);
                                        }));
                                }

                                ++workers;
                                batch.add(weebFarmService.readerContract.methods.senderFarmLiquidatablesSnapshot(1).call.request({},
                                    (error: Error, result: WeebFarmLiquidatablesViewModel) => {
                                        if (!error) {
                                            setWeebFarmLiquidatables(result);
                                        } else {
                                            console.error(error);
                                        }

                                        --workers;
                                        //console.log(`WeebFarmLiquidatables worker completed: ${workers}`);
                                    }));
                            }
                        }
                    }

                    batch.execute();

                    // All that worker tracking is done for the state reading indicator.
                    new Promise<boolean>(async (resolve, reject) => {
                        while (workers > 0) {
                            //console.log(`State: ${stateId.current} # of active workers: ${workers}`);
                            await sleep(1000);
                        }

                        resolve(true);
                    }).finally(() => {
                        stateId.current = undefined;
                        wallet.setIsReadingState(false);
                    });
                }

                // Promise.all([
                //     tokens.weebToken.senderTokenSnapshot(),
                //     tokens.weebToken.pair.getTokenReserves(),
                //     tokens.weebToken.price.priceInStableToken(chain.contracts.defaultSwapRouter),
                //     tokens.weebToken.price.priceInNativeToken(chain.contracts.defaultSwapRouter),
                //     weebFarmService ? tokens.nativeToken.price.priceInStableToken(chain.contracts.defaultSwapRouter) : Promise.resolve(undefined),
                //     weebGameService ? weebGameService.senderGameSnapshots(true) : Promise.resolve(undefined),
                //     weebFarmService ? weebFarmService.senderFarmSnapshot(true) : Promise.resolve(undefined),
                // ]).then(([
                //     weebSnapshot,
                //     weebTokenReserves,
                //     weebPriceInStableToken,
                //     weebPriceInNativeToken,
                //     nativeTokenPriceInStableToken,
                //     weebGameSnapshots,
                //     weebFarmSnapshot
                // ]: [
                //         WeebTokenViewModel,
                //         ReserveState,
                //         number,
                //         number,
                //         number | undefined,
                //         WeebGameViewModel[] | undefined,
                //         WeebFarmViewModel | undefined,
                //     ]
                // ) => {
                //     setWeebToken(weebSnapshot);
                //     setWeebTokenReserves(weebTokenReserves);
                //     setWeebTokenPriceInStableToken(weebPriceInStableToken);
                //     setWeebTokenPriceInNativeToken(weebPriceInNativeToken);
                //     setNativeTokenPriceInStableToken(nativeTokenPriceInStableToken);
                //     setWeebGames(weebGameSnapshots);
                //     setWeebFarm(weebFarmSnapshot);
                // }).finally(() => {
                //     stateId.current = undefined;
                //     wallet.setIsReadingState(false);
                // });
            })();
        }

        const timerId = setInterval(readState, blockTime);
        readState();

        return () => {
            clearInterval(timerId);
        };
    }, [blockTime, tokens]);

    useEffect(() => {
        if (navigateToGames) {
            setNavigateToGames(false);
        }
    }, [navigateToGames]);

    if (navigateToGames) {
        return (<Navigate to="/games" replace={true} />);
    }

    if (!wallet?.chainId || !blockTime || !tokens) {
        return null;
    }

    const print = (value: unknown) => (<pre>{JSON.stringify(value, undefined, 2)}</pre>);
    const chain = appSettings.chains[wallet.chainId];

    const harvestPendingReward = () => {
        if (!weebToken || !weebTokenService) {
            return;
        }

        if (weebToken.isDirectlyHarvestable) {
            if (weebTokenService.harvestPendingReward) {
                weebTokenService.harvestPendingReward();
            }
        } else {
            if (!weebGameService) {
                console.log("Game service is not initialized.");
                return;
            }

            setShowGameHarvestDialog(true);
        }
    }

    const harvestPendingRewardCallback = async (gameId: number, outcome: number) => {
        if (!wallet.account) {
            console.log("Wallet is not connected.");
            return;
        }

        if (!weebGameService || !weebGames || !weebTokenService || !weebToken) {
            console.log("Invalid harvest pending reward state.", { weebGameService, weebGames, weebTokenService, weebToken });
            return;
        }

        const game = weebGames.find(g => g.gameId.toInt() === gameId);

        if (!game) {
            console.log(`Game '${gameId}' does not exist.`);
            return;
        }

        console.assert(game.betToken.token === tokens.weebToken.token, "Game token must be WEEB!");

        if (!(await weebTokenService.game.isAllowanceSatisfied(wallet.account, weebToken.senderPendingReward.toBN()))) {
            setShowGameApproveDialog({
                show: true,
                callback: async () => {
                    await weebTokenService.game.approve();
                    await weebGameService.harvestPendingReward(gameId, outcome);

                    setNavigateToGames(true);
                }
            });
        } else {
            await weebGameService.harvestPendingReward(gameId, outcome);
            setNavigateToGames(true);
        }
    };

    const gameBet = (gameId: number) => {
        if (!weebGames || weebGames.length === 0) {
            console.log("No games found.");
            return;
        }

        setShowGameBetDialog({ show: true, gameId });
    };

    const gameBetCallback = async (gameId: number, selectedToken: TokenState | NativeCoinState | undefined, amount: number, outcome: number) => {
        if (!wallet.account) {
            console.log("Wallet is not connected.");
            return;
        }

        if (!wallet.balance) {
            console.log("Wallet balance is not set.");
            return;
        }

        if (!weebGameService || !weebGames || !selectedToken) {
            console.log("Invalid game bet state.", { weebGameService, weebGames, selectedToken });
            return;
        }

        const game = weebGames.find(g => g.gameId.toInt() === gameId);

        if (!game) {
            console.log(`Game '${gameId}' does not exist.`);
            return;
        }

        const betToken = weebGameService.createBetToken(game);
        const token = selectedToken as TokenState;

        if (token.contract !== undefined) {
            const senderBalanceInWei = await token.balanceOf(wallet.account);
            const senderBalance = token.convert.fromWei(senderBalanceInWei);
            const selectedTokenAmountInWei = amount >= senderBalance ? senderBalanceInWei.toBN() : token.convert.toWei(amount);

            if (!(await token.game.isAllowanceSatisfied(wallet.account, selectedTokenAmountInWei))) {
                setShowGameApproveDialog({
                    show: true,
                    callback: async () => {
                        await token.game.approve();

                        if (weebGameService.betToken) {
                            await weebGameService.betToken(gameId, token, selectedTokenAmountInWei, outcome, betToken);
                        }
                    }
                });
            } else {
                if (weebGameService.betToken) {
                    await weebGameService.betToken(gameId, token, selectedTokenAmountInWei, outcome, betToken);
                }
            }
        } else {
            const nativeCoin = selectedToken as NativeCoinState;
            const senderBalanceInWei = wallet.balance;
            const senderBalance = nativeCoin.convert.fromWei(senderBalanceInWei);
            const selectedTokenAmountInWei = amount >= senderBalance ? senderBalanceInWei.toBN() : nativeCoin.convert.toWei(amount);

            if (weebGameService.betETH) {
                await weebGameService.betETH(gameId, selectedTokenAmountInWei, outcome, betToken);
            }
        }
    }

    const gameCompound = (gameId: number) => {
        setShowGameCompoundDialog({ show: true, gameId });
    }

    const gameCompoundCallback = (gameId: number, outcome: number) => {
        if (!weebGameService) {
            console.log("Invalid game compound state.", { weebGameService });
            return;
        }

        if (weebGameService.compound) {
            return weebGameService.compound(gameId, outcome);
        }
    }

    const gameClaim = (gameId: number) => {
        if (!weebGameService) {
            console.log("Invalid game claim state.", { weebGameService });
            return;
        }

        if (weebGameService.claim) {
            return weebGameService.claim(gameId);
        }
    }

    const farmDeposit = (poolId: number) => {
        console.log("farmDeposit");

        if (!weebFarmService || !weebFarm || weebFarm.pools.length === 0) {
            console.log("Invalid farm deposit state.", { weebFarmService, weebFarm });
            return;
        }

        const pool = weebFarm.pools.find(p => p.poolId.toInt() === poolId);

        if (!pool) {
            console.log(`Farm pool '${poolId}' does not exist.`);
            return;
        }

        setShowFarmDepositDialog({
            show: true,
            symbol: weebFarmService.getDepositTokenSymbol(pool),
            pool
        });
    };

    const farmDepositCallback = async (poolId: number, selectedToken: TokenState | NativeCoinState | undefined, amount: number) => {
        if (!wallet.account) {
            console.log("Wallet is not connected.");
            return;
        }

        if (!wallet.balance) {
            console.log("Wallet balance is not set.");
            return;
        }

        if (!weebFarmService || !weebFarm || !selectedToken) {
            console.log("Invalid farm deposit state.", { weebFarmService, weebFarm, selectedToken });
            return;
        }

        const pool = weebFarm.pools.find(p => p.poolId.toInt() === poolId);

        if (!pool) {
            console.log(`Pool '${poolId}' does not exist.`);
            return;
        }

        const depositToken = weebFarmService.createDepositToken(pool);
        const token = selectedToken as TokenState;

        if (token.contract !== undefined) {
            const senderBalanceInWei = await token.balanceOf(wallet.account);
            const senderBalance = token.convert.fromWei(senderBalanceInWei);
            const selectedTokenAmountInWei = amount >= senderBalance ? senderBalanceInWei.toBN() : token.convert.toWei(amount);

            if (!(await token.farm.isAllowanceSatisfied(wallet.account, selectedTokenAmountInWei))) {
                setShowGameApproveDialog({
                    show: true,
                    callback: async () => {
                        await token.farm.approve();

                        if (weebFarmService.depositToken) {
                            await weebFarmService.depositToken(poolId, token, selectedTokenAmountInWei, depositToken);
                        }
                    }
                });
            } else {
                if (weebFarmService.depositToken) {
                    await weebFarmService.depositToken(poolId, token, selectedTokenAmountInWei, depositToken);
                }
            }
        } else {
            const nativeCoin = selectedToken as NativeCoinState;
            const senderBalanceInWei = wallet.balance;
            const senderBalance = nativeCoin.convert.fromWei(senderBalanceInWei);
            const selectedTokenAmountInWei = amount >= senderBalance ? senderBalanceInWei.toBN() : nativeCoin.convert.toWei(amount);

            if (weebFarmService.depositETH) {
                await weebFarmService.depositETH(poolId, selectedTokenAmountInWei, depositToken);
            }
        }
    }

    const farmWithdraw = async (poolId: number, creditTokenPriceInStableToken: number) => {
        if (!weebFarmService || !weebFarm) {
            console.log("Invalid farm deposit state.", { weebFarmService, weebFarm });
            return;
        }

        const pool = weebFarm.pools.find(p => p.poolId.toInt() === poolId);

        if (!pool) {
            console.log(`Pool '${poolId}' does not exist.`);
            return;
        }

        const creditToken = weebFarmService.createCreditToken(weebFarm);

        const callback = async (amount: number, convertToWeeb: boolean) => {
            const credited = creditToken.convert.fromWei(pool.senderPosition.credited);
            const amountInWei = amount >= credited
                ? pool.senderPosition.credited.toBN()
                : creditToken.convert.toWei(amount);

            if (convertToWeeb) {
                if (weebFarmService.withdrawToken) {
                    await weebFarmService.withdrawToken(poolId, amountInWei, tokens.weebToken);
                }
            } else {
                if (weebFarmService.withdraw) {
                    await weebFarmService.withdraw(poolId, amountInWei);
                }
            }
        };

        setShowFarmWithdrawDialog({
            show: true,
            callback,
            creditToken: creditToken,
            credited: creditToken.convert.fromWei(pool.senderPosition.credited),
            creditTokenPriceInStableToken,
            depositTokenSymbol: weebFarmService.getDepositTokenSymbol(pool),
        });
    }

    const farmRebalance = async (poolId: number) => {
        if (!weebFarmService) {
            console.log("Invalid farm rebalance state.", { weebFarmService });
            return;
        }

        if (weebFarmService.rebalance) {
            await weebFarmService.rebalance(poolId);
        }
    }

    const farmLiquidate = async (poolId: number, investor: string) => {
        if (!weebFarmService) {
            console.log("Invalid farm liquidate state.", { weebFarmService });
            return;
        }

        if (weebFarmService.liquidateAccount) {
            await weebFarmService.liquidateAccount(poolId, investor);
        }
    }

    const swap = async (isBuy: boolean, amount: number, recipient?: string) => {
        if (!wallet.account || !iziswap || !settingsRepositoryService) {
            console.log("Swap state is invalid.");
            return;
        }

        const to = recipient ? recipient : wallet.account;

        if (isBuy) {
            const maxReceived = await tokens.weebToken.quote.quoteFromNativeCoin(amount);
            const tolerance = (maxReceived * settingsRepositoryService.getSlippageTolerance()).toRate();
            const minReceived = tokens.weebToken.convert.toWei(maxReceived - tolerance);

            if (iziswap.swapExactETH) {
                const deadline = Math.floor((Date.now() / 1000) + settingsRepositoryService.getTransactionDeadline());
                console.log({
                    x: settingsRepositoryService.getTransactionDeadline(),
                    deadline
                });

                iziswap.swapExactETH(tokens.nativeCoin.convert.toWei(amount), tokens.weebToken.router, tokens.weebToken.token, tokens.weebToken.isLiquidityToken, to, wallet.account, deadline, minReceived);
            }
        } else {
            const trySwap = async (approve: boolean) => {
                if (!wallet.account || !iziswap || !settingsRepositoryService) {
                    console.log("Swap state is invalid.");
                    return;
                }

                const maxReceived = await tokens.weebToken.quote.quoteToNativeToken(amount);
                const tolerance = (maxReceived * settingsRepositoryService.getSlippageTolerance()).toRate();
                const minReceived = tokens.nativeToken.convert.toWei(maxReceived - tolerance);

                if (!(await tokens.weebToken.iziswap.isAllowanceSatisfied(wallet.account, tokens.weebToken.convert.toWei(amount)))) {
                    if (approve) {
                        await tokens.weebToken.iziswap.approve();
                    } else {
                        return false;
                    }
                }

                if (iziswap.swapExactTokens) {
                    const deadline = Math.floor((Date.now() / 1000) + settingsRepositoryService.getTransactionDeadline());
                    await iziswap.swapExactTokens(tokens.weebToken.router, tokens.weebToken.token, tokens.weebToken.isLiquidityToken, tokens.weebToken.convert.toWei(amount), tokens.nativeToken.router, tokens.nativeToken.token, tokens.nativeToken.isLiquidityToken, to, wallet.account, deadline, minReceived);
                }

                return true;
            };

            if (await trySwap(false)) {
                return;
            }

            setShowRouterApproveDialog({
                show: true,
                callback: () => trySwap(true)
            });
        }
    }

    const transfer = (recipient: string, amount: number) => {
        if (!weebTokenService || !weebToken) {
            return;
        }

        // Handle the special case transfer of WEEB.
        const senderFreeBalance = weebTokenService.convert.fromWei(weebToken.senderFreeBalance)
        const amountInWei = amount >= senderFreeBalance ? MAX_UINT256.toBN() : tokens.weebToken.convert.toWei(amount);

        if (weebTokenService.transfer) {
            weebTokenService.transfer(recipient, amountInWei);
        }
    }

    const buildGameCards = () => {
        if (weebGameService && weebGames && weebTokenPriceInStableToken) {
            return weebGames.map(game => {
                const gameId = game.gameId.toInt();

                return (
                    <div key={gameId} className="max-h-full">
                        <WeebGame
                            gameService={weebGameService}
                            tokens={tokens}
                            blockTime={blockTime / 1000}
                            chain={chain}
                            game={game}
                            priceInStableToken={weebTokenPriceInStableToken}
                            bet={() => gameBet(gameId)}
                            compound={() => gameCompound(gameId)}
                            claim={() => gameClaim(gameId)}
                        />
                        <hr className="border-0 h-[2px]" style={{ backgroundImage: "linear-gradient(to right, transparent, rgb(196 181 253), transparent)" }} />
                    </div>
                );
            });
        }
    }

    const spinner = <Spinner type="cloud" />;

    const tokenFragment = weebTokenService && loadWeebToken
        ? weebToken && weebTokenPriceInStableToken && nativeTokenPriceInWeebToken
            ?
            <WeebToken
                tokens={tokens}
                blockTime={blockTime / 1000}
                chain={chain}
                token={weebToken}
                reserves={weebTokenReserves}
                priceInStableToken={weebTokenPriceInStableToken}
                nativeTokenPriceInStableToken={nativeTokenPriceInStableToken}
                nativeTokenPriceInToken={nativeTokenPriceInWeebToken}
                showHeader={showWeebToken}
                showDetails={showWeebToken}
                showHarvestButton={showWeebToken || showWeebGames}
                harvestPendingReward={harvestPendingReward}
            />
            : spinner
        : undefined;

    let gamesFragment: JSX.Element | undefined;

    if (weebGameService && showWeebGames && !showWeebToken) {
        if (weebGames) {
            const gameCards = buildGameCards();

            if (gameCards) {
                gamesFragment = (
                    <div className="grid grid-cols-1 2xl:grid-cols-2 gap-x-5 gap-y-10">
                        {gameCards}
                    </div>
                );
            }
        }
        else {
            gamesFragment = spinner;
        }
    }

    const farmsFragment = weebFarmService && showWeebFarms
        ? weebFarm && weebTokenPriceInStableToken && nativeTokenPriceInStableToken
            ?
            <WeebFarm
                farmService={weebFarmService}
                tokens={tokens}
                blockTime={blockTime / 1000}
                chain={chain}
                farm={weebFarm}
                creditTokenPriceInStableToken={weebTokenPriceInStableToken}
                nativeTokenPriceInStableToken={nativeTokenPriceInStableToken}
                deposit={farmDeposit}
                withdraw={poolId => farmWithdraw(poolId, weebTokenPriceInStableToken)}
                rebalance={farmRebalance}
            />
            : spinner
        : undefined;
    const liquidatablesFragment = weebFarmService && showWeebFarmLiquidatables
        ? weebFarmLiquidatables
            ?
            <WeebFarmLiquidatables
                farmService={weebFarmService}
                chain={chain}
                senderCreditTokenBalance={weebTokenBalance ?? 0}
                farm={weebFarmLiquidatables}
                liquidate={farmLiquidate}
                buyCreditToken={() => setShowSwapDialog(true)}
            />
            : spinner
        : undefined;

    const swapFragment = showWeebToken && weebTokenService && weebToken && weebTokenReserves && settingsRepositoryService
        ?
        <Swap
            wallet={wallet as PersonalWalletInfo}
            settingsRepositoryService={settingsRepositoryService}
            chain={chain}
            tokens={tokens}
            tokenBalance={weebTokenService.convert.fromWei(weebToken.senderFreeBalance)}
            tokenReserves={weebTokenReserves}
            isDarkTheme={false}
            swap={swap}
            showSettingsDialog={setShowSettingsDialog}
        />
        : undefined;

    const transferFragment = showWeebToken && weebTokenService && weebToken
        ?
        <Transfer
            tokens={tokens}
            tokenFreeBalance={weebTokenService.convert.fromWei(weebToken.senderFreeBalance)}
            isDarkTheme={false}
            transfer={transfer}
        />
        : undefined;

    return (
        <main className="mt-3 grid grid-cols-1 gap-y-10 mx-auto">
            {tokenFragment}
            {gamesFragment}
            {farmsFragment}
            {liquidatablesFragment}

            {swapFragment != null &&
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-10">
                    <div className={`card bg-weeb-token/20 bg-diagonal-lines`}>
                        {swapFragment}
                    </div>
                    <div className={`card bg-weeb-token/20 bg-diagonal-lines`}>
                        {transferFragment}
                    </div>
                </div>
            }

            {showWeebToken && weebToken && weebTokenReserves && weebTokenPriceInStableToken && nativeTokenPriceInStableToken &&
                <div className="grid grid-cols-1 gap-y-5">
                    <div className="flex items-center justify-center group-title opacity-75 light-box h-9 px-2">
                        <span>Calculators</span>
                    </div>
                    <TokenCalculators
                        tokens={tokens}
                        token={weebToken}
                        reserves={weebTokenReserves}
                        priceInStableToken={weebTokenPriceInStableToken}
                        nativeTokenPriceInStableToken={nativeTokenPriceInStableToken}
                        blockTime={blockTime / 1000}
                    />
                </div>
            }

            {showGameApproveDialog.show &&
                <ApproveHarvesterDialog
                    isOpen={showGameApproveDialog.show}
                    setIsOpen={value => setShowGameApproveDialog(current => { return { ...current, show: value } })}
                    onSubmit={() => showGameApproveDialog.callback?.()}
                />
            }

            {showGameHarvestDialog && iziswap && moralis && tokenFinder && weebGameService &&
                <GameBetDialog
                    wallet={wallet as PersonalWalletInfo}
                    iziswap={iziswap}
                    moralis={moralis}
                    tokenFinder={tokenFinder}
                    tokens={tokens}
                    chain={chain}
                    gameService={weebGameService}
                    defaultGames={weebGames}
                    setGames={setWeebGames}
                    defaultGameId={0}
                    defaultOutcome={0}
                    enableGameSelection={true}
                    enableAmountEntry={false}
                    transferBurnRate={weebToken ? weebToken.transferBurnRate.toNumber().toRate() : 0}

                    isOpen={showGameHarvestDialog}
                    setIsOpen={setShowGameHarvestDialog}
                    onSubmit={(gameId: number, selectedToken: TokenState | NativeCoinState | undefined, amount: number, outcome: number) => harvestPendingRewardCallback(gameId, outcome)}
                    title="Harvest Pending Reward"
                    submitButtonText="Harvest"
                />
            }
            {showGameBetDialog.show && iziswap && moralis && tokenFinder && weebGameService &&
                <GameBetDialog
                    wallet={wallet as PersonalWalletInfo}
                    iziswap={iziswap}
                    moralis={moralis}
                    tokenFinder={tokenFinder}
                    tokens={tokens}
                    chain={chain}
                    gameService={weebGameService}
                    defaultGames={weebGames}
                    setGames={setWeebGames}
                    defaultGameId={showGameBetDialog.gameId}
                    defaultOutcome={0}
                    enableGameSelection={false}
                    enableAmountEntry={true}
                    transferBurnRate={weebToken ? weebToken.transferBurnRate.toNumber().toRate() : 0}

                    isOpen={showGameBetDialog.show}
                    setIsOpen={value => setShowGameBetDialog(current => { return { ...current, show: value } })}
                    onSubmit={(gameId: number, selectedToken: TokenState | NativeCoinState | undefined, amount: number, outcome: number) => gameBetCallback(gameId, selectedToken, amount, outcome)}
                    title="Place a Bet"
                    submitButtonText="Bet"
                />
            }
            {showGameCompoundDialog.show && iziswap && moralis && tokenFinder && weebGameService &&
                <GameBetDialog
                    wallet={wallet as PersonalWalletInfo}
                    iziswap={iziswap}
                    moralis={moralis}
                    tokenFinder={tokenFinder}
                    tokens={tokens}
                    chain={chain}
                    gameService={weebGameService}
                    defaultGames={weebGames}
                    setGames={setWeebGames}
                    defaultGameId={showGameCompoundDialog.gameId}
                    defaultOutcome={0}
                    enableGameSelection={false}
                    enableAmountEntry={false}
                    transferBurnRate={weebToken ? weebToken.transferBurnRate.toNumber().toRate() : 0}

                    isOpen={showGameCompoundDialog.show}
                    setIsOpen={value => setShowGameCompoundDialog(current => { return { ...current, show: value } })}
                    onSubmit={(gameId: number, selectedToken: TokenState | NativeCoinState | undefined, amount: number, outcome: number) => gameCompoundCallback(gameId, outcome)}
                    title="Bet the Prize"
                    submitButtonText="Bet"
                />
            }
            {(showFarmDepositDialog.show && showFarmDepositDialog.pool && iziswap && moralis && tokenFinder && weebFarmService) &&
                <FarmDepositDialog
                    wallet={wallet as PersonalWalletInfo}
                    iziswap={iziswap}
                    moralis={moralis}
                    tokenFinder={tokenFinder}
                    tokens={tokens}
                    chain={chain}
                    farmService={weebFarmService}
                    pool={showFarmDepositDialog.pool}

                    isOpen={showFarmDepositDialog.show}
                    setIsOpen={value => setShowFarmDepositDialog(current => { return { ...current, show: value } })}
                    onSubmit={(poolId: number, selectedToken: TokenState | NativeCoinState | undefined, amount: number) => farmDepositCallback(poolId, selectedToken, amount)}
                    title={`Deposit (${showFarmDepositDialog.symbol})`}
                    submitButtonText="Deposit"
                />
            }
            {showFarmWithdrawDialog.show && showFarmWithdrawDialog.creditToken &&
                <AmountSelectionDialog
                    tokens={tokens}
                    creditToken={showFarmWithdrawDialog.creditToken}
                    credited={showFarmWithdrawDialog.credited}
                    priceInStableToken={showFarmWithdrawDialog.creditTokenPriceInStableToken}
                    depositTokenSymbol={showFarmWithdrawDialog.depositTokenSymbol}

                    isOpen={showFarmWithdrawDialog.show}
                    setIsOpen={value => setShowFarmWithdrawDialog(current => { return { ...current, show: value } })}
                    title="Withdraw"
                    submitButtonText="Withdraw"
                    onSubmit={(amount: number, convertToWeeb: boolean) => showFarmWithdrawDialog.callback?.(amount, convertToWeeb)}
                />
            }

            {showRouterApproveDialog.show &&
                <ApproveRouterDialog
                    isOpen={showRouterApproveDialog.show}
                    setIsOpen={value => setShowRouterApproveDialog(current => { return { ...current, show: value } })}
                    onSubmit={() => showRouterApproveDialog.callback?.()}
                />
            }

            {showSettingsDialog && settingsRepositoryService &&
                <SettingsDialog
                    wallet={wallet as PersonalWalletInfo}
                    settingsRepositoryService={settingsRepositoryService}
                    chain={chain}
                    tokens={tokens}

                    isOpen={showSettingsDialog}
                    setIsOpen={setShowSettingsDialog}
                />
            }

            {showSwapDialog && weebTokenService && settingsRepositoryService &&
                <ModalDialog
                    isOpen={showSwapDialog}
                    setIsOpen={setShowSwapDialog}
                    disableBackgroundCancel={true}
                    body={(
                        <div className={`card bg-weeb-token/10`}>
                            <Swap
                                wallet={wallet as PersonalWalletInfo}
                                settingsRepositoryService={settingsRepositoryService}
                                chain={chain}
                                tokens={tokens}
                                tokenBalance={0}
                                tokenReserves={weebTokenReserves}
                                isDarkTheme={true}
                                swap={swap}
                                showSettingsDialog={setShowSettingsDialog}
                            />
                        </div>
                    )}
                    title={`Increase Balance`}
                    hideSubmitButton={true}
                    cancelButtonText="Close"
                />
            }

            <Toast
                blockExplorerLink={chain.blockExplorerLink}
                notificationItems={wallet.notificationItems}
                setNotificationItems={wallet.setNotificationItems}
            />
        </main >
    )
}

export default Main;
