import { useState } from "react";

import { getError } from "errors";

import { ChainInfo } from "configuration/types";
import { PriceBox, Spinner } from "components/shared";
import { StyledPopover } from "components/shared/dialogs";

import { MAX_BYTE } from "extensions/web3-extensions";
import { format } from "services/format";
import { TokensState } from "services/weeb-finance/tokens";
import { WeebGamePoolViewModel, WeebGameState, WeebGameViewModel } from "services/weeb-finance/weeb-game";
import { getOutcomeText } from "./weeb-game.helpers";
import {
    Activity,
    BetsRow,
    BetPool,
    isSenderUnderWin,
    isSenderWinner,
    getSenderBetPools,
    PopoverGameContent
} from "./weeb-game.fragments";
import { convert } from "services/convert";

type WeebGameProps = {
    gameService: WeebGameState,
    tokens: TokensState,
    blockTime: number,
    chain: ChainInfo;
    game: WeebGameViewModel;
    priceInStableToken: number;
    bet: () => void;
    compound: () => void;
    claim: () => void;
}

const WeebGame = ({
    gameService,
    tokens,
    blockTime,
    chain,
    game,
    priceInStableToken,
    bet,
    compound,
    claim,
}: WeebGameProps) => {
    const [isInteractionDisabled, setIsInteractionDisabled] = useState<boolean>(false);

    const [showLastBetPool, setShowLastBetPool] = useState<boolean>(false);
    const [showSenderLastBetPool, setShowSenderLastBetPool] = useState<boolean>(false);
    const [isPoolTotalsVisibile, setIsPoolTotalsVisibile] = useState<boolean>(false);
    const [showYourBetHistory, setShowYourBetHistory] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [senderBetPools, setSenderBetPools] = useState<WeebGamePoolViewModel[]>([]);

    const betTokenSymbol = gameService.getSymbol(game);
    const betToken = gameService.createBetToken(game);

    const now = Date.now() / 1000;
    const betTokenExplorerUri = `${chain.blockExplorerLink}/token/${game.betToken.token}`;
    const seignioryExplorerUri = `${chain.blockExplorerLink}/address/${game.seigniory}`;

    const outcomeCount = game.outcomeCount.toInt();
    const currentBlock = game.currentBlock.toInt();
    const senderBetPoolCount = game.senderBetPoolCount.toInt();

    const zero = "0".toBN();
    const senderUnclaimedPrizeBN = game.senderUnclaimedPrize.winningBet.toBN().add(game.senderUnclaimedPrize.prize.toBN());
    const isPrizeClaimable = senderUnclaimedPrizeBN.gt(zero);
    const senderUnclaimedPrize = betToken.convert.fromWei(senderUnclaimedPrizeBN);
    const currentBetPoolWinningPrize = betToken.convert.fromWei(game.currentBetPool.statement.senderPrize);
    const currentBetPoolPot = betToken.convert.fromWei(game.currentBetPool.statement.allAggregatedBets.total);
    const drawBlock = game.currentPoolId.toInt() + game.blockLength.toInt();
    const blocksToDraw = drawBlock - currentBlock;
    const timeToDraw = blocksToDraw * blockTime;

    const totalPlacedBets = betToken.convert.fromWei(game.totalPosition.placedBets.total);
    const totalClaimedBets = betToken.convert.fromWei(game.totalPosition.claimedBets.total);
    const totalClaimedPrizes = betToken.convert.fromWei(game.totalPosition.claimedPrizes.total);

    const senderAggregatedBets = betToken.convert.fromWei(game.currentBetPool.statement.senderAggregatedBets.total);
    const senderLosingBets = betToken.convert.fromWei(game.currentBetPool.statement.senderLosingBets.total);
    const senderPrize = betToken.convert.fromWei(game.currentBetPool.statement.senderPrize);

    const swapRouterName = chain.contracts.swapRouters[betToken.router].name;

    // Actions
    const toggleYourBetHistoryVisibility = async () => {
        const show = showYourBetHistory;
        setShowYourBetHistory(current => !current);

        if (!show) {
            await loadSenderBetPools();
        }
    }

    const loadSenderBetPools = async () => {
        setIsLoading(true);

        try {
            const betPools = await getSenderBetPools(gameService, game, senderBetPools, senderBetPoolCount, 3);

            if (betPools) {
                setSenderBetPools(betPools);
            }
        } catch (ex) {
            console.error(getError(ex));
        } finally {
            setIsLoading(false);
        }
    }

    const isCurrentBetPoolSenderUnderWin = isSenderUnderWin(betToken, game.currentBetPool.statement);

    const hasOpponent = isSenderWinner(game.currentBetPool) > 0;
    const senderPnL = hasOpponent ? (senderPrize - senderLosingBets) : senderAggregatedBets;

    return (
        <div className="grid grid-cols-1 gap-y-3">
            <div className={`card bg-weeb-game/25 bg-diagonal-lines`}>
                <div className="grid grid-cols-12 gap-x-2 gap-y-0.5">
                    <div className="col-span-4">
                        <span className="card-header-title-text opacity-75">
                            <span className="mr-1 font-semibold">{game.name}</span>
                            <StyledPopover
                                isTooltip={false}
                                children={(
                                    <PopoverGameContent
                                        betTokenSymbol={betTokenSymbol}
                                        betToken={betToken}
                                        game={game}
                                        tokens={tokens}
                                        betTokenExplorerUri={betTokenExplorerUri}
                                        priceInStableToken={priceInStableToken}
                                        seignioryExplorerUri={seignioryExplorerUri}
                                        swapRouterName={swapRouterName}
                                        blockTime={blockTime}
                                        now={now}
                                    />
                                )}
                            />
                        </span>
                    </div>

                    <div className="col-span-8 flex items-center justify-end card-header-item-text">
                        <span className="uppercase opacity-50">
                            <span className="2xs:hidden sm:block">
                                Time to Draw
                            </span>
                            <i className="sm:hidden bi bi-alarm" />
                        </span>

                        <span className="numeric opacity-75 ml-1 glow">
                            {format.formatTimespan(timeToDraw)}
                        </span>
                        <span className="2xs:hidden xs:block numeric opacity-75 ml-2">
                            {blocksToDraw}
                        </span>
                        <span className="2xs:hidden xs:block uppercase opacity-50 ml-1">
                            blocks
                        </span>
                    </div>

                    <div className="col-span-8 flex items-center justify-start card-header-item-text text-[0.8125rem]">
                        <span className="hidden sm:block opacity-25">
                            Draws every
                        </span>
                        <span className="hidden sm:block numeric opacity-50 ml-1">
                            {convert.formatInteger(game.blockLength.toInt())}
                        </span>
                        <span className="hidden sm:block opacity-25 ml-1 mr-2">
                            blocks.
                        </span>
                        <span className="uppercase opacity-50">
                            Pool Id
                        </span>
                        <span className="numeric opacity-25 ml-1">
                            {game.currentBetPool.poolId.toInt().format(0)}
                        </span>
                    </div>

                    <div className="col-span-4 flex items-center justify-end card-header-item-text text-[0.8125rem]">
                        <span className="numeric opacity-25">
                            <i className="bi bi-box mr-1" />
                            {currentBlock.format(0)}
                        </span>
                    </div>

                    <div className="col-span-12 mt-0.5 mb-3 border-bottom-t2 border-2"></div>
                </div>

                <div className="grid grid-cols-1 gap-y-3">
                    <div className="grid grid-cols-2 gap-x-2">
                        <PriceBox
                            title="Your Unclaimed Prize"
                            main={betToken.convert.formatCurrency(senderUnclaimedPrize)}
                            mainUnit={game.betToken.details.symbol}
                            detail={tokens.stableToken.convert.formatCurrency(senderUnclaimedPrize * priceInStableToken)}
                            detailUnit={tokens.stableToken.symbol}
                            mainClassName="glow"
                        />
                        <div className="grid grid-cols-2 gap-x-2 gap-y-3 leading-none auto-rows-auto text-[0.75em]">
                            <button
                                type="button"
                                className="w-full h-full justify-self-end self-end btn btn-green"
                                disabled={isInteractionDisabled || !isPrizeClaimable}
                                onClick={claim}
                            >
                                <span className="sm:hidden">Claim</span>
                                <span className="hidden sm:inline-block">Claim the Prize</span>
                            </button>
                            <button
                                type="button"
                                className="w-full h-full justify-self-start self-end btn btn-blue"
                                disabled={isInteractionDisabled || !isPrizeClaimable}
                                onClick={compound}
                            >
                                <span className="sm:hidden">Bet Prize</span>
                                <span className="hidden sm:inline-block">Bet the Prize</span>
                            </button>
                            <button
                                type="button"
                                className="w-full h-full justify-self-center self-start col-span-2 btn btn-red"
                                disabled={isInteractionDisabled /*|| balance === 0*/}
                                onClick={bet}
                            >
                                Place a Bet
                            </button>
                        </div>
                    </div>

                    <div className="border-bottom-t2"></div>

                    <div className="grid grid-cols-2 xl:grid-cols-4 gap-x-2 gap-y-4 text-[80%]">
                        <PriceBox
                            title="Winning Outcome"
                            main={getOutcomeText(outcomeCount, game.currentBetPool.outcome.toInt())}
                            detailUnit={game.currentBetPool.outcome.toInt() === MAX_BYTE
                                ? (<span className="animate-pulse">Place a Bet</span>)
                                : (<span className="animate-pulse">Bet to Change</span>)
                            }
                            titleClassName="light-box"
                            mainClassName="font-display neon"
                        />
                        <PriceBox
                            title="Pot"
                            main={betToken.convert.formatCurrency(currentBetPoolPot)}
                            mainUnit={game.betToken.details.symbol}
                            detail={tokens.stableToken.convert.formatCurrency(currentBetPoolPot * priceInStableToken)}
                            detailUnit={tokens.stableToken.symbol}
                            // info={(<span><i className="bi bi-app-indicator mr-1" />{game.currentBetPool.statement.allAggregatedBets.count.toInt().format(0)}</span>)}
                            titleClassName="light-box"
                        />

                        <PriceBox
                            title="Potential Prize"
                            main={betToken.convert.formatCurrency(currentBetPoolWinningPrize)}
                            mainUnit={game.betToken.details.symbol}
                            detail={tokens.stableToken.convert.formatCurrency(currentBetPoolWinningPrize * priceInStableToken)}
                            detailUnit={tokens.stableToken.symbol}
                            info={isCurrentBetPoolSenderUnderWin ? "Under Win" : null}
                            titleClassName="light-box"
                            mainClassName="neon"
                            infoClassName={`glow ${isCurrentBetPoolSenderUnderWin ? " blinker" : null}}`}
                        />
                        <PriceBox
                            title="PnL"
                            main={betToken.convert.formatCurrency(senderPnL)}
                            mainUnit={game.betToken.details.symbol}
                            detail={tokens.stableToken.convert.formatCurrency(senderPnL * priceInStableToken)}
                            detailUnit={tokens.stableToken.symbol}
                            titleClassName="light-box"
                            mainClassName="glow"
                        />

                        <div className="col-span-2 xl:col-span-4 mt-2 text-[90%]">
                            <BetsRow
                                outcomeCount={outcomeCount}
                                tokens={tokens}
                                betToken={betToken}
                                betPool={game.currentBetPool}
                                priceInStableToken={priceInStableToken}
                            />
                        </div>
                    </div>

                    <div className="border-bottom-t2"></div>

                    <div className="grid grid-cols-2 gap-x-2 text-[0.75em]">
                        <button
                            type="button"
                            className="w-full h-9 btn btn-dark"
                            disabled={game.lastBetPool.poolId.toInt() === 0}
                            onClick={() => setShowLastBetPool(current => !current)}
                        >
                            Last Bet Pool{showLastBetPool && <i className="bi bi-caret-down-fill ml-1" />}
                        </button>
                        <button
                            type="button"
                            className="w-full h-9 btn btn-dark"
                            disabled={game.senderLastBetPool.poolId.toInt() === 0}
                            onClick={() => setShowSenderLastBetPool(current => !current)}
                        >
                            Your Last Bet Pool{showSenderLastBetPool && <i className="bi bi-caret-down-fill ml-1" />}
                        </button>
                    </div>

                    <div className="border-bottom-t2"></div>

                    <div className="flex items-center justify-between">
                        <span
                            className="card-header-item-text uppercase opacity-75 cursor-pointer"
                            onClick={() => setIsPoolTotalsVisibile(current => !current)}
                        >
                            <i className={`bi ${(isPoolTotalsVisibile ? "bi-caret-down-fill" : "bi-caret-right-fill")} mr-1`} />
                            <span className="inline-block hover:animate-wiggle">
                                Pool Totals
                            </span>
                        </span>
                        <span
                            className={`card-header-item-text opacity-25 ${senderBetPoolCount === 0 ? "disabled" : "cursor-pointer"}`}
                            onClick={() => {
                                if (senderBetPoolCount > 0) {
                                    toggleYourBetHistoryVisibility();
                                }
                            }}
                        >
                            <i className="bi bi-calendar3 mr-1" />
                            Your Bet History (<span className="numeric">{senderBetPoolCount.format(0)}</span>)
                        </span>
                    </div>

                    {isPoolTotalsVisibile &&
                        <div className="grid grid-cols-3 gap-x-2 text-[80%]">
                            <PriceBox
                                title="Placed Bets"
                                main={betToken.convert.formatCurrency(totalPlacedBets)}
                                mainUnit={game.betToken.details.symbol}
                                detail={tokens.stableToken.convert.formatCurrency(totalPlacedBets * priceInStableToken)}
                                detailUnit={tokens.stableToken.symbol}
                                info={(
                                    <Activity
                                        count={game.totalPosition.placedBets.count.toInt()}
                                        timestamp={game.totalPosition.placedBets.timestamp.toInt()}
                                        now={now}
                                    />
                                )}
                            />
                            <PriceBox
                                title="Claimed Bets"
                                main={betToken.convert.formatCurrency(totalClaimedBets)}
                                mainUnit={game.betToken.details.symbol}
                                detail={tokens.stableToken.convert.formatCurrency(totalClaimedBets * priceInStableToken)}
                                detailUnit={tokens.stableToken.symbol}
                                info={(
                                    <Activity
                                        count={game.totalPosition.claimedBets.count.toInt()}
                                        timestamp={game.totalPosition.claimedBets.timestamp.toInt()}
                                        now={now}
                                    />
                                )}
                            />
                            <PriceBox
                                title="Claimed Prizes"
                                main={betToken.convert.formatCurrency(totalClaimedPrizes)}
                                mainUnit={game.betToken.details.symbol}
                                detail={tokens.stableToken.convert.formatCurrency(totalClaimedPrizes * priceInStableToken)}
                                detailUnit={tokens.stableToken.symbol}
                                info={(
                                    <Activity
                                        count={game.totalPosition.claimedPrizes.count.toInt()}
                                        timestamp={game.totalPosition.claimedPrizes.timestamp.toInt()}
                                        now={now}
                                    />
                                )}
                            />
                        </div>
                    }
                </div>
            </div>

            {showLastBetPool &&
                <BetPool
                    outcomeCount={outcomeCount}
                    tokens={tokens}
                    betToken={betToken}
                    betPool={game.lastBetPool}
                    priceInStableToken={priceInStableToken}
                    title="Last Bet Pool"
                    blockTime={blockTime}
                    currentBlock={currentBlock}
                />
            }
            {showSenderLastBetPool &&
                <BetPool
                    outcomeCount={outcomeCount}
                    tokens={tokens}
                    betToken={betToken}
                    betPool={game.senderLastBetPool}
                    priceInStableToken={priceInStableToken}
                    title="Your Last Bet Pool"
                    blockTime={blockTime}
                    currentBlock={currentBlock}
                />
            }
            {showYourBetHistory &&
                <>
                    {senderBetPools.map((betPool, i) => (
                        <div key={betPool.poolId}>
                            <BetPool
                                outcomeCount={outcomeCount}
                                tokens={tokens}
                                betToken={betToken}
                                betPool={betPool}
                                priceInStableToken={priceInStableToken}
                                title={`Your Bet Pool #${i + 1}`}
                                blockTime={blockTime}
                                currentBlock={currentBlock}
                            />
                        </div>
                    ))}
                    <div className="flex items-center justify-center text-[0.75em] mb-3">
                        <button
                            type="button"
                            className="w-48 h-9 btn btn-dark"
                            disabled={isLoading || senderBetPools.length === senderBetPoolCount}
                            onClick={_ => loadSenderBetPools()}
                        >
                            Load more
                        </button>
                    </div>
                    {isLoading &&
                        <Spinner
                            type="cloud"
                            title={`Loading ${senderBetPoolCount.format(0)} pools.`}
                        />
                    }
                </>
            }
        </div>
    );
}

export default WeebGame;