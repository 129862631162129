import { useState } from "react";

import { getError } from "errors";

import appSettings from "configuration";
import { ChainInfo } from "configuration/types";

import { format } from "services/format";
import { ReserveState } from "services/erc20";
import { TokensState } from "services/weeb-finance/tokens";
import { WeebTokenViewModel } from "services/weeb-finance/weeb-token";

import { PriceBox } from "components/shared";
import { StyledPopover } from "components/shared/dialogs";

import {
    PercentageYieldView,
    PopoverSenderBalanceContent,
    SummaryView,
    SupplyView,
    EmissionView,
    LiquidityAndOthersView,
    TotalsView
} from "./weeb-token.fragments";

type WeebTokenProps = {
    tokens: TokensState,
    blockTime: number,
    chain: ChainInfo;
    token: WeebTokenViewModel;
    reserves?: ReserveState;
    priceInStableToken: number;
    nativeTokenPriceInStableToken?: number;
    nativeTokenPriceInToken: number;
    showHeader: boolean;
    showDetails: boolean;
    showHarvestButton: boolean;
    harvestPendingReward: () => void;
}

const WeebToken = ({
    tokens,
    blockTime,
    chain,
    token,
    reserves,
    priceInStableToken,
    nativeTokenPriceInStableToken,
    nativeTokenPriceInToken,
    showHeader,
    showDetails,
    showHarvestButton,
    harvestPendingReward
}: WeebTokenProps) => {
    const [isInteractionDisabled, setIsInteractionDisabled] = useState<boolean>(false);
    const [isDetailed, setIsDetailed] = useState<boolean>(false);

    const now = Date.now() / 1000;
    const tokenExplorerUri = `${chain.blockExplorerLink}/token/${chain.contracts.weebToken.address}`;
    const seignioryExplorerUri = `${chain.blockExplorerLink}/address/${token.seigniory}`;

    const senderBalance = tokens.weebToken.convert.fromWei(token.senderBalance);
    const senderPendingReward = tokens.weebToken.convert.fromWei(token.senderPendingReward);

    const lastHarvest = token.senderTotalHarvested.timestamp.toInt() === 0 ? "-" : format.formatTimespan(now - token.senderTotalHarvested.timestamp.toInt());
    const swapRouterName = chain.contracts.swapRouters[chain.contracts.defaultSwapRouter].name;

    return (
        <div className="grid grid-cols-1 gap-y-2">
            <div className={`card bg-weeb-token/60 bg-diagonal-lines`}>
                {showHeader &&
                    <>
                        <div className="grid grid-cols-1 gap-x-2 gap-y-0.5">
                            <div className="flex items-center justify-between">
                                <span
                                    className="card-header-title-text opacity-75 cursor-pointer"
                                    onClick={() => setIsDetailed(current => !current)}
                                >
                                    <i className={`bi ${(isDetailed ? "bi-caret-down-fill" : "bi-caret-right-fill")} mr-1`} />
                                    <span className="font-semibold inline-block hover:animate-wiggle">{token.details.name}</span>
                                </span>
                                <div className="card-header-item-text truncate opacity-25 2xs:ml-4">
                                    <i className="bi bi-clipboard mr-1 cursor-pointer inline-block active:animate-ping"
                                        onClick={_ => {
                                            navigator.clipboard.writeText(chain.contracts.weebToken.address)
                                                .catch(ex => console.error(getError(ex)));
                                        }}
                                    />
                                    <a href={tokenExplorerUri} rel="noreferrer" target="_blank" className="numeric">
                                        {chain.contracts.weebToken.address}
                                    </a>
                                </div>
                            </div>

                            <div className="flex items-center justify-between card-header-item-text">
                                <span className="opacity-25">{swapRouterName}</span>
                                <span className="numeric opacity-25">
                                    <i className="bi bi-box mr-1" />
                                    {token.currentBlock.toInt().format(0)}
                                </span>
                            </div>

                            <div className={`mt-0.5 mb-3 border-bottom-t1 border-2`}></div>
                        </div>
                    </>
                }
                {/* Main View*/}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-x-2 gap-y-6">
                    <PriceBox
                        title="Price"
                        main={tokens.stableToken.convert.formatCurrency(priceInStableToken)}
                        mainUnit={chain.contracts.stableToken.symbol}
                        detail={tokens.weebToken.convert.formatCurrency(nativeTokenPriceInToken)}
                        detailUnit={`${token.details.symbol}/${tokens.nativeToken.symbol}`}
                    />
                    <PriceBox
                        title={(<>
                            <span className="mr-1">Balance</span>
                            <StyledPopover
                                isTooltip={false}
                                children={
                                    <PopoverSenderBalanceContent
                                        weebToken={tokens.weebToken}
                                        stableToken={tokens.stableToken}
                                        token={token}
                                        priceInStableToken={priceInStableToken}
                                    />
                                }
                            />
                        </>)}
                        main={tokens.weebToken.convert.formatCurrency(senderBalance)}
                        mainUnit={token.details.symbol}
                        detail={tokens.weebToken.convert.formatCurrency(senderBalance * priceInStableToken)}
                        detailUnit={chain.contracts.stableToken.symbol}
                    />
                    <PriceBox
                        title="Pending Reward"
                        main={tokens.weebToken.convert.formatCurrency(senderPendingReward, 4, 4)}
                        mainUnit={token.details.symbol}
                        detail={token.isSenderNonrewardable ? "Nonrewardable" : tokens.stableToken.convert.formatCurrency(senderPendingReward * priceInStableToken, 2, 2)}
                        detailUnit={token.isSenderNonrewardable ? null : chain.contracts.stableToken.symbol}
                        mainClassName="neon"
                        detailClassName={`${token.isSenderNonrewardable ? "font-display uppercase text-red-500" : ""}`}
                    />
                    <PriceBox
                        containerClassName="auto-rows-auto"
                        title={(
                            <>
                                Last Harvest
                                {token.isDirectHarvestingEnabled
                                    ? (<i className="bi bi-lightning-charge-fill text-green-500" />)
                                    : null}
                                {token.isSenderDirectHarvester
                                    ? (<i className="bi bi-lightning-charge-fill text-red-500" />)
                                    : null}
                            </>
                        )}
                        main={showHarvestButton && lastHarvest}
                        info={!showHarvestButton && lastHarvest}
                        detail={(
                            <>
                                {showHarvestButton &&
                                    <div className="sm:container font-display mt-1">
                                        <button
                                            autoFocus={true}
                                            type="button"
                                            className="w-full h-9 btn btn-green"
                                            disabled={isInteractionDisabled || senderPendingReward === 0}
                                            onClick={harvestPendingReward}
                                        >
                                            Harvest the Reward
                                        </button>
                                    </div>
                                }
                            </>
                        )}
                        mainClassName={showHarvestButton ? "text-[0.875em]" : ""}
                        infoClassName={!showHarvestButton ? "text-[1.4em] self-end" : ""}
                    />
                </div>
            </div>

            {(!isDetailed && showDetails) &&
                <div className={`card bg-weeb-token/50 bg-diagonal-lines`}>
                    <SummaryView
                        weebToken={tokens.weebToken}
                        stableToken={tokens.stableToken}
                        token={token}
                        priceInStableToken={priceInStableToken}
                        blockTime={blockTime}
                    />
                </div>
            }

            {(showHeader && isDetailed) &&
                <>
                    <div className={`card bg-weeb-token/40 bg-diagonal-lines`}>
                        <SupplyView
                            weebToken={tokens.weebToken}
                            stableToken={tokens.stableToken}
                            token={token}
                            priceInStableToken={priceInStableToken}
                        />
                    </div>

                    <div className={`card bg-weeb-token/40 bg-diagonal-lines`}>
                        <EmissionView
                            weebToken={tokens.weebToken}
                            stableToken={tokens.stableToken}
                            nativeToken={tokens.nativeToken}
                            token={token}
                            priceInStableToken={priceInStableToken}
                            nativeTokenPriceInStableToken={nativeTokenPriceInStableToken}
                            blockTime={blockTime}
                            now={now}
                        />
                    </div>

                    <div className={`card bg-weeb-token/40 bg-diagonal-lines`}>
                        <PercentageYieldView
                            weebToken={tokens.weebToken}
                            stableToken={tokens.stableToken}
                            token={token}
                            priceInStableToken={priceInStableToken}
                            blockTime={blockTime}
                            investmentInStablecoin={appSettings.percentageYieldInvestmentInStablecoin}
                        />
                    </div>

                    <div className={`card bg-weeb-token/40 bg-diagonal-lines`}>
                        <LiquidityAndOthersView
                            weebToken={tokens.weebToken}
                            nativeToken={tokens.nativeToken}
                            token={token}
                            reserves={reserves}
                            initialLiquidity={chain.initialLiquidity}
                            seignioryExplorerUri={seignioryExplorerUri}
                        />
                    </div>

                    <div className={`card bg-weeb-token/40 bg-diagonal-lines`}>
                        <TotalsView
                            weebToken={tokens.weebToken}
                            stableToken={tokens.stableToken}
                            token={token}
                            priceInStableToken={priceInStableToken}
                            now={now}
                        />
                    </div>
                </>
            }
        </div>
    );
}

export default WeebToken