import Web3 from "web3";
import BN from "bn.js";

import { format } from "services/format";

import { fromWei, toWei } from "./web3-convert";
import { ConvertState, CreateConverterState, GenericConvertState } from "./convert.types";

const convert = (): ConvertState => {
    const trimToDecimals = (value: number | string, decimals: number) => {
        if (typeof value === "number") {
            return parseFloat(value.toFixed(decimals));
        }

        const index = value.indexOf(".");

        if (index !== -1) {
            const count = value.length - (index + 1) - decimals;

            if (count > 0) {
                value = value.substr(0, value.length - count);
            }
        }

        return parseFloat(value);
    };

    const convert: GenericConvertState = {
        fromWei: (value: BN | string, decimals: number) => parseFloat(fromWei(value, decimals)),
        toWei: (value: number | string, decimals: number) => toWei(trimToDecimals(value, decimals), decimals),
        toBN: (value: number | string) => Web3.utils.toBN(value),
    };

    const createConverter = (decimals: number): CreateConverterState => {
        return {
            toWei: (value: number | string) => convert.toWei(value, decimals),
            fromWei: (value: BN | string) => convert.fromWei(value, decimals),
            formatCurrency: (number: number, maxDecimals?: number, minDecimals?: number) => format.formatCurrency(number, decimals, maxDecimals, minDecimals),
        };
    };

    return {
        ...format,
        ...convert,
        createConverter
    };
}

export default convert();