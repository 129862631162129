import { ModalDialog } from "components/shared/dialogs";

interface ApproveHarvesterDialogProps {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    onCancel?: Function,
    onSubmit: Function,
}

const ApproveHarvesterDialog = ({
    isOpen,
    setIsOpen,
    onCancel,
    onSubmit
}: ApproveHarvesterDialogProps) => {
    return (
        <ModalDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disableBackgroundCancel={false}
            body={(
                <>
                    <p>Harvesting of rewards is performed thru the game contract. A percentage of your pendig reward goes to a game of your choice and the rest is added to your balance.</p>
                    <p>The game contract needs to be approved <u>once</u>.</p>
                    <p>Action will resume after approval.</p>
                </>
            )}
            title="Approve the Harvester"
            submitButtonText="Approve"
            onCancel={onCancel}
            onSubmit={onSubmit}
        />
    )
}

export default ApproveHarvesterDialog;