import { useEffect, useState, useTransition } from "react";
import { StringLiteral } from "typescript";

type DeferredInputProps = {
    type: string;
    autoComplete: string;
    id: string;
    className: string;
    defaultValue: string | number | undefined;
    placeholder: string;
    list: string;
    min: string | number;
    max: string | number;
    step: string | number;
    onInput: (value: string | undefined) => void;
}

const DeferredInput = ({
    type = "search",
    autoComplete = "off",
    id,
    className,
    defaultValue,
    placeholder = "Search here...",
    list,
    min,
    max,
    step,
    onInput
}: Partial<DeferredInputProps>) => {
    const [isPending, startTransition] = useTransition();
    const [input, setInput] = useState<string>();

    useEffect(() => {
        setInput(typeof defaultValue === "number" ? defaultValue.toString() : defaultValue);
    }, [defaultValue]);

    // useEffect(() => {
    //     startTransition(() => {
    //         onInput?.(input);
    //     });
    // }, [input]);

    useEffect(() => {
        const timerId = setTimeout(() => onInput?.(input), 400);
        return () => clearTimeout(timerId);
    }, [input]);

    const getInputMode = (type: string) => {
        // 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
        switch (type) {
            case "number":
                return "decimal";
            case "tel":
                return "tel";
            case "search":
                return "search";
            case "email":
                return "email";
            case "url":
                return "url";
            default:
                return "text";
        }
    }

    return (
        <input
            type={type}
            inputMode={getInputMode(type)}
            autoComplete={autoComplete}
            id={id}
            className={`${className} ${isPending ? "animate-pulse" : ""}`}
            // defaultValue={defaultValue}
            value={input ?? ""}
            placeholder={placeholder}
            list={list}
            min={min}
            max={max}
            step={step}
            onChange={e => setInput(e.target.value)}
        />
    )
}

export default DeferredInput;