import Web3 from "web3";
import { provider } from "web3-core/types";

import * as errors from "errors";

export const MAX_UINT256: string = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
export const MAX_BYTE = 255;

declare global {
    interface Window {
        ethereum: {
            request<T>(params: { method: string }): Promise<T>;
            on<T>(event: string, cb: (params: T) => void): void;
            removeListener<T>(event: string, cb: (params: T) => void): void;
            selectedChainId: number | undefined;
            selectedAddress: string | undefined;
        };
    }
}

export interface EthereumChain {
    chainId: number,
    chainName: string,
    nativeCurrencyName?: string,
    nativeCurrencySymbol: string,
    nativeCurrencyDecimals: number,
    rpcUrls: string[],
    blockExplorerUrls?: string[],
    iconUrls?: string[]
}

export interface WatchAssetParameters {
    type: string; // The asset's interface, e.g. 'ERC20'
    options: {
        address: string; // The hexadecimal Ethereum address of the token contract
        symbol?: string; // A ticker symbol or shorthand, up to 5 alphanumerical characters
        decimals?: number; // The number of asset decimals
        image?: string; // A string url of the token logo
    };
}

export class Web3Extensions {
    static createWeb3(provider: provider, defaultAccount?: string): Web3 {
        const web3 = new Web3(provider);

        web3.defaultAccount = defaultAccount ?? "0x0000000000000000000000000000000000000000"; /* Fix for hardhat which keeps returning data of previous account when a request comes from an empty account. */
        web3.eth.extend({
            methods: [
                {
                    name: "chainId",
                    call: "eth_chainId",
                    //outputFormatter: web3.utils.hexToNumber
                }
            ]
        });

        return web3;
    }

    static async switchOrAddEthereumChain(provider: provider, chain: EthereumChain) {
        if (!provider) {
            console.log("Provider not set.");
            return;
        }

        console.log(`Switching to chain: ${chain.chainId}`);

        try {
            await (provider as any).request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: Web3.utils.numberToHex(chain.chainId) }],
            });
        } catch (switchError: any) {
            if (switchError.code === 4902/*For desktop MM*/ || switchError.code === -32603/*For iPhone MM*/) {
                console.log(`Non-existent chain: ${chain.chainId}. Trying to add.`);

                try {
                    await this.addEthereumChain(provider, chain);
                } catch (addError: any) {
                    throw errors.getAddEthereumChainError(chain.chainId, addError);
                }
            } else {
                throw errors.getSwitchEthereumChainError(chain.chainId, switchError);
            }
        }
    }

    static async addEthereumChain(provider: provider, chain: EthereumChain) {
        if (!provider) {
            console.log("Provider not set.");
            return;
        }

        const p = {
            chainId: Web3.utils.numberToHex(chain.chainId),
            chainName: chain.chainName,
            nativeCurrency: {
                name: chain.nativeCurrencyName,
                symbol: chain.nativeCurrencySymbol,
                decimals: chain.nativeCurrencyDecimals
            },
            rpcUrls: chain.rpcUrls,
            blockExplorerUrls: chain.blockExplorerUrls,
            iconUrls: chain.iconUrls
        };

        // Returns null - The method returns null if the request was successful, and an error otherwise.
        return (provider as any).request({ method: "wallet_addEthereumChain", params: [p] });
    }

    // https://github.com/ethereum/EIPs/blob/master/EIPS/eip-747.md
    static async watchAsset(provider: provider, asset: WatchAssetParameters) {
        try {
            return await (provider as any).request({ method: "wallet_watchAsset", params: asset });
        } catch (watchError: any) {
            throw errors.getError(watchError);
        }
    }
}
