import appSettings from "configuration";

import { AnonymousWalletInfo } from "services/shared.types";
import { IziswapState } from "services/iziswap";
import { createToken, createPair, ReserveState } from "services/erc20";
import { convert } from "services/convert";
import { TokensState } from "./tokens.types";

import { createWeebToken } from "../weeb-token";

const createTokens = (wallet: AnonymousWalletInfo, iziswap: IziswapState): TokensState => {
    const chain = appSettings.chains[wallet.chainId];

    const self = {
        nativeCoin: {
            decimals: chain.nativeCoin.decimals,
            symbol: chain.nativeCoin.symbol,
            convert: {
                ...convert.createConverter(chain.nativeCoin.decimals),
            },
        },
        nativeToken: {
            ...createToken({
                wallet,
                iziswap,
                router: chain.contracts.defaultSwapRouter,
                token: chain.contracts.nativeToken.address,
                decimals: chain.contracts.nativeToken.decimals,
                isLiquidityToken: false,
                symbol: chain.contracts.nativeToken.symbol
            }),
        },
        stableToken: {
            ...createToken({
                wallet,
                iziswap,
                router: chain.contracts.defaultSwapRouter,
                token: chain.contracts.stableToken.address,
                decimals: chain.contracts.stableToken.decimals,
                isLiquidityToken: false,
                symbol: chain.contracts.stableToken.symbol
            }),
        },
        weebToken: {
            ...createWeebToken(wallet, iziswap),
            pair: createPair({
                reader: wallet.reader,
                liquidityToken: chain.contracts.weebTokenNativeTokenPair,
                token1: chain.contracts.weebToken.address,
                decimals1: chain.contracts.weebToken.decimals,
                token2: chain.contracts.nativeToken.address,
                decimals2: chain.contracts.nativeToken.decimals
            }),
            nativeTokenCurrentReserves: () => self.weebToken.pair.getTokenReserves(),
            nativeTokenInitialReserves: (): ReserveState => {
                const reserves = chain.initialLiquidity;

                return {
                    token1Reserve: self.weebToken.convert.fromWei(reserves.reserveWeebToken),
                    token2Reserve: self.nativeToken.convert.fromWei(reserves.reserveNativeToken)
                };
            },
        },
    }

    return self;
}

export default createTokens;