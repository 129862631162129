import { useEffect, useState, useTransition } from "react";
import { Switch } from "@headlessui/react";

import Web3 from "web3";

import { ChainInfo } from "configuration/types";

import { DeferredInput, MainText, PriceBox, RangeInput } from "components/shared";
import { PersonalWalletInfo } from "services/shared.types";
import { SettingsRepositoryState } from "services/settings-repository";
import { TokensState } from "services/weeb-finance/tokens";
import { useStateDeferred } from "hooks/useStateDeferred";
import { getAmountOut } from "extensions/price-extensions";
import { ReserveState } from "services/erc20";

type SwapProps = {
    wallet: PersonalWalletInfo;
    settingsRepositoryService: SettingsRepositoryState;
    chain: ChainInfo,
    tokens: TokensState;
    tokenBalance: number;
    tokenReserves?: ReserveState;
    isDarkTheme: boolean;
    swap: (isBuy: boolean, amount: number, recipient?: string) => void;
    showSettingsDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

enum Mode {
    Buy,
    Sell
}

const Swap = ({
    wallet,
    settingsRepositoryService,
    chain,
    tokens,
    tokenBalance,
    tokenReserves,
    isDarkTheme,
    swap,
    showSettingsDialog
}: SwapProps) => {
    const [isInteractionDisabled, setIsInteractionDisabled] = useState(false);

    const [mode, setMode] = useState(Mode.Buy);
    const [amount, setAmount] = useStateDeferred(0);
    const [percentage, setPercentage] = useState(0);
    const [recipient, setRecipient] = useState<string>();

    const [maxReceived, setMaxReceived] = useState(0);
    const [minReceived, setMinReceived] = useState(0);
    const [maxPriceImpact, setMaxPriceImpact] = useState(0);
    const [minPriceImpact, setMinPriceImpact] = useState(0);

    useEffect(() => {
        (async () => {
            if (amount > 0) {
                const swapFee = chain.contracts.swapRouters[tokens.weebToken.router].fee.toRate();
                const amountIn = amount * (1 - swapFee);

                const reserves = tokenReserves ?? await tokens.weebToken.pair.getTokenReserves();
                const tokenPriceInNativeToken = reserves.token2Reserve / reserves.token1Reserve;

                if (mode === Mode.Buy) {
                    /*
                    // How much WEEB can we receive from "amount" of ETH?
                    const max = await tokens.weebToken.quote.quoteFromNativeCoin(amount);
                    const tolerance = (max * settingsRepositoryService.getSlippageTolerance()).toRate();
                    const min = max - tolerance;
    
                    setMaxReceived(maxReceived);
                    setMinReceived(min);
                    */

                    const max = getAmountOut(amountIn, reserves.token2Reserve, reserves.token1Reserve);
                    setMaxReceived(max);

                    const min = max - (max * settingsRepositoryService.getSlippageTolerance()).toRate();
                    setMinReceived(min);

                    let finalTokenPriceInNativeToken = (amountIn + reserves.token2Reserve) / (reserves.token1Reserve - max);
                    let priceImpact = (finalTokenPriceInNativeToken - tokenPriceInNativeToken) / tokenPriceInNativeToken;
                    setMaxPriceImpact(priceImpact);

                    finalTokenPriceInNativeToken = (amountIn + reserves.token2Reserve) / (reserves.token1Reserve - min);
                    priceImpact = (finalTokenPriceInNativeToken - tokenPriceInNativeToken) / tokenPriceInNativeToken;
                    setMinPriceImpact(priceImpact);
                } else {
                    /*
                    // How much ETH can we receive from "amount" of WEEB?
                    const max = await tokens.weebToken.quote.quoteToNativeToken(amount);
                    const tolerance = (max * settingsRepositoryService.getSlippageTolerance()).toRate();
                    const min = max - tolerance;
    
                    setMaxReceived(max);
                    setMinReceived(min);
                    */

                    const max = getAmountOut(amountIn, reserves.token1Reserve, reserves.token2Reserve);
                    setMaxReceived(max);

                    const min = max - (max * settingsRepositoryService.getSlippageTolerance()).toRate();
                    setMinReceived(min);

                    let finalTokenPriceInNativeToken = (reserves.token2Reserve - max) / (amountIn + reserves.token1Reserve);
                    let priceImpact = (finalTokenPriceInNativeToken - tokenPriceInNativeToken) / tokenPriceInNativeToken;
                    setMaxPriceImpact(priceImpact);

                    finalTokenPriceInNativeToken = (reserves.token2Reserve - min) / (amountIn + reserves.token1Reserve);
                    priceImpact = (finalTokenPriceInNativeToken - tokenPriceInNativeToken) / tokenPriceInNativeToken;
                    setMinPriceImpact(priceImpact);
                }
            } else {
                setMaxReceived(0);
                setMinReceived(0);
                setMaxPriceImpact(0);
                setMinPriceImpact(0);
            }
        })();
    }, [mode, amount, settingsRepositoryService.getSlippageTolerance()]);

    const nativeCoinBalance = wallet.balance ? tokens.nativeCoin.convert.fromWei(wallet.balance) : 0;

    const toggleMode = () => {
        setMode(current => current === Mode.Buy ? Mode.Sell : Mode.Buy);
    }

    return (
        <div className="grid grid-cols-1 gap-y-3">
            <div className="flex items-center justify-between group-title light-box opacity-75 h-9 px-2">
                <Switch.Group>
                    <div className="flex items-center">
                        <Switch
                            disabled={!tokenBalance}
                            checked={mode === Mode.Buy}
                            onChange={toggleMode}
                            className={`${isDarkTheme
                                ? "bg-indigo-900 ring-offset-indigo-500 focus:ring-indigo-300"
                                : "bg-violet-300 ring-offset-slate-800 focus:ring-violet-300/50"}
                                relative inline-flex items-center w-11 h-5 rounded-full transition-colors focus:outline-none ring-2 ring-offset-2 ring-transparent`
                            }
                        >
                            <span className="sr-only">
                                Withdraw as
                            </span>
                            <span className={`${mode === Mode.Buy ? "translate-x-6" : "translate-x-1"} ${isDarkTheme ? "bg-indigo-300" : "bg-slate-800"} inline-block w-4 h-4 transform rounded-full transition-transform`}
                            />
                        </Switch>
                        <Switch.Label className="leading-6 ml-1.5 hover:animate-wiggle">
                            {mode === Mode.Buy ? "Buy" : "Sell"} {tokens.weebToken.symbol}
                        </Switch.Label>
                    </div>
                </Switch.Group>
            </div>

            <div className="grid grid-cols-1 gap-y-0.5">
                <div className="flex items-center justify-between leading-6">
                    <span className="input-label">
                        Amount:
                        <span className="ml-1">
                            (
                            <MainText
                                main={percentage.toPercent().formatPercentage()}
                                mainUnit="%"
                                mainClassName="text-[1em]"
                                mainUnitClassName="ml-0.5"
                            />
                            )
                        </span>
                    </span>
                    <span className="input-label">
                        Balance:
                        <MainText
                            main={
                                mode === Mode.Buy
                                    ? nativeCoinBalance
                                        ? tokens.nativeCoin.convert.formatCurrency(nativeCoinBalance)
                                        : "-"
                                    : tokenBalance
                                        ? tokens.weebToken.convert.formatCurrency(tokenBalance)
                                        : "-"
                            }
                            mainUnit={
                                mode === Mode.Buy
                                    ? tokens.nativeCoin.symbol
                                    : tokens.weebToken.symbol
                            }
                            mainClassName="text-[1em] ml-1"
                            mainUnitClassName="ml-0.5"
                        />
                    </span>
                </div>
                <div className={`${isDarkTheme ? "accent-violet-300" : ""}`}>
                    <RangeInput
                        isDisabled={false}
                        min={0}
                        max={
                            mode === Mode.Buy
                                ? nativeCoinBalance
                                    ? nativeCoinBalance
                                    : 0
                                : tokenBalance
                                    ? tokenBalance
                                    : 0
                        }
                        decimals={Math.min(mode === Mode.Buy ? tokens.nativeCoin.decimals : tokens.weebToken.decimals, 4)}
                        setValue={setAmount}
                        setPercentage={setPercentage}
                        valueClassName={`${isDarkTheme ? "bg-violet-900/80" : ""}`}
                        rangeClassName={`${isDarkTheme ? "bg-violet-900/80" : ""}`}
                        buttonClassName={`btn btn-violet text-indigo-50 numeric w-11 h-7 ${isDarkTheme ? "bg-violet-900/80" : "bg-violet-900/20"} hover:text-violet-900 focus:hover:text-violet-200`}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 gap-y-1">
                <label htmlFor="recipient" className="flex leading-6">
                    <span className="input-label">Recipient:</span>
                </label>
                <DeferredInput
                    type="search"
                    id="recipient"
                    className={`numeric !text-[1em] text-right ${isDarkTheme ? "bg-violet-900/80" : ""}`}
                    placeholder={`Enter recipient address${wallet.account ? " or leave blank if it's you" : ""}`}
                    onInput={setRecipient}
                />
            </div>
            <div className="flex items-start justify-between">
                <div className="lg:col-span-2 justify-self-start">
                    <button
                        title="Slippage tolerance"
                        type="button"
                        className={`lg:col-span-2 w-24 h-9 text-[0.75em] btn btn-indigo-right ${isDarkTheme ? "text-violet-50" : ""}`}
                        onClick={() => showSettingsDialog(true)}
                    >
                        <i className="bi bi-arrow-down-up mr-1" />
                        {settingsRepositoryService.getSlippageTolerance() / 100}
                        <i className="bi bi-percent" />
                    </button>
                </div>
                <button
                    type="button"
                    className={`lg:col-span-2 w-28 h-9 text-[0.75em] btn ${mode === Mode.Buy ? "btn-green" : "btn-red"} ${isDarkTheme ? "text-violet-50" : ""}`}
                    disabled={isInteractionDisabled || amount === 0 || !(recipient ? Web3.utils.isAddress(recipient.toLowerCase()) : true)}
                    onClick={() => swap(mode === Mode.Buy, amount, recipient)}
                >
                    {mode === Mode.Buy ? "Buy" : "Sell"}
                </button>
            </div>
            <div className="grid grid-cols-2 gap-y-4 gap-x-2 items-center text-[80%]">
                <PriceBox
                    title="To be Received (Min, Max)"
                    main={
                        mode === Mode.Buy
                            ? tokens.nativeCoin.convert.formatCurrency(minReceived)
                            : tokens.weebToken.convert.formatCurrency(minReceived)
                    }
                    mainUnit={
                        mode === Mode.Buy
                            ? tokens.weebToken.symbol
                            : tokens.nativeCoin.symbol
                    }
                    detail={
                        mode === Mode.Buy
                            ? tokens.nativeCoin.convert.formatCurrency(maxReceived)
                            : tokens.weebToken.convert.formatCurrency(maxReceived)
                    }
                    detailUnit={
                        mode === Mode.Buy
                            ? tokens.weebToken.symbol
                            : tokens.nativeCoin.symbol
                    }
                    detailClassName="opacity-100 text-[1.4em]"
                    detailUnitClassName="!opacity-75 !text-[0.75em]"
                />
                <PriceBox
                    title="Price Impact (Min, Max)"
                    detail={maxPriceImpact.toPercent().formatPercentage(4, 2)}
                    detailUnit="%"
                    main={minPriceImpact.toPercent().formatPercentage(4, 2)}
                    mainUnit="%"
                    detailClassName="opacity-100 text-[1.4em]"
                    detailUnitClassName="!opacity-75 !text-[0.75em]"
                />
            </div>
        </div>
    );
}

export default Swap;