import { useEffect, useState } from "react";

import { NotificationItem } from "services/notification";

import { ToastItem } from "./toast-item";
import { ToastPosition } from "./toast.types";

type ToastProps = {
    autoDelete?: boolean;
    autoDeleteDuration?: number;
    position?: ToastPosition;
    showPending?: boolean;
    blockExplorerLink: string;
    notificationItems: NotificationItem[];
    setNotificationItems: React.Dispatch<React.SetStateAction<NotificationItem[]>>;
}

const Toast = ({
    autoDelete = true,
    autoDeleteDuration = 7000,
    position = ToastPosition.TopCenter,
    showPending = true,
    blockExplorerLink,
    notificationItems,
    setNotificationItems
}: ToastProps) => {
    useEffect(() => {
        const updateList = () => {
            const now = Date.now();

            setNotificationItems(current => {
                if (autoDelete) {
                    return current.filter(item => item.resultTime === undefined || item.resultTime + autoDeleteDuration > now);
                }

                return current;
            });
        }

        const timerId = setInterval(updateList, 1000);
        updateList();

        return () => {
            clearInterval(timerId);
        };
    }, []);

    const getPosition = () => {
        if (position === ToastPosition.TopCenter) {
            return "top-center";
        }

        if (position === ToastPosition.TopLeft) {
            return "top-left";
        }

        if (position === ToastPosition.TopRight) {
            return "top-right";
        }

        if (position === ToastPosition.BottomCenter) {
            return "bottom-center";
        }

        if (position === ToastPosition.BottomLeft) {
            return "bottom-left";
        }

        return "bottom-right";
    }

    const removeItem = (id: string) => {
        setNotificationItems(current => current.filter(item => item.trackId !== id));
    }

    const toasts = notificationItems.sort((a, b) => a.createdAt - b.createdAt)
        .filter(item => showPending || item.resultTime !== undefined)
        .map(item => (
            <ToastItem
                key={item.trackId}
                blockExplorerLink={blockExplorerLink}
                item={item}
                remove={removeItem}
            />
        ));

    return (
        <div className={`grid grid-cols-1 gap-y-5 w-80 xs:w-96 fixed z-10 overflow-y-auto ${getPosition()} text-[.875em]`}>
            {toasts}
        </div>
    )
}

export default Toast;