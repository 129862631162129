import { FormatState } from "./format.types";

const format = (): FormatState => {
    const base = {
        formatAccount: (value: string, part?: boolean, shorten?: boolean) => {
            const delta = shorten ? 2 : 0;
            const parts: string[] = [value.substring(0, 6 - delta), "...", value.substring(value.length - (4 - delta))];
            return part ? parts : parts.join("");
        },
        formatDateTime: (date: number) => {
            const parts = new Date(date).toISOString().split("T");
            return `${parts[0]} ${parts[1]}`;
        },
        formatTimespan: (timespan: number) => {
            const h = Math.floor(timespan / 3600);
            const r = timespan % 3600;
            const m = Math.floor(r / 60);
            const s = Math.floor(r % 60);

            const hh = h < 10 ? `0${h}` : `${h}`;
            const mm = m < 10 ? `0${m}` : `${m}`;
            const ss = s < 10 ? `0${s}` : `${s}`;

            return `${hh}:${mm}:${ss}`;
        },
        formatNumber: (number: number, maxDecimals?: number, minDecimals?: number) => {
            const result = new Intl.NumberFormat([], {
                maximumFractionDigits: maxDecimals,
                minimumFractionDigits: minDecimals
            }).format(number);

            return result;
        },
        // Dependent functions.
        formatInteger: (number: number) => base.formatNumber(number, 0),
        formatNumberScaled: (number: number, maxDecimals?: number, minDecimals?: number) => {
            const abs = Math.abs(number);

            if (abs === 0) {
                return `${number}`;
            }

            if (abs >= 10000) {
                return base.formatNumber(number, maxDecimals ? Math.min(0, maxDecimals) : undefined, 0);
            }

            if (abs >= 1000) {
                return base.formatNumber(number, maxDecimals ? Math.min(1, maxDecimals) : undefined, 0);
            }

            if (abs >= /*100*/10) {
                return base.formatNumber(number, maxDecimals ? Math.min(2, maxDecimals) : undefined, 0);
            }

            return base.formatNumber(number, maxDecimals, minDecimals);
        },
        formatPercentage: (number: number, maxDecimals?: number, minDecimals?: number) => base.formatNumberScaled(number * 100, maxDecimals, minDecimals),
        formatCurrency: (number: number, currencyDecimals: number, maxDecimals?: number, minDecimals?: number) => base.formatNumberScaled(number, Math.min(currencyDecimals, maxDecimals ?? 4), Math.min(currencyDecimals, minDecimals ?? 0)),
    };

    return base;
};

export default format();