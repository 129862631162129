import { Fragment, ReactNode } from "react";

type TitleTextProps = {
    title: ReactNode,
    titleClassName: string,
}

export const TitleText = ({
    title,
    titleClassName,
}: Partial<TitleTextProps>) => {
    return (
        <>
            <span className={`opacity-50 uppercase tracking-wider overflow-ellipsis break-normal text-[0.6875em] ${titleClassName ?? ""}`}>
                {title}
            </span>
        </>
    );
}

type MainTextProps = {
    main: ReactNode,
    mainClassName: string,
    mainUnit: ReactNode,
    mainUnitClassName: string,
    isUnitOnTop: boolean
}

export const MainText = ({
    main,
    mainClassName,
    mainUnit,
    mainUnitClassName,
    isUnitOnTop = false
}: Partial<MainTextProps>) => {
    return isUnitOnTop
        ? (
            <>
                {mainUnit &&
                    <span className={`uppercase block mb-1 opacity-75 text-[0.75em] ${mainUnitClassName ?? ""}`}>
                        {mainUnit}
                    </span>
                }
                {main &&
                    <span className={`numeric text-[1.4em] ${mainClassName ?? ""}`}>
                        {main}
                    </span>
                }
            </>
        )
        : (
            <>
                {main &&
                    <span className={`numeric text-[1.4em] ${mainClassName ?? ""}`}>
                        {main}
                    </span>
                }
                {mainUnit &&
                    <span className={`uppercase ml-1 opacity-75 text-[0.75em] ${mainUnitClassName ?? ""}`}>
                        {mainUnit}
                    </span>
                }
            </>
        );
}

type DetailTextProps = {
    detail: ReactNode,
    detailClassName: string,
    detailUnit: ReactNode,
    detailUnitClassName: string
}

export const DetailText = ({
    detail,
    detailClassName,
    detailUnit,
    detailUnitClassName
}: Partial<DetailTextProps>) => {
    return (
        <>
            {detail &&
                <span className={`numeric opacity-75 text-[0.75em] ${detailClassName ?? ""}`}>
                    {detail}
                </span>
            }
            {detailUnit &&
                <span className={`uppercase ml-1 opacity-50 text-[0.625em] ${detailUnitClassName ?? ""}`}>
                    {detailUnit}
                </span>
            }
        </>
    );
}

type InfoTextProps = {
    info: ReactNode,
    infoClassName: string
}

export const InfoText = ({
    info,
    infoClassName = "opacity-25"
}: Partial<InfoTextProps>) => {
    return (
        <>
            {info &&
                <div className={`numeric mt-2 text-[0.75em] ${infoClassName ?? ""}`}>
                    {info}
                </div>
            }
        </>
    );
}

type InfoBoxProps = {
    containerClassName: string,
    lineHeight: string,
    title: ReactNode,
    titleClassName: string,
    main: ReactNode,
    detail: ReactNode,
    info: ReactNode
}

const InfoBox = ({
    containerClassName,
    lineHeight,
    title,
    titleClassName,
    main,
    detail,
    info
}: Partial<InfoBoxProps>) => {
    return (
        <div className={`grid grid-cols-1 auto-rows-min text-center ${lineHeight ?? ""} ${containerClassName ?? ""}`}>
            {title && <TitleText title={title} titleClassName={titleClassName} />}
            {main && <>{main}</>}
            {detail && <>{detail}</>}
            {info && <>{info}</>}
        </div>
    );
}

type PriceBoxProps = {
    containerClassName: string,
    lineHeight: string,
    title: ReactNode,
    titleClassName: string,
    mainRaw: ReactNode,
    main: ReactNode,
    mainClassName: string,
    mainUnit: ReactNode,
    mainUnitClassName: string,
    isMainUnitOnTop: boolean,
    detail: ReactNode,
    detailClassName: string,
    detailUnit: ReactNode,
    detailUnitClassName: string,
    info: ReactNode,
    infoClassName: string,
    isHorizontal: boolean,
}

export const PriceBox = ({
    containerClassName,
    lineHeight = "leading-none",
    title,
    titleClassName,
    mainRaw,
    main,
    mainClassName,
    mainUnit,
    mainUnitClassName,
    isMainUnitOnTop = false,
    detail,
    detailClassName,
    detailUnit,
    detailUnitClassName,
    info,
    infoClassName = "opacity-25",
    isHorizontal,
}: Partial<PriceBoxProps>) => {
    return <InfoBox
        containerClassName={containerClassName}
        lineHeight={lineHeight}
        title={title}
        titleClassName={titleClassName}
        main={mainRaw
            ? <>{mainRaw}</>
            : (main || mainUnit) &&
            <div className={isHorizontal ? "mt-0" : "mt-2"}>
                <MainText
                    main={main}
                    mainClassName={mainClassName}
                    mainUnit={mainUnit}
                    mainUnitClassName={mainUnitClassName}
                    isUnitOnTop={isMainUnitOnTop}
                />
            </div>}
        detail={(detail || detailUnit) &&
            <div className={isHorizontal ? "mt-0" : "mt-1"}>
                <DetailText
                    detail={detail}
                    detailClassName={detailClassName}
                    detailUnit={detailUnit}
                    detailUnitClassName={detailUnitClassName}
                />
            </div>
        }
        info={(
            <InfoText info={info} infoClassName={infoClassName} />
        )}
    />;
}

type MultiBoxProps = {
    containerClassName: string,
    lineHeight: string,
    title: ReactNode,
    titleClassName: string,
    subTitles: ReactNode[],
    subTitleClassName: string,
    subValues: ReactNode[],
    subValueClassName: string
}

export const MultiBox = ({
    containerClassName,
    lineHeight = "leading-none",
    title,
    titleClassName,
    subTitles,
    subTitleClassName,
    subValues,
    subValueClassName
}: Partial<MultiBoxProps>) => {
    let i = 0;

    const sts = subTitles?.map(e => (<TitleText key={i++} title={e} titleClassName={subTitleClassName} />));
    const svs = subValues?.map(e => typeof e === "object" ? <Fragment key={i++}>{e}</Fragment> : (<MainText key={i++} main={e} mainClassName={subValueClassName} />));

    return <InfoBox
        containerClassName={containerClassName}
        lineHeight={lineHeight}
        title={title}
        titleClassName={titleClassName}
        main={(
            <div className="mt-2 grid gap-x-2 gap-y-2 items-center-REMOVE w-full" style={{ gridTemplateColumns: `repeat(${subTitles?.length}, minmax(max-content, 1fr))` }}>
                {sts}
                {svs}
            </div>
        )}
    />;
}