import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import appSettings from "configuration";
import { UriExtensions } from "extensions/uri-extensions";

import Wallet from "./wallet";
import Links from "./links";

const Navbar = () => {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

  function toggleNavbar() {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  }

  return (
    <nav>
      <div className="flex items-center justify-between">
        <div className="flex items-center lg:space-x-16">
          <Link className="navbar-brand flex items-center gap-2" to="/" aria-label="logo">
            <div className="bg-weeb-logo
                    hidden
                    sm:inline-block
                    2xs:h-[42px]
                    2xs:max-h-[42px]
                    2xs:w-[42px] 
                    2xs:max-w-[42px]
                    xs:h-[52px]
                    xs:max-h-[52px]
                    xs:w-[52px] 
                    xs:max-w-[52px]
                    lg:h-[78px]
                    lg:max-h-[78px]
                    lg:w-[78px] 
                    lg:max-w-[78px]
                    bg-left-top 
                    bg-no-repeat 
                    self-center">
            </div>
            <div className="bg-weeb-text
                    2xs:h-[52px]
                    2xs:max-h-[52px]
                    2xs:w-[102px] 
                    2xs:max-w-[102px]
                    xs:h-[64px]
                    xs:max-h-[64px]
                    xs:w-[125px] 
                    xs:max-w-[125px]
                    lg:h-[85px]
                    lg:max-h-[85px]
                    lg:w-[166px] 
                    lg:max-w-[166px]
                    bg-left-top 
                    bg-no-repeat 
                    self-center">
            </div>
          </Link>
          <div className="hidden lg:flex lg:space-x-4">
            <Links />
            <NavLink className="nav-link text-red-300" to="/Test">Test</NavLink>
          </div>
        </div>

        <div className="flex items-center justify-end space-x-4">
          <Wallet defaultChainId={appSettings.defaultChainId} />
          <button
            type="button"
            className={`lg:hidden rounded-md ring-4 focus:outline-none ${isNavbarCollapsed
              ? "text-gray-100/75 ring-gray-100/5 hover:bg-gray-100/10 hover:text-gray-100 hover:ring-gray-100/25"
              : "bg-gray-100/10 text-gray-100 ring-gray-100/25"
              }`}
            onClick={toggleNavbar}
            role="button"
            aria-label="toggle navbar"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className={`h-10 w-12 fill-current p-1`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>

      <div className={`lg:hidden ${isNavbarCollapsed
        ? "hidden"
        : "mt-2 grid w-full grid-cols-2 gap-2 rounded-lg bg-gray-100/10"
        }`}
      >
        <div className="flex flex-col p-2 gap-1">
          <Links />
        </div>
        <div className="flex flex-col p-2 gap-1 justify-between">
          <NavLink className="nav-link text-right text-red-300" to="/Test">Test</NavLink>

          <a className="nav-link text-right" href={UriExtensions.getRoot(window.location.href)}>
            <i className="bi bi-box-arrow-right mr-1" />
            Exit App
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
