import BN from "bn.js";
import { PromiEvent } from "web3-core/types";
import { AbiItem } from "web3-utils/types";
import { Contract, ContractOptions } from "web3-eth-contract/types";

import appSettings from "configuration";
import { Address } from "configuration/types";

import { MAX_UINT256 } from "extensions/web3-extensions";

import { AnonymousWalletInfo, PersonalWalletInfo } from "services/shared.types";
import { IziswapState } from "services/iziswap";
import { convert } from "services/convert";

import { TokenProps, TokenState } from "./token.types";

import IERC20_ABI from "contracts/abi/IERC20.json";
import IUniswapV2Pair_ABI from "contracts/abi/IUniswapV2Pair.json";

type CreateTokenProps = {
    wallet: AnonymousWalletInfo | PersonalWalletInfo,
    iziswap: IziswapState,
    readerContract?: Contract,
    signerContract?: Contract,
} & TokenProps;

const createToken = ({
    wallet,
    iziswap,
    readerContract,
    signerContract,
    router,
    token,
    decimals,
    isLiquidityToken,
    symbol,
}: CreateTokenProps): TokenState => {
    var zero = "0".toBN();
    const _isAllowed = (minAmount: BN, allowance: BN) => allowance.eq(zero) ? false : allowance.gte(minAmount);

    const chain = appSettings.chains[wallet.chainId];
    const options = { from: wallet.reader.defaultAccount } as ContractOptions;

    let reader: Contract;

    if (readerContract) {
        reader = readerContract;
    }
    else {
        const abi = isLiquidityToken ? IUniswapV2Pair_ABI as AbiItem[] : IERC20_ABI as AbiItem[];
        reader = new wallet.reader.eth.Contract(abi, token, options);
    }

    const shared = {
        allowance: (owner: Address, spender: Address): Promise<string> => reader.methods.allowance(owner, spender).call(),
        isAllowanceSatisfied: async (owner: Address, spender: Address, minAmount: BN): Promise<boolean> => {
            const allowance = await shared.allowance(owner, spender);
            return _isAllowed(minAmount, allowance.toBN());
        },
    };

    const read = {
        readerContract: reader,
        balanceOf: (account: Address): Promise<string> => reader.methods.balanceOf(account).call(),
        ...shared
    };

    // Sign methods.
    let sign: {
        signerContract?: Contract,
        approve?: (spender: Address, amount: BN) => PromiEvent<Contract>,
        transfer?: (recipient: Address, amount: BN) => PromiEvent<Contract>
    };

    let signer: Contract | undefined;

    if (signerContract) {
        signer = signerContract;
    }
    else if ("signer" in wallet && wallet.signer) {
        const abi = isLiquidityToken ? IUniswapV2Pair_ABI as AbiItem[] : IERC20_ABI as AbiItem[];
        signer = new wallet.signer.eth.Contract(abi, token, options);
    }

    if (signer) {
        const _signer = signer;

        const track = "signer" in wallet && wallet.signer ? wallet.track : undefined;

        sign = {
            signerContract: signer,
            approve: (spender: Address, amount: BN): PromiEvent<Contract> => {
                const transaction = _signer.methods.approve(spender, amount).send();
                return track ? track(transaction, { name: "approve" }) : transaction;
            },
            transfer: (recipient: Address, amount: BN): PromiEvent<Contract> => {
                const transaction = _signer.methods.transfer(recipient, amount).send();
                return track ? track(transaction, { name: "transfer" }) : transaction;
            },
        }
    } else {
        sign = {};
    }

    const quote = {
        quoteFromNativeCoin: async (amountETH: number) => {
            const amountOut = await iziswap.quoteETH(convert.toWei(amountETH, chain.nativeCoin.decimals), router, token, isLiquidityToken);
            return convert.fromWei(amountOut, decimals);
        },
        quoteToTargetToken: async (amount: number, targetRouter: Address, targetToken: Address, targetDecimals: number, isTargetLiquidityToken: boolean) => {
            const amountOut = await iziswap.quote(router, token, isLiquidityToken, convert.toWei(amount, decimals), targetRouter, targetToken, isTargetLiquidityToken);
            return convert.fromWei(amountOut, targetDecimals);
        },
        quoteToTargetTokenDirect: (amount: number, targetToken: TokenState) => {
            return quote.quoteToTargetToken(amount, targetToken.router, targetToken.token, targetToken.decimals, targetToken.isLiquidityToken);
        },
        quoteToNativeToken: async (amount: number, nativeTokenRouter?: Address) => {
            const targetRouter = nativeTokenRouter ?? chain.contracts.defaultSwapRouter;
            return quote.quoteToTargetToken(amount, targetRouter, chain.contracts.nativeToken.address, chain.contracts.nativeToken.decimals, false);
        },
        quoteToStableToken: async (amount: number, stableTokenRouter?: Address) => {
            const targetRouter = stableTokenRouter ?? chain.contracts.defaultSwapRouter;
            return quote.quoteToTargetToken(amount, targetRouter, chain.contracts.stableToken.address, chain.contracts.stableToken.decimals, false);
        },
    };

    const price = {
        priceOfNativeCoin: () => quote.quoteFromNativeCoin(1),
        priceInTargetToken: (targetRouter: Address, targetToken: Address, targetDecimals: number, isTargetLiquidityToken: boolean) => quote.quoteToTargetToken(1, targetRouter, targetToken, targetDecimals, isTargetLiquidityToken),
        priceInNativeToken: (nativeTokenRouter?: Address) => quote.quoteToNativeToken(1, nativeTokenRouter),
        priceInStableToken: (stableTokenRouter?: Address) => quote.quoteToStableToken(1, stableTokenRouter),
    }

    const base = {
        router,
        token,
        decimals,
        isLiquidityToken,
        symbol,
        ...read,
        ...sign,
        convert: {
            ...convert.createConverter(decimals)
        },
        quote,
        price
    };

    const contract = (address: Address) => {
        return {
            isAllowanceSatisfied: (owner: Address, minAmount: BN) => read.isAllowanceSatisfied(owner, address, minAmount),
            approve: () => sign.approve?.(address, convert.toBN(MAX_UINT256)),
        };
    };

    return {
        ...base,
        contract,
        iziswap: {
            ...contract(chain.contracts.iziswap)
        },
        game: {
            ...contract(chain.contracts.weebGame)
        },
        farm: {
            ...contract(chain.contracts.weebFarm)
        }
    }
}

export default createToken;