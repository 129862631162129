export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = 30.4167;
export const DAYS_IN_YEAR = 365;

// export function getAnnualPercentageYieldBigDecimal(rewardPerBlock, blockTime, totalStaked, investment) {
//     var rate = getRewardRatePerDay(rewardPerBlock, blockTime, totalStaked, investment);
//     return BN.pow(1 + rate, 365) - 1;
// }

/// <summary>
///     https://www.thecalculatorsite.com/finance/calculators/daily-compound-interest.php
/// </summary>

export function getDurationPercentageYield2(rewardPerBlock: number, blockTime: number, totalStaked: number, investment: number, duration: number) {
    var rate = getRewardRatePerDay(rewardPerBlock, blockTime, totalStaked, investment);
    return Math.pow(1 + rate, duration) - 1;
}

// If user wants to invest "investment" amount of to the existing "totalStaked".
export function getRewardRatePerDay(rewardPerBlock: number, blockTime: number, totalStaked: number, investment: number) {
    return _getRewardPerDay(rewardPerBlock, blockTime) / (totalStaked + investment);
}

// This is compounded interest. The reward earned increases user's balance and totalStaked as well which renders constantish daily earned reward for the user.
export function getDurationPercentageYield(rewardPerBlock: number, blockTime: number, totalStaked: number, investment: number, duration: number) {
    const rpd = getRewardPerDay(rewardPerBlock, blockTime, totalStaked + investment, investment);
    return (rpd * duration) / investment;
}

export function getRewardPerDay(rewardPerBlock: number, blockTime: number, totalStaked: number, balance: number) {
    return totalStaked === 0 ? 0 : _getRewardPerDay(rewardPerBlock, blockTime) * balance / totalStaked;
}

export function getRewardPerDuration(rewardPerBlock: number, blockTime: number, duration: number) {
    return _getRewardPerDay(rewardPerBlock, blockTime) * duration;
}

function _getRewardPerDay(rewardPerBlock: number, blockTime: number) {
    return 60 * 60 * 24 * rewardPerBlock / blockTime;
}

export function getRewards(rewardPerBlock: number, blockTime: number, totalStaked: number, balance: number, duration: number, compound: number) {
    const rewardPerDay = _getRewardPerDay(rewardPerBlock, blockTime);
    const dailyRewards = [];

    for (let i = 0; i < duration; ++i) {
        let reward = rewardPerDay * balance / totalStaked;
        totalStaked += rewardPerDay;

        if (compound) {
            balance += reward;
        } else {
            totalStaked -= reward;
        }

        if (i > 0) {
            reward += dailyRewards[i - 1];
        }

        dailyRewards.push(reward);
    }

    console.log(`Final balance: ${balance}`);
    console.log(`Total staked : ${totalStaked}`);
    console.log(dailyRewards);

    const str = dailyRewards.join("\r\n");
    console.log(str);

    return dailyRewards;
}

export function getRewardsOfIncrementedBalance(rewardPerBlock: number, blockTime: number, totalStaked: number, balance: number, duration: number, compound: number, balanceIncrement: number, balanceIncrementPeriod: number) {
    const rewardPerDay = _getRewardPerDay(rewardPerBlock, blockTime);
    const dailyRewards = [];

    for (let i = 0; i < duration; ++i) {
        let reward = rewardPerDay * balance / totalStaked;
        totalStaked += rewardPerDay;

        if (compound) {
            balance += reward;
        } else {
            totalStaked -= reward;
        }

        if (i > 0) {
            reward += dailyRewards[i - 1];

            if ((i + 1) % balanceIncrementPeriod === 0) {
                balance += balanceIncrement;
                totalStaked += balanceIncrement;
            }
        }

        dailyRewards.push(reward);
    }

    console.log(`Final balance: ${balance}`);
    console.log(`Total staked : ${totalStaked}`);
    console.log(dailyRewards);

    const str = dailyRewards.join("\r\n");
    console.log(str);

    return dailyRewards;
}