import { SettingsRepositoryState } from "./settings-repository.types";

import appSettings from "configuration";

const createSettingsRepository = (): SettingsRepositoryState => {
    const slippageToleranceKey = "slippageTolerance";
    const transactionDeadlineKey = "transactionDeadline";

    return {
        slippageToleranceKey,
        getSlippageTolerance: () => {
            const value = window.localStorage.getItem(slippageToleranceKey);
            return value ? parseFloat(value) : appSettings.slippageTolerance;
        },
        setSlippageTolerance: (value: number | null) => {
            if (value) {
                window.localStorage.setItem(slippageToleranceKey, String(value));
            } else {
                window.localStorage.removeItem(slippageToleranceKey);
            }
        },

        transactionDeadlineKey,
        getTransactionDeadline: () => {
            const value = window.localStorage.getItem(transactionDeadlineKey);
            return value ? parseFloat(value) : appSettings.transactionDeadline;
        },
        setTransactionDeadline: (value: number | null) => {
            if (value) {
                window.localStorage.setItem(transactionDeadlineKey, String(value));
            } else {
                window.localStorage.removeItem(transactionDeadlineKey);
            }
        },
    }
}

export default createSettingsRepository;