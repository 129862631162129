import { useState, useTransition } from "react";

import Web3 from "web3";

import { DeferredInput, MainText, RangeInput } from "components/shared";
import { TokensState } from "services/weeb-finance/tokens";

type TransferProps = {
    tokens: TokensState;
    tokenFreeBalance: number;
    isDarkTheme: boolean;
    transfer: (recipient: string, amount: number) => void;
}

const Transfer = ({
    tokens,
    tokenFreeBalance,
    isDarkTheme,
    transfer
}: TransferProps) => {
    const [isPending, startTransition] = useTransition();

    const [isInteractionDisabled, setIsInteractionDisabled] = useState(false);

    const [amount, setAmount] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [recipient, setRecipient] = useState<string>();

    return (
        <div className="grid grid-cols-1 gap-y-3">
            <div className="flex items-center justify-between group-title light-box opacity-75 h-9 px-2">
                <span>
                    Transfer {tokens.weebToken.symbol}
                </span>
            </div>

            <div className="grid grid-cols-1 gap-y-0.5">
                <div className="flex items-center justify-between leading-6">
                    <span className="input-label">
                        Amount:
                        <span className="ml-1">
                            (
                            <MainText
                                main={percentage.toPercent().formatPercentage()}
                                mainUnit="%"
                                mainClassName="text-[1em]"
                                mainUnitClassName="ml-0.5"
                            />
                            )
                        </span>
                    </span>
                    <span className="input-label">
                        Balance:
                        <MainText
                            main={
                                tokenFreeBalance
                                    ? tokens.weebToken.convert.formatCurrency(tokenFreeBalance)
                                    : "-"
                            }
                            mainUnit={tokens.weebToken.symbol}
                            mainClassName="text-[1em] ml-1"
                            mainUnitClassName="ml-0.5"
                        />
                    </span>
                </div>
                <div className={`${isDarkTheme ? "accent-violet-300" : ""}`}>
                    <RangeInput
                        isDisabled={false}
                        min={0}
                        max={
                            tokenFreeBalance
                                ? tokenFreeBalance
                                : 0
                        }
                        decimals={Math.min(tokens.weebToken.decimals, 4)}
                        setValue={value => {
                            startTransition(() => {
                                setAmount(value);
                            });
                        }}
                        setPercentage={setPercentage}
                        valueClassName={`${isDarkTheme ? "bg-violet-900/80" : ""}`}
                        rangeClassName={`${isDarkTheme ? "bg-violet-900/80" : ""}`}
                        buttonClassName={`btn btn-violet text-indigo-50 numeric w-11 h-7 ${isDarkTheme ? "bg-violet-900/80" : "bg-violet-900/20"} hover:text-violet-900 focus:hover:text-violet-200`}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 gap-y-1">
                <label htmlFor="recipient" className="flex leading-6">
                    <span className="input-label">Recipient:</span>
                </label>
                <DeferredInput
                    type="search"
                    id="recipient"
                    className={`numeric !text-[1em] text-right ${isDarkTheme ? "bg-violet-900/80" : ""}`}
                    placeholder="Enter recipient address"
                    onInput={setRecipient}
                />
            </div>
            <div className="flex items-start justify-end">
                <button
                    type="button"
                    className={`lg:col-span-2 w-28 h-9 text-[0.75em] btn btn-green ${isDarkTheme ? "text-violet-50" : ""}`}
                    disabled={isInteractionDisabled || amount === 0 || !(recipient && Web3.utils.isAddress(recipient.toLowerCase()))}
                    onClick={() => {
                        if (recipient) {
                            transfer(recipient, amount);
                        }
                    }}
                >
                    Send
                </button>
            </div>
        </div>
    );
}

export default Transfer;