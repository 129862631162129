import { ReserveState } from "services/erc20";
import { TokensState } from "services/weeb-finance/tokens";
import { WeebTokenViewModel } from "services/weeb-finance/weeb-token";

import InflowOutflowCalculator from "./inflow-outflow-calculator";
import RewardCalculator from "./reward-calculator";
import TargetPriceCalculator from "./target-price-calculator";

type TokenCalculatorsProps = {
    tokens: TokensState;
    token: WeebTokenViewModel;
    reserves: ReserveState;
    priceInStableToken: number;
    nativeTokenPriceInStableToken: number;
    blockTime: number;
}

const TokenCalculators = ({
    tokens,
    token,
    reserves,
    priceInStableToken,
    nativeTokenPriceInStableToken,
    blockTime
}: TokenCalculatorsProps) => {
    const senderBalance = tokens.weebToken.convert.fromWei(token.senderBalance);
    const totalStaked = tokens.weebToken.convert.fromWei(token.totalStaked);
    const maxRewardPerBlock = tokens.weebToken.convert.fromWei(token.maximumRewardPerBlock);
    const currentRewardPerBlock = tokens.weebToken.convert.fromWei(token.currentRewardPerBlock);

    return (
        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-x-2 xs:gap-x-5 gap-y-5 text-center">
            <div className={`card bg-weeb-token/30 bg-diagonal-lines`}>
                <InflowOutflowCalculator
                    tokens={tokens}
                    reserves={reserves}
                    weebTokenPriceInStableToken={priceInStableToken}
                    nativeTokenPriceInStableToken={nativeTokenPriceInStableToken}
                />
            </div>
            <div className={`card bg-weeb-token/30 bg-diagonal-lines`}>
                <TargetPriceCalculator
                    tokens={tokens}
                    reserves={reserves}
                    weebTokenPriceInStableToken={priceInStableToken}
                    nativeTokenPriceInStableToken={nativeTokenPriceInStableToken}
                />
            </div>
            <div className={`card bg-weeb-token/30 bg-diagonal-lines xs:col-span-2`}>
                <RewardCalculator
                    tokens={tokens}
                    priceInStableToken={priceInStableToken}
                    senderBalance={senderBalance}
                    totalStaked={totalStaked}
                    currentRewardPerBlock={currentRewardPerBlock}
                    maxRewardPerBlock={maxRewardPerBlock}
                    isMaximizedEmission={token.isInflationRegulated}
                    blockTime={blockTime}
                />
            </div>
        </div>
    );
}

export default TokenCalculators;