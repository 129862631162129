import { useState, useEffect } from "react";

import { MainText } from "./info-box";

type RangeInputProps = {
    isDisabled: boolean,
    defaultValue?: number,
    min: number,
    max: number,
    decimals: number,
    percentages?: number[],
    valueIdSuffix?: string,
    valueClassName: string,
    rangeClassName: string,
    buttonClassName?: string,
    setValue: React.Dispatch<React.SetStateAction<number>>,
    setPercentage: React.Dispatch<React.SetStateAction<number>>
}

const RangeInput = ({
    isDisabled,
    defaultValue,
    min,
    max,
    decimals,
    percentages = [1, 2, 5, 10, 25, 50, 75, 100],
    valueIdSuffix,
    valueClassName,
    rangeClassName,
    buttonClassName = "btn btn-indigo text-violet-50 numeric w-11 h-7",
    setValue,
    setPercentage
}: RangeInputProps) => {
    const [value, setValueInternal] = useState<number>(defaultValue ?? 0);

    useEffect(() => {
        const v = value;
        const m = max;

        if (v > m) {
            updateValues(m);
        }
    }, [max]);

    const format = (x: number | undefined) => x ? parseFloat(x.toFixed(decimals)) : 0;
    const getStep = () => !isNaN(decimals) ? Math.pow(10, -decimals) : 0;

    const updateValues = (n: number) => {
        const mx = max;
        const mn = min;

        if (n > mx) {
            n = mx;
        }

        if (n < mn) {
            n = mn;
        }

        setValueInternal(n);
        setValue(n);

        // Update the percentage.
        const r = mx - mn;
        setPercentage(r !== 0 ? n / r : 0);
    }

    const onValueChanged = (e: React.FormEvent<HTMLInputElement>) => {
        updateValues(e.currentTarget.value != null ? format(Number(e.currentTarget.value)) : 0);
    }

    const setPercentageFormatted = (percentage: number) => {
        const mx = max;
        const mn = min;

        const n = mn + (mx - mn) * percentage / 100;
        updateValues(n);
    }

    const getSelectablePercentages = () => {
        const items = [];

        for (let i = 0; i < percentages.length; ++i) {
            const percentage = percentages[i];

            items.push((
                <button key={i}
                    type="button"
                    className={`${buttonClassName} ${i < 2 ? "hidden lg:inline" : ""}`}
                    onClick={() => setPercentageFormatted(percentage)}
                >
                    <MainText
                        main={percentage}
                        mainUnit="%"
                        mainClassName="!text-[0.875em]"
                        mainUnitClassName="!text-[0.6875em] ml-0.5"
                    />
                </button>
            ));
        }

        return items;
    }

    return (
        <div className="grid grid-cols-1 gap-y-1">
            <input
                id={`value_${valueIdSuffix ?? ""}`}
                disabled={isDisabled}
                type="number"
                inputMode="decimal"
                className={`text-[2em] numeric text-right h-fit ${valueClassName}`}
                min={format(min)}
                max={format(max)}
                step={format(getStep())}
                value={format(value)}
                onInput={onValueChanged}
                onFocus={e => e.target.select()}
            />
            <input
                disabled={isDisabled}
                type="range"
                list="tickmarks"
                className={`border-0 focus:border-0 ring-0 focus:ring-0 ${rangeClassName}`}
                min={format(min)}
                max={format(max)}
                step={format(getStep())}
                value={format(value)}
                onInput={onValueChanged}
            />
            {/* <datalist id="tickmarks">
                <option value="0" label="0%"></option>
                <option value="10"></option>
                <option value="20"></option>
                <option value="30"></option>
                <option value="40"></option>
                <option value="50" label="50%"></option>
                <option value="60"></option>
                <option value="70"></option>
                <option value="80"></option>
                <option value="90"></option>
                <option value="100" label="100%"></option>
            </datalist> */}
            {percentages &&
                <div className="flex items-center justify-center space-x-2 lg:justify-evenly">
                    {getSelectablePercentages()}
                </div>
            }
        </div>
    );
}

export default RangeInput;