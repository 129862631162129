import Web3 from "web3";
import { BlockNumber } from "web3-core/types";

import { BlockReaderState } from "./block-reader.types";

const createBlockReader = (reader: Web3): BlockReaderState => {
    const base = {
        getBlock: (blockId: BlockNumber) => {
            return reader.eth.getBlock(blockId);
        }
    }

    return {
        ...base,
        getAverageBlockTime: async (count: number) => {
            const blockF = await base.getBlock("latest");
            const now = Date.now() / 1000;
            const blockI = await base.getBlock(blockF.number - count);
            const blockTime = (Number(blockF.timestamp) - Number(blockI.timestamp)) / count;

            console.log(`Block Time: ${blockTime} s; F: ${blockF.timestamp} - I: ${blockI.timestamp} = ${Number(blockF.timestamp) - Number(blockI.timestamp)} s. Lag: ${now - Number(blockF.timestamp)} s.`);

            return blockTime;
        }
    }
}

export default createBlockReader;