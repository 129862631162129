import { getOutcomeImage, getOutcomeText, getRandomizeText, getRandomInteger } from "components/weeb-finance/weeb-game/weeb-game.helpers";

type OutcomeListProps = {
    outcomeCount: number;
    defaultOutcome: number;
    setOutcome: React.Dispatch<React.SetStateAction<number>>
}

const OutcomeList = ({
    outcomeCount,
    defaultOutcome,
    setOutcome
}: OutcomeListProps) => {
    const items = [];

    for (let outcome = 0; outcome < outcomeCount; ++outcome) {
        const isActive = outcome === defaultOutcome;

        items.push(
            <button
                key={outcome}
                type="button"
                className={`group btn btn-outcome font-bold uppercase tracking-wide ${isActive ? "active" : ""} p-1`}
                onClick={_ => setOutcome(outcome)}
            >
                {getOutcomeImage(outcomeCount, outcome)}
                <div className={`text-[0.875em] mt-1 light-box p-1 group-active:text-violet-800 ${isActive ? "text-violet-800" : ""}`}>
                    {getOutcomeText(outcomeCount, outcome)}
                </div>
            </button>
        );
    }

    return (
        <div className="grid grid-cols-1 gap-y-3">
            <div className="flex items-start justify-center space-x-2 h-[160px] xs:h-[180px]">
                {items}
            </div>

            <div className="mx-auto text-[80%]">
                <button
                    type="button"
                    className="btn btn-randomize w-20 h-9 text-violet-50 mt-3"
                    onClick={() => setOutcome(getRandomInteger(0, outcomeCount))}
                >
                    {getRandomizeText(outcomeCount)}
                </button>
            </div>
        </div>
    )
}

export default OutcomeList;