import BN from "bn.js";

import { convert } from "services/convert";

declare global {
    interface Number {
        toBN(): BN;
        toWei(decimals: number): BN;
        toRate(): number;
        toPercent(): number;
        format(maxDecimals?: number, minDecimals?: number): string;
        formatScaled(maxDecimals?: number, minDecimals?: number): string;
        formatPercentage(maxDecimals?: number, minDecimals?: number): string;
        formatCurrency(currencyDecimals: number, maxDecimals?: number, minDecimals?: number): string;
    }
}

Number.prototype.toBN = function (): BN {
    return convert.toBN(Number(this));
};

Number.prototype.toWei = function (decimals: number): BN {
    return convert.toWei(Number(this), decimals);
}

Number.prototype.toRate = function (): number {
    return Number(this) / 10000;
}

Number.prototype.toPercent = function (): number {
    return Number(this) * 100;
}

Number.prototype.format = function (maxDecimals?: number, minDecimals?: number): string {
    const max = maxDecimals ?? 4;
    const min = minDecimals ?? Math.min(2, max);

    return convert.formatNumber(Number(this), max, min);
}

Number.prototype.formatScaled = function (maxDecimals?: number, minDecimals?: number): string {
    const max = maxDecimals ?? 4;
    const min = minDecimals ?? Math.min(2, max);

    return convert.formatNumberScaled(Number(this), max, min);
}

Number.prototype.formatPercentage = function (maxDecimals?: number, minDecimals?: number): string {
    const max = maxDecimals ?? 2;
    const min = minDecimals ?? Math.min(0, max);

    return convert.formatNumberScaled(Number(this), max, min);
}

Number.prototype.formatCurrency = function (currencyDecimals: number, maxDecimals?: number, minDecimals?: number): string {
    return convert.formatCurrency(Number(this), currencyDecimals, maxDecimals, minDecimals);
}