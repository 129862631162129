import { DeferredInput, PriceBox } from "components/shared";
import { DAYS_IN_MONTH, DAYS_IN_WEEK, DAYS_IN_YEAR, getRewardPerDay } from "extensions/interest-extensions";
import { useEffect, useState } from "react";
import { TokensState } from "services/weeb-finance/tokens";

type RewardCalculatorProps = {
    tokens: TokensState;
    priceInStableToken: number;
    senderBalance: number;
    totalStaked: number;
    currentRewardPerBlock: number;
    maxRewardPerBlock: number;
    isMaximizedEmission: boolean;
    blockTime: number;
}

const RewardCalculator = ({
    tokens,
    priceInStableToken,
    senderBalance,
    totalStaked,
    currentRewardPerBlock,
    maxRewardPerBlock,
    isMaximizedEmission,
    blockTime,
}: RewardCalculatorProps) => {
    const [targetBalance, setTargetBalance] = useState<number>();
    const [currentRewards, setCurrentRewards] = useState([0, 0, 0, 0]);
    const [maxRewards, setMaxRewards] = useState([0, 0, 0, 0]);

    useEffect(() => {
        if (!targetBalance) {
            setTargetBalance(senderBalance);
        }
    }, [senderBalance]);

    useEffect(() => {
        if (targetBalance) {
            const currentRewardPerD = getRewardPerDay(currentRewardPerBlock, blockTime, totalStaked, targetBalance);
            const currentRewardPerW = currentRewardPerD * DAYS_IN_WEEK;
            const currentRewardPerM = currentRewardPerD * DAYS_IN_MONTH;
            const currentRewardPerY = currentRewardPerD * DAYS_IN_YEAR;

            const maxRewardPerD = getRewardPerDay(maxRewardPerBlock, blockTime, totalStaked, targetBalance);
            const maxRewardPerW = maxRewardPerD * DAYS_IN_WEEK;
            const maxRewardPerM = maxRewardPerD * DAYS_IN_MONTH;
            const maxRewardPerY = maxRewardPerD * DAYS_IN_YEAR;

            setCurrentRewards([currentRewardPerD, currentRewardPerW, currentRewardPerM, currentRewardPerY]);
            setMaxRewards([maxRewardPerD, maxRewardPerW, maxRewardPerM, maxRewardPerY]);
        }
    }, [totalStaked, currentRewardPerBlock, blockTime, targetBalance]);

    return (
        <>
            <div className="grid grid-cols-1 gap-x-2 gap-y-4">
                <div>
                    <span className="text-[0.5em] text-violet-100/50 light-box block mb-4 lg:min-h-[4.5em]">
                        Calculates potential reward levels (daily, weekly, monthly, yearly) for the given balance.
                    </span>
                    <div className="grid grid-cols-1 gap-y-1">
                        <div className="flex items-center justify-between leading-6">
                            <label htmlFor="balance" className="flex leading-6">
                                <span className="input-label">Target balance</span>
                            </label>
                            <span
                                className="cursor-pointer leading-none shadow-none"
                                onClick={() => setTargetBalance(senderBalance)}
                            >
                                <i className="bi bi-arrow-clockwise"></i>
                            </span>
                        </div>
                        <DeferredInput
                            type="number"
                            id="balance"
                            className="numeric !text-[1em] text-right"
                            defaultValue={targetBalance}
                            placeholder="Balance in WEEB"
                            min={0}
                            step={1}
                            onInput={value => {
                                const float = value ? parseFloat(value) : 0;
                                setTargetBalance(isNaN(float) ? 0 : float);
                            }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-2 gap-y-4 text-[80%]">
                    <span className="group-title opacity-75 col-span-2 sm:col-span-4 light-box">Current Target Reward</span>
                    <PriceBox
                        title="Per Day"
                        main={isNaN(currentRewards[0]) ? "" : tokens.weebToken.convert.formatCurrency(currentRewards[0])}
                        mainUnit={tokens.weebToken.symbol}
                        detail={isNaN(currentRewards[0]) ? "" : tokens.stableToken.convert.formatCurrency(currentRewards[0] * priceInStableToken)}
                        detailUnit={tokens.stableToken.symbol}
                    />
                    <PriceBox
                        title="Per Week"
                        main={isNaN(currentRewards[1]) ? "" : tokens.weebToken.convert.formatCurrency(currentRewards[1])}
                        mainUnit={tokens.weebToken.symbol}
                        detail={isNaN(currentRewards[1]) ? "" : tokens.stableToken.convert.formatCurrency(currentRewards[1] * priceInStableToken)}
                        detailUnit={tokens.stableToken.symbol}
                    />
                    <PriceBox
                        title="Per Month"
                        main={isNaN(currentRewards[2]) ? "" : tokens.weebToken.convert.formatCurrency(currentRewards[2])}
                        mainUnit={tokens.weebToken.symbol}
                        detail={isNaN(currentRewards[2]) ? "" : tokens.stableToken.convert.formatCurrency(currentRewards[2] * priceInStableToken)}
                        detailUnit={tokens.stableToken.symbol}
                    />
                    <PriceBox
                        title="Per Year"
                        main={isNaN(currentRewards[3]) ? "" : tokens.weebToken.convert.formatCurrency(currentRewards[3])}
                        mainUnit={tokens.weebToken.symbol}
                        detail={isNaN(currentRewards[3]) ? "" : tokens.stableToken.convert.formatCurrency(currentRewards[3] * priceInStableToken)}
                        detailUnit={tokens.stableToken.symbol}
                    />

                    {!isMaximizedEmission &&
                        <>
                            <span className="group-title opacity-75 col-span-2 sm:col-span-4 light-box">Maximum Target Reward</span>
                            <PriceBox
                                title="Per Day"
                                main={isNaN(maxRewards[0]) ? "" : tokens.weebToken.convert.formatCurrency(maxRewards[0])}
                                mainUnit={tokens.stableToken.symbol}
                                detail={isNaN(maxRewards[0]) ? "" : tokens.stableToken.convert.formatCurrency(maxRewards[0] * priceInStableToken)}
                                detailUnit={tokens.stableToken.symbol}
                            />
                            <PriceBox
                                title="Per Week"
                                main={isNaN(maxRewards[1]) ? "" : tokens.weebToken.convert.formatCurrency(maxRewards[1])}
                                mainUnit={tokens.stableToken.symbol}
                                detail={isNaN(maxRewards[1]) ? "" : tokens.stableToken.convert.formatCurrency(maxRewards[1] * priceInStableToken)}
                                detailUnit={tokens.stableToken.symbol}
                            />
                            <PriceBox
                                title="Per Month"
                                main={isNaN(maxRewards[2]) ? "" : tokens.weebToken.convert.formatCurrency(maxRewards[2])}
                                mainUnit={tokens.stableToken.symbol}
                                detail={isNaN(maxRewards[2]) ? "" : tokens.stableToken.convert.formatCurrency(maxRewards[2] * priceInStableToken)}
                                detailUnit={tokens.stableToken.symbol}
                            />
                            <PriceBox
                                title="Per Year"
                                main={isNaN(maxRewards[3]) ? "" : tokens.weebToken.convert.formatCurrency(maxRewards[3])}
                                mainUnit={tokens.stableToken.symbol}
                                detail={isNaN(maxRewards[3]) ? "" : tokens.stableToken.convert.formatCurrency(maxRewards[3] * priceInStableToken)}
                                detailUnit={tokens.stableToken.symbol}
                            />
                        </>
                    }
                </div>
            </div>
        </>
    );
}

export default RewardCalculator;