import { useState, useEffect, useRef } from "react";

import Web3 from "web3";
import { provider, PromiEvent, TransactionReceipt } from "web3-core/types";
import { Contract } from "web3-eth-contract/types";
import { Hex } from "web3-utils";

import WalletConnectProvider from "@walletconnect/web3-provider";

import * as errors from "errors";
import { Web3Extensions } from "extensions/web3-extensions";

import { TrackInfo, WalletState } from "./useWallet.types";
import { NotificationItem, NotificationType } from "services/notification";

type WalletProps = {
    autoConnect: boolean,
    rpcUris: Record<number, string>
}

const useWallet = ({
    autoConnect,
    rpcUris
}: WalletProps): WalletState => {
    const componentDidMount = useRef<boolean>(false);
    const isProviderSubscribed = useRef<boolean>(false);
    const selectedChainId = useRef<number>();
    const selectedAccount = useRef<string>();

    const [isMobileDevice, setIsMobileDevice] = useState<boolean>();
    const [isInjectedProviderInstalled, setIsInjectedProviderInstalled] = useState<boolean>();
    const [error, setError] = useState<Error>();

    const [provider, setProvider] = useState<provider>();
    const [reader, setReader] = useState<Web3>();
    const [signer, setSigner] = useState<Web3>();

    const [isConnecting, setIsConnecting] = useState<boolean>(false);
    const [isConnected, setIsConnected] = useState<boolean>(false);

    const [chainId, setChainId] = useState<number>();
    const [account, setAccount] = useState<string>();
    const [balance, setBalance] = useState<string>();

    const [isReadingBalance, setIsReadingBalance] = useState<boolean>(false);
    const [isReadingState, setIsReadingState] = useState<boolean>(false);

    const [notificationItems, setNotificationItems] = useState<NotificationItem[]>([]);

    useEffect(() => {
        if (componentDidMount.current) {
            return;
        }

        const isMobileDevice = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(navigator.userAgent);
        setIsMobileDevice(isMobileDevice);

        const injectedProvider = getInjectedProvider();
        setIsInjectedProviderInstalled(injectedProvider ? true : false);

        if (autoConnect) {
            connect();
        }

        componentDidMount.current = true;
    }, []);

    useEffect(() => {
        if (!provider) {
            handleAccountsChanged([]);

            return;
        }

        isProviderSubscribed.current = true;
        subscribeProvider(provider);

        handleConnect(provider);

        return () => {
            unsubscribeProvider(provider);
            isProviderSubscribed.current = false;
        };
    }, [provider]);

    function handleConnect(provider: provider) {
        setIsConnecting(true);

        initializeProvider(provider)
            .catch((ex: unknown) => {
                const e = errors.getError(ex);
                setError(e);

                console.error(e);
            }).finally(() => {
                setIsConnecting(false);
            });
    }

    async function initializeProvider(provider: provider) {
        const currentProvider = (provider as any);

        const hexChainId = await currentProvider.request({ method: "eth_chainId" });
        handleChainChanged(hexChainId);

        const accounts = await currentProvider.request({ method: "eth_accounts" });

        // Is already connected?
        if (accounts.length > 0) {
            handleAccountsChanged(accounts);
        } else {
            // Connect, MetaMask pop-up windows appears. Internally triggers "accountsChanged" event.
            await currentProvider.request({ method: "eth_requestAccounts" });
        }
    }

    function disconnect() {
        handleDisconnect(410, "User requested disconnect.");

        setIsConnected(false);
        setAccount(undefined);
        setBalance(undefined);
    }

    function connect() {
        if (!connectInjectedProvider()) {
            connectWalletConnectProvider();
        }
    }

    function connectInjectedProvider(): boolean {
        const injectedProvider = getInjectedProvider();

        if (!injectedProvider) {
            return false;
        }

        setError(undefined);

        if (isProviderSubscribed.current) {
            handleConnect(injectedProvider);
        } else {
            setProvider(injectedProvider);
        }

        return true;
    }

    function getInjectedProvider(): provider {
        const w = window as any;

        if (typeof w.ethereum !== "undefined") {
            return w.ethereum;
        }

        if (typeof w.web3 !== "undefined") {
            return w.web3.currentProvider;
        }

        return null;
    }

    function connectWalletConnectProvider() {
        setError(undefined);
        initializeWalletConnectProvider();
    }

    async function initializeWalletConnectProvider() {
        const options = {
            rpc: rpcUris,
            qrcode: true,
        };
        const provider = new WalletConnectProvider(options);
        console.log({ event: "walletConnectInitialize", provider });

        provider.connector.on("display_uri", (err: any, payload: { params: any[]; }) => {
            const uri = payload.params[0];
            console.log(`WalletConnect QR code: ${uri}`);
            //CustomQRCodeModal.display(uri);
        });

        try {
            const accounts = await provider.enable();
            console.log({ event: "walletConnectEnabled", name: provider.walletMeta?.name, accounts });

            setProvider(provider as any);
        } catch (ex: unknown) {
            const e = errors.getError(ex);
            setError(e);

            console.error(e);
        }
    }

    function subscribeProvider(provider: any) {
        if (!provider.on) {
            console.warn("Provider doesn't support events.");

            return;
        }

        provider.on("chainChanged", handleChainChanged);
        provider.on("accountsChanged", handleAccountsChanged);
        provider.on("disconnect", handleDisconnect);
    };

    function unsubscribeProvider(provider: any) {
        if (!provider.on) {
            console.warn("Provider doesn't support events.");

            return;
        }

        provider.removeListener("chainChanged", handleChainChanged);
        provider.removeListener("accountsChanged", handleAccountsChanged);
        provider.removeListener("disconnect", handleDisconnect);
    }

    function initializeWeb3(selectedChainId: number | undefined, selectedAccount: string | undefined): boolean {
        const rpcUri = selectedChainId ? rpcUris[selectedChainId] : undefined;

        if (rpcUri) {
            setChainId(selectedChainId);
            setReader(Web3Extensions.createWeb3(rpcUri, selectedAccount));

            if (provider && selectedAccount) {
                setSigner(Web3Extensions.createWeb3(provider, selectedAccount));
                setIsConnected(true);
            } else {
                setIsConnected(false);
            }

            setError(undefined);

            setAccount(selectedAccount);
            setBalance(undefined);

            return true;
        } else {
            setIsConnected(false);
            setChainId(undefined);
            setReader(undefined);
            setSigner(undefined);

            if (selectedChainId) {
                const e = errors.getUnsupportedChainError(selectedChainId);
                setError(e);

                console.error(e);
            } else {
                setError(undefined);
            }

            return false;
        }
    }

    function handleChainChanged(hexChainId: Hex) {
        selectedChainId.current = Web3.utils.hexToNumber(hexChainId);
        console.log({ event: "chainChanged", chainId: selectedChainId.current });

        initializeWeb3(selectedChainId.current, selectedAccount.current);
    }

    function handleAccountsChanged(accounts: string[]) {
        if (accounts.length > 0) {
            selectedAccount.current = accounts[0];

            console.log({ event: "accountsChanged", account: selectedAccount.current });

            initializeWeb3(selectedChainId.current, selectedAccount.current);
        } else {
            selectedAccount.current = undefined;

            console.log({ event: "accountsChanged", text: "disconnected" });

            setError(undefined);
            setIsConnected(false);
            setAccount(undefined);
            setBalance(undefined);

            handleDisconnect(404, "No accounts found.");
        }
    }

    function handleDisconnect(code: number, reason: string) {
        console.log({ event: "disconnect", code, reason });

        if (signer && signer.currentProvider) {
            if (signer.currentProvider instanceof WalletConnectProvider) {
                signer.currentProvider.close();
                setSigner(undefined);
            } else {
                const currentProvider = signer.currentProvider as any;

                if (currentProvider.connected && currentProvider.close) {
                    currentProvider.close()
                        .then(() => {
                            setSigner(undefined);
                        })
                        .catch((ex: unknown) => {
                            // Wallet close failed.
                            const e = errors.getError(ex);
                            setError(e);

                            console.error(e);
                        });
                } else {
                    setSigner(undefined);
                }
            }
        } else {
            setSigner(undefined);
        }
    }

    function track(transaction: PromiEvent<Contract>, info?: TrackInfo) {
        const trackId = `${Math.random()}`;

        return transaction.on("sent", (payload: object) => {
            const item = createNotificationItem(trackId, info);
            setNotificationItems(current => [item, ...current]);

            console.log("track:sent", payload);
        }).on("transactionHash", (receipt: string) => {
            setNotificationItems(current => current.map(item => {
                if (item.trackId === trackId) {
                    item.transactionHash = receipt;
                }

                return item;
            }));

            console.log("track:transactionHash", receipt);
        }).on("receipt", (receipt: TransactionReceipt) => {
            setNotificationItems(current => current.map(item => {
                if (item.trackId === trackId) {
                    item.resultTime = Date.now();
                    item.type = receipt.status ? NotificationType.Success : NotificationType.Failure;
                }

                return item;
            }));

            console.log("track:receipt", receipt);
        }).on("error", (error: Error) => {
            setNotificationItems(current => current.map(item => {
                if (item.trackId === trackId) {
                    item.resultTime = Date.now();
                    item.type = NotificationType.Warning;
                    item.description = error.message;
                }

                return item;
            }));

            console.error("track:error", error);
        });
    }

    function createNotificationItem(id: string, info?: TrackInfo): NotificationItem {
        return {
            trackId: id,
            type: NotificationType.Info,
            title: info?.name ?? "Transaction",
            description: info?.params,
            createdAt: Date.now()
        };
    }

    return {
        isMobileDevice,
        isInjectedProviderInstalled,
        error,
        provider,
        reader: reader,
        signer,
        isConnecting,
        isConnected,
        chainId: chainId,
        account: account,
        balance,
        setBalance,
        connectInjectedProvider,
        connectWalletConnectProvider,
        connect,
        disconnect,
        isReadingBalance,
        setIsReadingBalance,
        isReadingState,
        setIsReadingState,
        notificationItems,
        setNotificationItems,
        track
    };
}

export default useWallet;