import BN from "bn.js";

import { convert } from "services/convert";

declare global {
    interface String {
        toInt(): number;
        toNumber(): number;
        toBN(): BN;
        fromWei(decimals: number): number;
    }
}

String.prototype.toInt = function (): number {
    return parseInt(String(this));
};

String.prototype.toNumber = function (): number {
    return parseFloat(String(this));
};

String.prototype.toBN = function (): BN {
    return convert.toBN(String(this));
};

String.prototype.fromWei = function (decimals: number): number {
    return convert.fromWei(String(this), decimals);
}