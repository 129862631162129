import { format } from "services/format";
import { NotificationItem, NotificationType } from "services/notification";

type ToastItemProps = {
    blockExplorerLink: string;
    item: NotificationItem;
    remove: (id: string) => void;
}

export const ToastItem = ({
    blockExplorerLink,
    item,
    remove
}: ToastItemProps) => {
    const now = Date.now();
    const elapsedTime = (item.resultTime ? item.resultTime - item.createdAt : now - item.createdAt) / 1000;

    const getIconStyle = (item: NotificationItem) => {
        if (item.type === NotificationType.Success) {
            return "bi-check-circle-fill text-green-500";
        }

        if (item.type === NotificationType.Failure) {
            return "bi-x-circle-fill text-red-500";
        }

        if (item.type === NotificationType.Warning) {
            return "bi-exclamation-circle-fill text-orange-500";
        }

        return "bi-info-circle-fill text-blue-500";
    }

    const getTitle = (title: string) => {
        switch (title) {
            // token:
            case "approve":
                return "Approve Contract";

            case "transfer":
                return "Transfer Tokens";

            // iziswap:
            case "swapExactETH":
                return "Swap Native Coin";

            case "swapExactTokens":
                return "Swap Tokens";

            // weeb-farm:
            case "withdrawETH":
                return "Withdraw in Native Coin";

            case "withdraw":
                return "Withdraw";

            case "withdrawToken":
                return "Withdraw in Token";

            case "rebalance":
                return "Rebalance the Credit";

            case "liquidateAccount":
                return "Liquidate Account";

            case "depositETH":
                return "Deposit in Native Coin";

            case "deposit":
                return "Deposit";

            case "depositToken":
                return "Deposit in Token";

            // weeb-game:
            case "claim":
                return "Claim the Prize";

            case "compound":
                return "Bet the Prize";

            case "betETH":
                return "Place a Bet in Native Coin";

            case "bet":
                return "Harvest and Bet";

            case "betToken":
                return "Place a Bet in Token";

            // weeb-token:
            case "harvestPendingReward":
                return "Harvest Pending Reward";

            default:
                return "-";
        }
    }

    return (
        <div className="card rounded-xl p-2 relative w-full max-h-40 overflow-y-auto mx-auto bg-violet-50 text-gray-900 bg-gradient-to-r to-indigo-200 from-purple-200">
            <div className="flex flex-col space-y-1">
                <div className="flex items-center justify-between space-x-2">
                    <i className={`text-[2em] bi ${getIconStyle(item)}`} />
                    <span className="font-medium truncate w-full">
                        {getTitle(item.title)}
                    </span>
                    <div className="flex items-center justify-between space-x-1">
                        <span className="numeric text-[0.875em]">
                            {format.formatTimespan(elapsedTime)}
                        </span>
                        <button
                            type="button"
                            className="btn text-[2em] leading-none shadow-none btn-close"
                            data-bs-dismiss="modal"
                            aria-label="close"
                            onClick={() => remove(item.trackId)}
                        >
                            <i className="bi bi-x" />
                        </button>
                    </div>
                </div>
                {(item.description || item.transactionHash) &&
                    <>
                        <hr />
                        <div className="overflow-y-auto">
                            {item.description &&
                                <div className="text-clip">
                                    {item.description}
                                </div>
                            }
                            {item.transactionHash &&
                                <div className="text-end">
                                    <a
                                        className="text-[.875em] text-slate-500"
                                        href={`${blockExplorerLink}/tx/${item.transactionHash}`}
                                        target="_blank"
                                        aria-label="transaction details"
                                    >
                                        View transaction
                                    </a>
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    );
}