import { Routes as BrowserRoutes, Route } from "react-router-dom";
import { Main, PageType, Test } from "pages";

const Routes = () => {
    return (
        <BrowserRoutes>
            <Route path="/" element={<Main showPage={PageType.Token} />} />
            <Route path="/games" element={<Main showPage={PageType.Games} />} />
            <Route path="/farms" element={<Main showPage={PageType.Farms} />} />
            <Route path="/hunt" element={<Main showPage={PageType.Liquidatables} />} />

            <Route path="/test" element={<Test />} />
        </BrowserRoutes>
    )
}

export default Routes;