import Web3 from "web3";

import appSettings from "configuration";

/**
 * Another API to fetch wallet tokens.
 * https://github.com/EverexIO/Ethplorer/wiki/Ethplorer-API#get-address-info
 * https://api.ethplorer.io/getAddressInfo/0xff71cb760666ab06aa73f34995b42dd4b85ea07b?apiKey=freekey
 */

export type MoralisToken = {
    token_address: string,
    name: string,
    symbol: string,
    logo?: string,
    thumbnail?: string,
    decimals: number,
    balance: string
}

export type MoralisState = {
    getTokenList: () => Promise<MoralisToken[]>;
    getTokenListUri: () => string;
}

const createMoralis = (account: string, chainId: number): MoralisState => {
    const base = {
        getTokenListUri: () => {
            account = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";
            const hexChainId = Web3.utils.toHex(chainId === 31337 ? 1 : chainId);
            return `https://deep-index.moralis.io/api/v2/${account}/erc20?chain=${hexChainId}`;
        }
    };

    return {
        ...base,
        getTokenList: async () => {
            const response = await fetch(base.getTokenListUri(), {
                headers: {
                    "X-API-Key": appSettings.moralisApiKey
                }
            });

            return await response.json() as MoralisToken[];
        }
    }
}

export default createMoralis;