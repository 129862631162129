import { PriceBox } from "components/shared";
import { StyledPopover } from "components/shared/dialogs";
import { getError } from "errors";
import { ReactNode, useMemo } from "react";
import { convert } from "services/convert";
import { TokenState } from "services/erc20";
import { format } from "services/format";
import { AggregateValue } from "services/weeb-finance/shared.types";
import { TokensState } from "services/weeb-finance/tokens";
import { FinancialStatement, WeebGamePoolViewModel, WeebGameState, WeebGameViewModel } from "services/weeb-finance/weeb-game";
import { getOutcomeText } from "./weeb-game.helpers";

const zero = "0".toBN();

export const Activity = ({
    count,
    timestamp,
    now
}: {
    count: number,
    timestamp: number,
    now: number
}) => {
    return (
        <div className="flex justify-evenly space-x-1">
            <span className="light-box w-full">
                <i className="hidden xs:inline-block bi bi-app-indicator mr-1" />
                {count.format(0)}
            </span>
            <span className="light-box w-full">
                <i className="hidden xs:inline-block bi bi-clock mr-1" />
                {timestamp > 0 ? format.formatTimespan(now - timestamp) : "-"}
            </span>
        </div>
    );
}

const OutcomeTexts = ({
    outcomeCount
}: {
    outcomeCount: number
}) => {
    return useMemo(() => {
        const items = [];

        for (let i = 0; i < outcomeCount; ++i) {
            items.push(
                <th key={i} className="text-[1em] font-normal light-box px-2">
                    {getOutcomeText(outcomeCount, i)}
                </th>
            );
        }

        return (<>{items}</>);
    }, [outcomeCount]);
}

const BetCounts = ({
    outcomeCount,
    bets
}: {
    outcomeCount: number,
    bets: AggregateValue[]
}) => {
    const items = [];

    for (let i = 0; i < outcomeCount; ++i) {
        items.push(
            <td key={i} className="text-[1em] lighter-box px-2">
                {bets[i] ? bets[i].count.toInt().format(0) : 0}
            </td>
        );
    }

    return (<>{items}</>);
}

const BetAmounts = ({
    outcomeCount,
    betToken,
    bets
}: {
    outcomeCount: number,
    betToken: TokenState,
    bets: AggregateValue[]
}) => {
    const items = [];

    for (let i = 0; i < outcomeCount; ++i) {
        items.push(
            <td key={i}>
                <span className="text-[1em] px-2 py-1">
                    {bets[i] ? betToken.convert.formatCurrency(betToken.convert.fromWei(bets[i].total)) : 0}
                </span>
            </td>
        );
    }

    return (<>{items}</>);
}

const getBetsSum = (outcomeCount: number, betToken: TokenState, bets: AggregateValue[]) => {
    let sum = 0;

    for (let i = 0; i < outcomeCount; ++i) {
        sum += bets[i] ? betToken.convert.fromWei(bets[i].total) : 0;
    }

    return sum;
}

const getPayoutOf = (betToken: TokenState, betPool: WeebGamePoolViewModel, outcome: number) => {
    let winningBets = zero;
    let losingBets = zero;

    const allBets = betPool.allBets;

    for (let i = 0; i < allBets.length; ++i) {
        var bet = allBets[i];

        if (i === outcome)
            winningBets = winningBets.add(bet.total.toBN());
        else
            losingBets = losingBets.add(bet.total.toBN());
    }

    if (winningBets.eq(zero)) {
        return null;
    }

    return betToken.convert.fromWei(losingBets) / betToken.convert.fromWei(winningBets);
}

const Payouts = ({
    outcomeCount,
    betToken,
    betPool
}: {
    outcomeCount: number,
    betToken: TokenState,
    betPool: WeebGamePoolViewModel
}) => {
    const payouts: (number | null)[] = [];

    for (let i = 0; i < outcomeCount; ++i) {
        const payout = getPayoutOf(betToken, betPool, i);
        payouts.push(payout);
    }

    const max = Math.max(...payouts.filter(p => p !== null).map(p => p!));

    const items = payouts.map((payout, i) => {
        return (
            <td key={i}>
                <span className={`text-[1em] px-2 py-1 ${payout === max ? " glow" : ""}`}>
                    ×{payout ? payout.formatScaled(2) : ""}
                </span>
            </td>
        );
    });

    return (<>{items}</>);
}

const BetsTable = ({
    outcomeCount,
    betToken,
    bets,
    payouts
}: {
    outcomeCount: number,
    betToken: TokenState,
    bets: AggregateValue[],
    payouts: ReactNode
}) => {
    return (
        <table className="mx-auto table-fixed border-separate leading-none w-full">
            <thead className="group-title uppercase tracking-wider">
                <tr>
                    <OutcomeTexts outcomeCount={outcomeCount} />
                </tr>
            </thead>
            <tbody className="numeric">
                <tr className="opacity-75">
                    <BetCounts
                        outcomeCount={outcomeCount}
                        bets={bets}
                    />
                </tr>
                <tr>
                    <BetAmounts
                        outcomeCount={outcomeCount}
                        betToken={betToken}
                        bets={bets}
                    />
                </tr>
                <tr>
                    {payouts}
                </tr>
            </tbody>
        </table>
    )
}

const AllBets = ({
    outcomeCount,
    betToken,
    betPool
}: {
    outcomeCount: number,
    betToken: TokenState,
    betPool: WeebGamePoolViewModel
}) => {
    return (
        <BetsTable
            outcomeCount={outcomeCount}
            betToken={betToken}
            bets={betPool.allBets}
            payouts={
                <Payouts
                    outcomeCount={outcomeCount}
                    betToken={betToken}
                    betPool={betPool}
                />
            }
        />
    );
}

const SenderBets = ({
    outcomeCount,
    betToken,
    betPool
}: {
    outcomeCount: number,
    betToken: TokenState,
    betPool: WeebGamePoolViewModel
}) => {
    return (
        <BetsTable
            outcomeCount={outcomeCount}
            betToken={betToken}
            bets={betPool.senderBets}
            payouts={
                <td colSpan={outcomeCount}>
                    {/* &nbsp; */}
                    {/* <span className="text-[14px] px-2 py-1 glow">
                        {gameToken.convert.formatCurrency(getBetsSum(gameToken, betPool.senderBets))}
                    </span> */}
                </td>
            }
        />
    );
}

export const BetsRow = ({
    outcomeCount,
    tokens,
    betToken,
    betPool,
    priceInStableToken
}: {
    outcomeCount: number,
    tokens: TokensState,
    betToken: TokenState,
    betPool: WeebGamePoolViewModel,
    priceInStableToken: number
}) => {
    return (
        <>
            <div className={`grid ${outcomeCount > 2 ? "grid-cols-1 sm:grid-cols-2" : "grid-cols-2"} gap-x-2 gap-y-2 text-center`}>
                <div className="flex flex-col items-center">
                    <span className="group-title light-box w-full uppercase tracking-wider">
                        <span className="mr-1">Total Bets</span>
                        <StyledPopover
                            isTooltip={false}
                            children={(
                                <PopoverWinningStatementAllBetsDetail
                                    tokens={tokens}
                                    betToken={betToken}
                                    winningStatement={betPool.statement}
                                    priceInStableToken={priceInStableToken}
                                />
                            )}
                        />
                    </span>
                    {/* <span className="group-title opacity-25 mt-1">{betToken.symbol}</span> */}
                    <div className="overflow-x-auto overflow-y-hidden section">
                        <AllBets
                            outcomeCount={outcomeCount}
                            betToken={betToken}
                            betPool={betPool}
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <span className="group-title light-box w-full uppercase tracking-wider">
                        <span className="mr-1">Your Bets</span>
                        <StyledPopover
                            isTooltip={false}
                            children={(
                                <PopoverWinningStatementSenderBetsDetail
                                    tokens={tokens}
                                    betToken={betToken}
                                    winningStatement={betPool.statement}
                                    priceInStableToken={priceInStableToken}
                                />
                            )}
                        />
                    </span>
                    {/* <span className="group-title opacity-25 mt-1">{betToken.symbol}</span> */}
                    <div className="overflow-x-auto overflow-y-hidden section">
                        <SenderBets
                            outcomeCount={outcomeCount}
                            betToken={betToken}
                            betPool={betPool}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export const PopoverGameContent = ({
    betTokenSymbol,
    betToken,
    game,
    tokens,
    betTokenExplorerUri,
    priceInStableToken,
    seignioryExplorerUri,
    swapRouterName,
    blockTime,
    now
}: {
    betTokenSymbol: string,
    betToken: TokenState,
    game: WeebGameViewModel,
    tokens: TokensState,
    betTokenExplorerUri: string,
    priceInStableToken: number,
    seignioryExplorerUri: string,
    swapRouterName: string,
    blockTime: number,
    now: number
}) => {
    const tooltipSeigniorageFeeText = (
        <span className="tooltip">
            Fee paid to the seignory (game owner) from the prize won on claim.
        </span>
    );

    const harvestRate = game.harvestRate.toNumber().toRate().toPercent();
    const minimumBet = betToken.convert.fromWei(game.minimumBet);
    const blockLength = game.blockLength.toInt();
    const unclaimedBalance = betToken.convert.fromWei(game.unclaimedBalance);

    const seigniorageFee = game.seigniorageFee.rate.toNumber().toRate();
    const seigniorageFeeCollected = betToken.convert.fromWei(game.seigniorageFee.collected.total);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-6 text-[80%]">
            <PriceBox
                title="Bet Token"
                main={(<a href={betTokenExplorerUri} rel="noreferrer" target="_blank" className="font-display">{betTokenSymbol}</a>)}
                detailUnit={swapRouterName}
            />
            <PriceBox
                title="Outcome Count"
                main={game.outcomeCount.toInt().format(0)}
            />
            {/* <PriceBox
                title="Draw Time"
                main={convert.formatInteger(blockLength)}
                mainUnit={"blocks"}
                detail={format.formatTimespan(blockLength * blockTime)}
            /> */}
            {harvestRate > 0
                ?
                <PriceBox
                    title="Harvest Rate as Bet"
                    main={harvestRate.formatPercentage()}
                    mainUnit={"%"}
                    detail={""}
                    detailUnit={"Of the Pending Reward"}
                />
                :
                <PriceBox
                    title="Minimum Bet"
                    main={betToken.convert.formatCurrency(minimumBet)}
                    mainUnit={betToken.symbol}
                    detail={tokens.stableToken.convert.formatCurrency(minimumBet * priceInStableToken)}
                    detailUnit={tokens.stableToken.symbol}
                />
            }
            <PriceBox
                title="Unclaimed Balance"
                main={betToken.convert.formatCurrency(unclaimedBalance)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(unclaimedBalance * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                mainClassName="glow"
            />
            <PriceBox
                title={(
                    <>
                        <span className="mr-1">Seigniorage Fee</span>
                        <StyledPopover isTooltip={true} children={tooltipSeigniorageFeeText} />
                    </>
                )}
                main={seigniorageFee.toPercent().formatPercentage()}
                mainUnit="%"
                detail={(
                    <>
                        <i className="bi bi-clipboard mr-1 cursor-pointer inline-block active:animate-ping"
                            onClick={_ => {
                                navigator.clipboard.writeText(game.seigniory)
                                    .catch(ex => console.error(getError(ex)));
                            }}
                        />
                        <a href={seignioryExplorerUri} rel="noreferrer" target="_blank" className="numeric">
                            {format.formatAccount(game.seigniory)}
                        </a>
                    </>
                )}
                info={game.isSenderSeignory ? (<span className="font-display">You're Seignory</span>) : null}
                infoClassName="opacity-75 light-box font-semibold uppercase text-red-500"
            />
            <PriceBox
                title="Seigniorage Collected"
                main={betToken.convert.formatCurrency(seigniorageFeeCollected)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(seigniorageFeeCollected * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={
                    <Activity
                        count={game.seigniorageFee.collected.count.toInt()}
                        timestamp={game.seigniorageFee.collected.timestamp.toInt()}
                        now={now}
                    />
                }
            />
        </div>
    );
}

export const PopoverBetPoolWinningStatementDetail = ({
    tokens,
    betToken,
    winningStatement,
    priceInStableToken
}: {
    tokens: TokensState,
    betToken: TokenState,
    winningStatement: FinancialStatement,
    priceInStableToken: number
}) => {
    const convert = betToken.convert;

    const allAggregatedBets = convert.fromWei(winningStatement.allAggregatedBets.total);
    const allWinningBets = convert.fromWei(winningStatement.allWinningBets.total);
    const allLosingBets = convert.fromWei(winningStatement.allLosingBets.total);

    const senderAggregatedBets = convert.fromWei(winningStatement.senderAggregatedBets.total);
    const senderWinningBets = convert.fromWei(winningStatement.senderWinningBets.total);
    const senderLosingBets = convert.fromWei(winningStatement.senderLosingBets.total);

    const senderPrize = convert.fromWei(winningStatement.senderPrize);
    const senderPnL = senderPrize - senderLosingBets;

    return (
        <div className="grid grid-cols-4 gap-x-2 gap-y-4 text-[80%]">
            <span className="group-title opacity-75 col-span-4 glass-box">Total Position</span>
            <PriceBox
                title="All Bets"
                main={convert.formatCurrency(allAggregatedBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(allAggregatedBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.allAggregatedBets.count.toInt().format(0)}</span>)}
            />
            <PriceBox
                title="Winning Bets"
                main={convert.formatCurrency(allWinningBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(allWinningBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.allWinningBets.count.toInt().format(0)}</span>)}
            />
            <PriceBox
                title="Losing Bets"
                main={convert.formatCurrency(allLosingBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(allLosingBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.allLosingBets.count.toInt().format(0)}</span>)}
                mainClassName="glow"
            />
            <PriceBox
                title="Payout"
                main={`×${(allWinningBets === 0 ? 0 : allLosingBets / allWinningBets).formatScaled(2)}`}
                mainClassName="neon"
            />

            <span className="group-title opacity-75 col-span-4 glass-box">Your Position</span>
            <PriceBox
                title="All Bets"
                main={convert.formatCurrency(senderAggregatedBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(senderAggregatedBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.senderAggregatedBets.count.toInt().format(0)}</span>)}
            />
            <PriceBox
                title="Winning Bets"
                main={convert.formatCurrency(senderWinningBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(senderWinningBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.senderWinningBets.count.toInt().format(0)}</span>)}
                mainClassName="neon"
            />
            <PriceBox
                title="Losing Bets"
                main={convert.formatCurrency(senderLosingBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(senderLosingBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.senderLosingBets.count.toInt().format(0)}</span>)}
                mainClassName="glow"
            />
            <PriceBox
                title="PnL"
                main={convert.formatCurrency(senderPnL)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(senderPnL * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                mainClassName="glow"
            />
            <PriceBox
                containerClassName="col-span-4"
                title="Winning Share"
                main={(allWinningBets === 0 ? 0 : senderWinningBets / allWinningBets).toPercent().formatPercentage()}
                mainUnit="%"
                mainClassName="neon"
            />
        </div>
    );
}

export const PopoverWinningStatementAllBetsDetail = ({
    tokens,
    betToken,
    winningStatement,
    priceInStableToken
}: {
    tokens: TokensState,
    betToken: TokenState,
    winningStatement: FinancialStatement,
    priceInStableToken: number
}) => {
    const convert = betToken.convert;

    const allAggregatedBets = convert.fromWei(winningStatement.allAggregatedBets.total);
    const allWinningBets = convert.fromWei(winningStatement.allWinningBets.total);
    const allLosingBets = convert.fromWei(winningStatement.allLosingBets.total);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 text-[80%]">
            <PriceBox
                title="All Bets"
                main={convert.formatCurrency(allAggregatedBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(allAggregatedBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.allAggregatedBets.count.toInt().format(0)}</span>)}
            />
            <PriceBox
                title="Winning Bets"
                main={convert.formatCurrency(allWinningBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(allWinningBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.allWinningBets.count.toInt().format(0)}</span>)}
            />
            <PriceBox
                title="Losing Bets"
                main={convert.formatCurrency(allLosingBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(allLosingBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.allLosingBets.count.toInt().format(0)}</span>)}
                mainClassName="glow"
            />
            <PriceBox
                title="Payout"
                main={`×${(allWinningBets === 0 ? 0 : allLosingBets / allWinningBets).formatScaled(2)}`}
                mainClassName="neon"
            />
        </div>
    );
}

export const PopoverWinningStatementSenderBetsDetail = ({
    tokens,
    betToken,
    winningStatement,
    priceInStableToken
}: {
    tokens: TokensState,
    betToken: TokenState,
    winningStatement: FinancialStatement,
    priceInStableToken: number
}) => {
    const convert = betToken.convert;

    const allWinningBets = convert.fromWei(winningStatement.allWinningBets.total);

    const senderAggregatedBets = convert.fromWei(winningStatement.senderAggregatedBets.total);
    const senderWinningBets = convert.fromWei(winningStatement.senderWinningBets.total);
    const senderLosingBets = convert.fromWei(winningStatement.senderLosingBets.total);

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-4 text-[80%]">
            <PriceBox
                title="All Bets"
                main={convert.formatCurrency(senderAggregatedBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(senderAggregatedBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.senderAggregatedBets.count.toInt().format(0)}</span>)}
            />
            <PriceBox
                title="Winning Bets"
                main={convert.formatCurrency(senderWinningBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(senderWinningBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.senderWinningBets.count.toInt().format(0)}</span>)}
                mainClassName="neon"
            />
            <PriceBox
                title="Losing Bets"
                main={convert.formatCurrency(senderLosingBets)}
                mainUnit={betToken.symbol}
                detail={tokens.stableToken.convert.formatCurrency(senderLosingBets * priceInStableToken)}
                detailUnit={tokens.stableToken.symbol}
                info={(<span><i className="bi bi-app-indicator mr-1" />{winningStatement.senderLosingBets.count.toInt().format(0)}</span>)}
                mainClassName="glow"
            />
            <PriceBox
                title="Winning Share"
                main={(allWinningBets === 0 ? 0 : senderWinningBets / allWinningBets).toPercent().formatPercentage()}
                mainUnit="%"
                mainClassName="neon"
            />
        </div>
    );
}

export const isSenderUnderWin = (betToken: TokenState, winningStatement: FinancialStatement) => {
    const convert = betToken.convert;
    const senderPrize = convert.fromWei(winningStatement.senderPrize);
    const senderLosingBets = convert.fromWei(winningStatement.senderLosingBets.total);

    return senderPrize > 0 && senderPrize < senderLosingBets;
}

export const isSenderWinner = (betPool: WeebGamePoolViewModel) => {
    if (betPool.senderBets.length === 0) {
        return 0;
    }

    return betPool.senderBets[betPool.outcome.toInt()].count.toInt() > 0
        ? betPool.statement.allLosingBets.count.toInt() > 0 ? 1 : -1
        : betPool.statement.allWinningBets.count.toInt() > 0 ? 2 : -1;
}

const isSenderWinnerText = (betPool: WeebGamePoolViewModel) => {
    switch (isSenderWinner(betPool)) {
        case -1:
            return "had no opponent";
        case 0:
            return "didn't bet";
        case 1:
            return "won";
        case 2:
            return "lost";
    }
}

export const BetPool = ({
    outcomeCount,
    tokens,
    betToken,
    betPool,
    priceInStableToken,
    title,
    blockTime,
    currentBlock
}: {
    outcomeCount: number,
    tokens: TokensState,
    betToken: TokenState,
    betPool: WeebGamePoolViewModel,
    priceInStableToken: number,
    title: string,
    blockTime: number,
    currentBlock: number
}) => {
    const poolId = betPool.poolId.toInt();
    const blocksFromDraw = currentBlock - poolId;

    const convert = betToken.convert;

    const lastBetPoolPot = convert.fromWei(betPool.statement.allAggregatedBets.total);
    const lastBetPoolWinningPrize = convert.fromWei(betPool.statement.senderPrize);
    const isUnderWin = isSenderUnderWin(betToken, betPool.statement);

    const senderAggregatedBets = convert.fromWei(betPool.statement.senderAggregatedBets.total);
    const senderLosingBets = convert.fromWei(betPool.statement.senderLosingBets.total);
    const senderPrize = convert.fromWei(betPool.statement.senderPrize);
    const hasOpponent = isSenderWinner(betPool) > 0;
    const senderPnL = hasOpponent ? (senderPrize - senderLosingBets) : senderAggregatedBets;

    return (
        <>
            <div className={`card bg-white/5 bg-diagonal-lines`}>
                <div className="grid grid-cols-12 gap-x-2 gap-y-0.5">
                    <div className="col-span-5">
                        <span className="card-header-title-text opacity-75">
                            {title}
                        </span>
                    </div>
                    <div className="col-span-7 flex items-center justify-end card-header-item-text">
                        <span className="uppercase opacity-50">
                            <span className="2xs:hidden sm:block">
                                Time from Draw
                            </span>
                            <i className="sm:hidden bi bi-clock-history" />
                        </span>
                        <span className="numeric opacity-75 ml-1 glow">
                            {poolId > 0 ? format.formatTimespan(blocksFromDraw * blockTime) : "-"}
                        </span>
                        <span className="2xs:hidden xs:block numeric opacity-75 ml-2">
                            {poolId > 0 ? blocksFromDraw.format(0) : "-"}
                        </span>
                        <span className="2xs:hidden xs:block uppercase opacity-50 ml-1">
                            blocks
                        </span>
                    </div>
                    <div className="col-span-7 flex items-center justify-start card-header-item-text">
                        <span className="uppercase opacity-50">
                            Pool Id
                        </span>
                        <span className="numeric opacity-25 ml-1">
                            {poolId > 0 ? poolId.format(0) : "-"}
                        </span>
                    </div>
                    <div className="col-span-5 flex items-center justify-end card-header-item-text">
                        <span className="opacity-25">
                            You {isSenderWinnerText(betPool)}
                        </span>
                    </div>

                    <div className="col-span-12 mt-0.5 mb-3 border-bottom-t2 border-2"></div>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-4 gap-x-2 gap-y-4 text-[80%]">
                    <PriceBox
                        title="Outcome"
                        main={getOutcomeText(outcomeCount, betPool.outcome.toInt())}
                        titleClassName="light-box"
                        mainClassName="font-display neon"
                    />
                    <PriceBox
                        title="Pot"
                        main={convert.formatCurrency(lastBetPoolPot)}
                        mainUnit={betToken.symbol}
                        detail={tokens.stableToken.convert.formatCurrency(lastBetPoolPot * priceInStableToken)}
                        detailUnit={tokens.stableToken.symbol}
                        // info={(<span><i className="bi bi-app-indicator mr-1" />{betPool.statement.allAggregatedBets.count.toInt().format(0)}</span>)}
                        titleClassName="light-box"
                    />

                    <PriceBox
                        title="Your Prize"
                        main={convert.formatCurrency(lastBetPoolWinningPrize)}
                        mainUnit={betToken.symbol}
                        detail={tokens.stableToken.convert.formatCurrency(lastBetPoolWinningPrize * priceInStableToken)}
                        detailUnit={tokens.stableToken.symbol}
                        info={isUnderWin ? "Under Win" : null}
                        titleClassName="light-box"
                        mainClassName="neon"
                        infoClassName={`glow ${isUnderWin ? " blinker" : null}}`}
                    />
                    <PriceBox
                        title="PnL"
                        main={convert.formatCurrency(senderPnL)}
                        mainUnit={betToken.symbol}
                        detail={tokens.stableToken.convert.formatCurrency(senderPnL * priceInStableToken)}
                        detailUnit={tokens.stableToken.symbol}
                        titleClassName="light-box"
                        mainClassName="glow"
                    />

                    <div className="col-span-2 sm:col-span-4 text-[90%]">
                        <BetsRow
                            outcomeCount={outcomeCount}
                            tokens={tokens}
                            betToken={betToken}
                            betPool={betPool}
                            priceInStableToken={priceInStableToken}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export const getSenderBetPools = async (
    gameService: WeebGameState,
    game: WeebGameViewModel,
    senderBetPools: WeebGamePoolViewModel[],
    senderBetPoolCount: number,
    pageLength: number = 3
) => {
    const remaining = senderBetPoolCount - senderBetPools.length;

    if (remaining > 0) {
        let offset;
        let count;

        offset = senderBetPools.length;

        if (remaining > pageLength) {
            count = pageLength;
        } else {
            count = remaining;
        }

        let pools = [...await gameService.senderPoolSnapshots(game.gameId.toInt(), offset, count)];

        if (pools.length > 0 && pools[pools.length - 1].poolId.toInt() === game.currentPoolId.toInt()) {
            pools = pools.slice(0, pools.length - 1);
        }

        return [...senderBetPools, ...pools];
    }

    return null;
}