import { WeebGameState, WeebGameViewModel } from "services/weeb-finance/weeb-game";

type GameListProps = {
    gameService: WeebGameState,
    games: WeebGameViewModel[],
    defaultGameId: number,
    setGameId: React.Dispatch<React.SetStateAction<number>>
}

const GameList = ({
    gameService,
    games,
    defaultGameId,
    setGameId
}: GameListProps) => {
    const items = games.filter(game => game.harvestRate.toInt() > 0).map((game, i) => {
        // const betTokenSymbol = gameService.getSymbol(game);
        // const betToken = gameService.createBetToken(game);

        var isActive = false;

        if (i === 0) {
            if (defaultGameId === -1 || defaultGameId > games.length) {
                isActive = true;
            }
        }

        const harvestRate = game.harvestRate.toNumber().toRate().toPercent().formatPercentage();
        //const minimumBet = betToken.convert.formatCurrency(betToken.convert.fromWei(game.minimumBet));

        const gameId = game.gameId.toInt();

        return (
            <button
                key={gameId}
                className={`card py-1.5 px-1 btn btn-violet ${isActive || gameId === defaultGameId ? "active" : ""}`}
                onClick={_ => {
                    setGameId(gameId);
                }}>
                <div className="grid grid-cols-1 gap-y-1 text-center">
                    <h6 className="card-header-title-text font-semibold whitespace-normal text-[0.75em] xs:text-[0.875em]">
                        {game.name}
                    </h6>
                    <ul className="text-[0.625em] opacity-80 normal-case">
                        <li>
                            Draws every
                            <span className="numeric mx-1 font-bold">{game.blockLength}</span>
                            blocks
                        </li>
                        <li>
                            Harvest as bet is
                            <span className="mx-1 font-bold">
                                <span className="numeric">{harvestRate}</span>
                                %
                            </span>
                            of the reward
                        </li>
                        {/* <li>
                            Minimum bet is
                            <span className="mx-1 font-bold">
                                <span className="numeric">{minimumBet}</span>&nbsp;{betTokenSymbol}
                            </span>
                        </li> */}
                    </ul>
                </div>
            </button>
        );
    });

    return (
        <div className="grid grid-cols-2 gap-x-2 gap-y-2 max-h-56 overflow-y-auto overflow-x-hidden section p-1">
            {items}
        </div>
    )
}

export default GameList;