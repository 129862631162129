import { useContext, useEffect, useState } from "react";

import appSettings from "configuration";
import { format } from "services/format";
import WalletContext from "contexts/wallet-context";

import { ConnectDialog, ModalDialog, WalletDialog } from "components/shared/dialogs";
import { Web3Extensions } from "extensions/web3-extensions";

const Wallet = (defaultChainId: { defaultChainId?: number }) => {
  const wallet = useContext(WalletContext);

  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState<boolean>(false);
  const [isWalletDialogOpen, setIsWalletDialogOpen] = useState<boolean>(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(true);

  useEffect(() => {
    const readBalance = () => {
      if (!wallet || !wallet.reader || !wallet.account) {
        return;
      }

      wallet.setIsReadingBalance(true);

      wallet.reader.eth.getBalance(wallet.account)
        .then(balance => {
          wallet.setBalance(balance);
        })
        .finally(() => {
          wallet.setIsReadingBalance(false);
        });
    }

    const timerId = setInterval(readBalance, appSettings.walletPingInterval);
    readBalance();

    return () => {
      clearInterval(timerId);
    };
  }, [wallet?.account]);

  if (!wallet) {
    return <p className="text-red-500">Invalid wallet state</p>
  }

  const switchOrAddEthereumChain = () => {
    const chainId = appSettings.defaultChainId;
    const chain = appSettings.chains[chainId];
    const chainName = chain.name;
    const nativeCurrencyName = undefined;
    const nativeCurrencySymbol = chain.nativeCoin.symbol;
    const nativeCurrencyDecimals = chain.nativeCoin.decimals;
    const rpcUrls = [chain.rpcUri];
    const blockExplorerUrls: string[] | undefined = undefined;
    const iconUrls: string[] | undefined = undefined;

    Web3Extensions.switchOrAddEthereumChain(wallet.provider,
      {
        chainId,
        chainName,
        nativeCurrencyName,
        nativeCurrencySymbol,
        nativeCurrencyDecimals,
        rpcUrls,
        blockExplorerUrls: blockExplorerUrls,
        iconUrls
      }
    );
  }

  const errorFragment = (error: Error) => {
    return (
      <ModalDialog
        isOpen={isErrorDialogOpen}
        setIsOpen={setIsErrorDialogOpen}
        disableBackgroundCancel={false}
        body={(
          <>
            <p className="text-red-500">
              {error.message === "User closed modal"
                ? "Wallet connection cancelled."
                : error.message
              }
            </p>
            {error.name === "unsupportedChain" &&
              <>
                <p>
                  Please switch to a supported chain (network) from your wallet.
                </p>
                {wallet.isInjectedProviderInstalled &&
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-indigo-right p-2 w-full text-violet-50"
                      onClick={switchOrAddEthereumChain}
                    >
                      Add the Default Chain to Wallet
                    </button>
                  </div>
                }
              </>
            }
          </>
        )}
        title="Wallet Error"
        hideSubmitButton={true}
        cancelButtonText="Close"
      />
    );
  }

  const formatAccount = (account: string, isReadingBalance: boolean, isReadingState: boolean) => {
    const parts = format.formatAccount(account, true);

    return (
      <>
        {parts[0]}
        <b className={`${isReadingBalance ? "text-rose-300" : ""}`}>.</b>
        <b className={`${isReadingState ? "text-emerald-300" : ""}`}>.</b>
        <b>.</b>
        {parts[2]}
      </>
    );
  }

  if (wallet.isConnected) {
    const chain = appSettings.chains[wallet.chainId!];

    return (
      <>
        {wallet.error && errorFragment(wallet.error)}
        <WalletDialog isOpen={isWalletDialogOpen} setIsOpen={setIsWalletDialogOpen} />
        <div className="flex items-center space-x-2">
          <div className="hidden rounded bg-gray-100/10 px-1 py-0.5 text-[0.875em] xl:block">
            <span>{chain.name}</span>
          </div>
          <div className="xs:block hidden rounded bg-gray-100/10 px-1 py-0.5 text-[0.875em]">
            <div>
              <span className="numeric">
                {wallet.balance === undefined ? "-" : wallet.balance.fromWei(chain.nativeCoin.decimals).formatScaled()}
              </span>
              <span className="numeric-unit text-[0.75em]">
                {chain.nativeCoin.symbol}
              </span>
            </div>
          </div>
          {wallet.account &&
            <div className="cursor-pointer rounded bg-gray-100/[0.15] px-1 py-0.5 text-[0.875em]">
              <span
                className="numeric flex"
                onClick={() => setIsWalletDialogOpen(true)}>
                {formatAccount(wallet.account, wallet.isReadingBalance, wallet.isReadingState)}
              </span>
            </div>
          }
        </div>
      </>
    );
  } else {
    return (
      <>
        {wallet.error && errorFragment(wallet.error)}
        <ConnectDialog
          isOpen={isConnectDialogOpen}
          setIsOpen={setIsConnectDialogOpen}
          onSubmit={providerId => setIsErrorDialogOpen(true)
          }
        />
        <div className="flex items-center">
          <div className="rounded bg-gray-100/10 px-1 text-[0.875em]">
            <span
              className="cursor-pointer"
              onClick={() => setIsConnectDialogOpen(true)}>
              Connect to Wallet
            </span>
          </div>
          <i className={`bi bi-dot ${wallet.isReadingState ? "text-emerald-500" : ""}`}></i>
        </div>
      </>
    );
  }
}

export default Wallet;